import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseIconDirective } from '../base-icon.directive';

@Component({
  selector: 'app-close-card-icon',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './close-card-icon.component.html',
  styleUrl: './close-card-icon.component.scss',
})
export class CloseCardIconComponent extends BaseIconDirective {}
