@if (!actionsTemplate() || isMobileScreen()) {
  <app-button-filter
    [isActive]="isActiveFilterButton()"
    (click)="toggleDisplayFilters()"
  ></app-button-filter>
}

<app-search-input
  class="filter-panel_search-input"
  [(searchText)]="searchInputValue"
></app-search-input>

@if (actionsTemplate()) {
  <ng-container
    *ngTemplateOutlet="actionsTemplate()"
  ></ng-container>

  @if (!isMobileScreen()) {
    <app-button-filter
      [isActive]="isActiveFilterButton()"
      (click)="toggleDisplayFilters()"
    ></app-button-filter>
  }
}

@if (!isHiddenFilters()) {
  <app-filters-list-horizontal
    class="filter-panel_filters-list"
    [filtersList]="filterItems()"
    [(selectedFilters)]="selectedFilters"
  ></app-filters-list-horizontal>
}


