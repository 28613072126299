<app-button
  class="close-button"
  size="small"
  btnUIType="subtle"
>
  <app-close-icon
    class="close-button_icon"
    color="#1E1E1E"
  ></app-close-icon>
</app-button>
