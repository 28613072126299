<div class="chips-item"
     [ngClass]="{
      selected: isSelected()
     }"
>
  @if (isSelected()) {
    <app-mark-icon></app-mark-icon>
  }
  {{ (chipsItem().name) | translate }}
</div>
