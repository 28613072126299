import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goey-footer',
  templateUrl: './goey-footer.component.html',
  styleUrls: ['./goey-footer.component.scss'],
})
export class GoeyFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  changeIconToHover(event: Event): void {
    const target = event.target as HTMLImageElement;
    const hoverSrc = target.getAttribute('data-hover');
    if (hoverSrc) {
      target.setAttribute('data-default', target.src); // Store the initial image source
      target.src = hoverSrc; // Change the image source to the hover image
    }
  }

  changeIconToDefault(event: Event): void {
    const target = event.target as HTMLImageElement;
    const defaultSrc = target.getAttribute('data-default');
    if (defaultSrc) {
      target.src = defaultSrc; // Revert the image source to the default image
    }
  }
}
