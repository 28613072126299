<div class="container">
  <app-header class="header"></app-header>
  <section class="page-container">
    <div class="page-content">
      <main
        class="content"
        [ngClass]="{ 'mt-0': isAuthPage }"
        [@fader]="outlet.isActivated ? outlet.activatedRoute : ''"
      >
        <div [ngClass]="{'hidden': !isModalActive()}">
          <router-outlet name="modal"></router-outlet>
        </div>
        <div [ngClass]="{'hidden': isModalActive()}">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </main>
    </div>
  </section>
  @if (!hideFooter()) {
    <app-goey-footer></app-goey-footer>
  }
  <app-cookie-notification
    [@slideInOut]
    *ngIf="showCookieNotification"
    (hideNotification)="showCookieNotification = false"
    (agree)="hideNotification()"
  ></app-cookie-notification>
</div>

