<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
  <g clip-path="url(#clip0_7226_13241)">
    <path d="M22.3638 11.2102V12.1302C22.3625 14.2867 21.6643 16.3849 20.3731 18.1121C19.0819 19.8392 17.2671 21.1027 15.1991 21.7141C13.1312 22.3256 10.921 22.2521 8.89824 21.5048C6.87545 20.7575 5.14842 19.3763 3.97473 17.5673C2.80104 15.7583 2.24356 13.6183 2.38545 11.4665C2.52733 9.3148 3.36098 7.26656 4.76205 5.62731C6.16312 3.98805 8.05656 2.84562 10.16 2.37038C12.2634 1.89515 14.464 2.11257 16.4338 2.99024M22.3638 4.13024L12.3638 14.1402L9.36378 11.1402"  [attr.stroke]="color()"
          [attr.stroke-width]="strokeWidth()" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_7226_13241">
      <rect width="100%" height="100%" fill="white" transform="translate(0.36377 0.130249)"/>
    </clipPath>
  </defs>
</svg>
