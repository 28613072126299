import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IDropdownListItem } from './dropdown-list-item.interface';

@Component({
  selector: 'app-dropdown-list-item',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './dropdown-list-item.component.html',
  styleUrls: ['./dropdown-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownListItemComponent {
  readonly menuItem = input.required<IDropdownListItem>();

  readonly disabled = input<boolean>(false);
  readonly selected = input<boolean>(false);

  readonly icon = computed(() => this.menuItem().icon);
  readonly isIconTemplate = computed(() => {
    const icon = this.icon();
    return icon && icon instanceof TemplateRef;
  });
  readonly isIconImgSrc = computed(() => {
    const icon = this.icon();
    return icon && typeof icon === 'string';
  });
}
