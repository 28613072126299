import { BonusItem } from '../interfaces';

export const Bonus: BonusItem[] = [
  {
    percent: '',
    info: 'finances_stripe.bonus.percent_',
  },
  {
    percent: '-1%',
    info: 'finances_stripe.bonus.percent_1_1',
  },
  {
    percent: '-1%',
    info: 'finances_stripe.bonus.percent_1_2',
  },
  {
    percent: '-2%',
    info: 'finances_stripe.bonus.percent_2',
  },
  {
    percent: '-3%',
    info: 'finances_stripe.bonus.percent_3_1',
  },
  {
    percent: '-3%',
    info: 'finances_stripe.bonus.percent_3_2',
  },
  {
    percent: '-5%',
    info: 'finances_stripe.bonus.percent_5',
  },
];
