<svg
  width="49"
  height="121"
  viewBox="0 0 49 121"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M42.915 82.217H6.08502C3.92602 82.217 1.89402 83.487 0.878016 85.392C-0.137984 87.297 -0.0109822 89.71 1.25902 91.488L19.674 118.285C20.817 119.936 22.595 120.825 24.5 120.825C26.405 120.825 28.31 119.809 29.326 118.285L47.741 91.488C49.011 89.71 49.138 87.297 48.122 85.392C47.106 83.487 45.074 82.217 42.915 82.217Z"
    fill="black"
  />
  <path
    d="M6.08502 38.656H42.915C45.074 38.656 47.106 37.386 48.122 35.481C49.138 33.576 49.011 31.163 47.741 29.385L29.326 2.58804C27.167 -0.586963 21.833 -0.586963 19.674 2.58804L1.25902 29.385C-0.0109822 31.163 -0.137984 33.576 0.878016 35.481C1.89402 37.386 3.92602 38.656 6.08502 38.656Z"
    fill="black"
  />
  <path
    d="M24.5 73.581C31.7946 73.581 37.708 67.6676 37.708 60.373C37.708 53.0785 31.7946 47.165 24.5 47.165C17.2054 47.165 11.292 53.0785 11.292 60.373C11.292 67.6676 17.2054 73.581 24.5 73.581Z"
    fill="black"
  />
</svg>
