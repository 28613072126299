import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceConditionsComponent } from './price-conditions/price-conditions.component';
import { PipesModule } from '../../../../../shared/pipes/pipes.module';
import { PropertyCounterComponent } from './price-conditions/components/property-counter/property-counter.component';
import { PriceControlSliderModule } from '../../../../../shared/components/price-control-slider/price-control-slider.module';
import { FormsModule } from '@angular/forms';
import { PriceSliderModule } from '../../../../conversation/price-slider/price-slider.module';

@NgModule({
  declarations: [PriceConditionsComponent, PropertyCounterComponent],
  exports: [PriceConditionsComponent],
  imports: [
    CommonModule,
    PipesModule,
    PriceControlSliderModule,
    FormsModule,
    PriceSliderModule,
  ],
})
export class PriceConditionsModule {}
