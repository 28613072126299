import { ConversationType } from '../../shared/models/conversation.model';
import { ConversationFilterItem } from '../../shared/models/conversation-filter-item';

export const filterSelectItems: ConversationFilterItem[] = [
  // {
  //   name: 'scheduled',
  //   title: 'Scheduled',
  // },
  {
    name: ConversationType.DIRECT_MESSAGES,
    title: 'Direct Messages',
  },
  {
    name: 'requests',
    title: 'Requests',
  },
  {
    name: 'we_care',
    title: 'We Care',
  },
  {
    name: 'get_support',
    title: 'Get Support',
  },
  {
    name: ConversationType.ORG_CHATS,
    title: 'Org Chats',
  },
  {
    name: ConversationType.COMMUNITIES,
    title: 'Communities',
  },
];
export const clientFilterSelectItems: ConversationFilterItem[] = [
  // {
  //   name: 'scheduled',
  //   title: 'Scheduled',
  // },
  {
    name: ConversationType.DIRECT_MESSAGES,
    title: 'Direct Messages',
  },
  {
    name: 'requests',
    title: 'Requests',
  },
  {
    name: 'get_support',
    title: 'Get Support',
  },
  {
    name: ConversationType.COMMUNITIES,
    title: 'Communities',
  },
];
