import { Component, input } from '@angular/core';
import {
  Conversation,
  IConversationUserInfo,
} from '../../../../shared/models/conversation.model';
import { AddingUserComponent } from '../adding-user/adding-user.component';
import { MatDialog } from '@angular/material/dialog';
import { getRandomAvatarSrc } from '../../../usersettings/constants/avatars';

@Component({
  selector: 'app-internal-chat-header',
  templateUrl: './internal-chat-header.component.html',
  styleUrls: ['./internal-chat-header.component.scss'],
})
export class InternalChatHeaderComponent {
  public conversation = input<Conversation>(null);

  constructor(private dialog: MatDialog) {}

  public openAddUserPopup() {
    this.dialog.open(AddingUserComponent, {
      data: {
        conversation: this.conversation(),
        inviteTo: 'internal_chat',
        note: 'internal_chat_note',
        isInternal: true,
      },
    });
  }

  public getDefaultAvatar(id = 1) {
    return getRandomAvatarSrc(id);
  }
}
