<div class="nested-content">
  <ng-content></ng-content>
</div>
<div class="select-filter">
  <button class="filter-btn" [ngClass]="{active: selected()?.length > 0}" (click)="switchItems()">
    <app-settings-icon [color]="selected()?.length > 0 ? 'white' : '#1E1E1E' " strokeWidth="2"></app-settings-icon>
  </button>
  @if(isDesktop()) {
    <div class="filter-list">
      @for(item of items(); track item.name) {
        <ng-container [ngTemplateOutlet]="itemTemp" [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container>
      }
    </div>
  }
</div>

@if(!isDesktop() && openList()) {
  <div class="filter-list mobile" @openClose>
    @for(item of items(); track item.name) {
      <ng-container [ngTemplateOutlet]="itemTemp" [ngTemplateOutletContext]="{ $implicit: item }">
      </ng-container>
    }
  </div>
}

<ng-template #itemTemp let-item>
  <div class="filter-item" [ngClass]="{selected: item.selected}" (click)="select(item)">
    @if (item.selected) {
      <app-mark-icon [strokeWidth]="4" color="white"></app-mark-icon>
    }
    {{ ('conversation_types.' + item.name) | translate }}
  </div>
</ng-template>
