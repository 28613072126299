export const DefaultProperties = {
  loop: false,
  index: 0,
  counter: false,
  imageMaxHeight: '100%',
  imageMaxWidth: '100%',
  animationDuration: 350,
  animationMode: 'zoom-preloader',
  animationTimingFunction: 'cubic-bezier(0.475, 0.105, 0.445, 0.945)',
  closeButtonText: 'Close',
  counterSeparator: '/',
  disable: false,
  simpleMode: false,
  backgroundColor: 'black',
  backgroundOpacity: 1,
  hideThumbnail: true,
  gestureEnable: false,
};
