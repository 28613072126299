import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ExpertiseCardInput } from './expertise-card.interface';
import { CloseIconComponent } from '../../icons/close-icon/close-icon.component';

@Component({
  selector: 'app-expertise-card',
  templateUrl: './expertise-card.component.html',
  styleUrls: ['./expertise-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CloseIconComponent],
  standalone: true,
})
export class ExpertiseCardComponent {
  readonly skillFilter = input.required<ExpertiseCardInput>();
  readonly skillFilterName = computed(() => this.skillFilter()['name']);
  readonly skillFilterCounselorsAmount = computed(
    () => this.skillFilter()['counselors_amount'],
  );

  readonly close = output<void>();
  private readonly _counselorsWordTranslations = toSignal(
    this._translateService.get('expertise-card'),
    { initialValue: null },
  );
  readonly counselorsWord = computed(() => {
    const translations = this._counselorsWordTranslations();
    return translations
      ? this.skillFilterCounselorsAmount() === 1
        ? translations.counselor
        : translations.counselors
      : '';
  });

  constructor(private readonly _translateService: TranslateService) {}

  closeIconMouseOver = false;

  closeIconMouseOverHandler() {
    this.closeIconMouseOver = true;
  }

  closeIconMouseLeaveHandler() {
    this.closeIconMouseOver = false;
  }

  closeIconClickHandler() {
    this.close.emit();
  }
}
