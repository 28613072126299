import { ChangeDetectionStrategy, Component, input, model, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IceBreaker } from '../../../pages/profile/ice-breakers/services/model/response/ice-breaker.model';
import { ButtonMoreVertComponent } from '../../UIkit/buttons/button-more-vert/button-more-vert.component';
import { CardImageComponent } from '../../UIkit/card-image/card-image.component';
import { IconMoreVerticalComponent } from '../../icons/icon-more-vertical/icon-more-vertical.component';
import { ButtonComponent } from '../../UIkit/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconShakeHandsSmallComponent } from '../../icons/icon-shake-hands-small/icon-shake-hands-small.component';
import { IceBreakerService } from '../../../pages/profile/ice-breakers/services/ice-breaker.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-ice-breaker-card',
  standalone: true,
  imports: [CommonModule, CardImageComponent, IconMoreVerticalComponent, ButtonComponent, TranslateModule, IconShakeHandsSmallComponent],
  templateUrl: './ice-breaker-card.component.html',
  styleUrls: ['./ice-breaker-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IceBreakerCardComponent {
  readonly iceBreaker = input.required<IceBreaker>();
  readonly fullView = input(false);
  readonly emitFullViewToggle = output();

  constructor(
    private readonly _iceBreakerService: IceBreakerService,
  ) {
  }

  startConversation() {
    // @ts-ignore
    this._iceBreakerService.join(this.iceBreaker())
      .pipe(take(1))
      .subscribe();
  }

}
