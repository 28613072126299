import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(
    private readonly _authService: AuthService,
    private router: Router,
  ) {
  }
  canActivate(): Observable<boolean | UrlTree> {
    return this._authService.authorizedUser$.pipe(
      map((user) => user?.permissions?.isAdmin() || this.router.createUrlTree(['/home'])),
    );
  }
}
