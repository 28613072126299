import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  input,
  output,
  signal,
} from '@angular/core';
import { Conversation } from '../../../../shared/models/conversation.model';
import { IConversationMenuItem } from './conversation-menu-item.interface';
import { MatDialog } from '@angular/material/dialog';
import { IConfirmationMessage } from '../../../../shared/complex-ui-components/confirmation-message/confirmation-message.interface';
import { ConfirmationMessageComponent } from '../../../../shared/complex-ui-components/confirmation-message/confirmation-message.component';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ConversationsService } from '../../../../services/conversations.service';
import { AddingUserComponent } from '../adding-user/adding-user.component';
import { PermissionService } from '../../../../auth/service/permission.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { isArrayAndHasItems } from '../../../../shared/lib/array-helpers.lib';
import { AuthService } from '../../../../auth/auth.service';
import { AnonymousUser } from '../../../../shared/models/user/anonymous-user.model';

@Component({
  selector: 'app-conversation-menu',
  templateUrl: './conversation-menu.component.html',
  styleUrls: ['./conversation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationMenuComponent {
  readonly conversation = input.required<Conversation>();
  readonly isVideoCallRequested = input<boolean>(false);
  readonly requestVideoCall = output<void>();
  public isDisable = toSignal(
    this.authService.authorizedUser$.pipe(
      map(
        (authorizedUser) =>
          !authorizedUser || (authorizedUser as AnonymousUser).isAnonymous,
      ),
    ),
  );

  private readonly _isCounselor = toSignal(
    this._permissionsService.isCounselor$,
  );

  @HostBinding('class')
  get classes() {
    return { hidden: !isArrayAndHasItems(this.menuItems()) };
  }

  constructor(
    private readonly _dialog: MatDialog,
    private authService: AuthService,
    private readonly _conversationsService: ConversationsService,
    private readonly _permissionsService: PermissionService,
  ) {}

  readonly isOpened = signal(false);

  private readonly _openRequestMenuItems = signal<IConversationMenuItem[]>([
    {
      title: 'conversation_menu.accept_case',
      param: 'accept',
      selectHandler: this._acceptCaseHandler,
    },
    {
      title: 'conversation_menu.reject_case',
      param: 'reject',
      selectHandler: this._rejectCaseHandler,
    },
  ]);
  private readonly _defaultMenuItems = computed<IConversationMenuItem[]>(() => [
    {
      title: this.isVideoCallRequested()
        ? 'conversation_menu.video_call_requested'
        : 'conversation_menu.video_call_request',
      param: 'video_call',
      icon: 'assets/icons/video-camera.svg',
      icon_alt: 'conversation_menu.video_call_request_icon_alt',
      disabled: this.isVideoCallRequested(),
      selectHandler: this._requestVideoCall,
    },
    {
      title: 'conversation_menu.add_user_title',
      description: 'conversation_menu.add_user_description',
      param: 'add_user',
      icon: 'assets/icons/user-plus.svg',
      selectHandler: this._openAddUserPopup,
    },
  ]);

  readonly menuItems = computed(() => {
    return this.conversation().isOpenRequest()
      ? this._isCounselor()
        ? this._openRequestMenuItems()
        : []
      : this._defaultMenuItems();
  });

  private _acceptCaseHandler() {
    this._dialog
      .open(ConfirmationMessageComponent, {
        data: {
          title: 'confirmation_message.accept_case_title',
          message: 'confirmation_message.accept_case_message',
          question: 'confirmation_message.accept_case_question',
          confirm: 'confirmation_message.accept_case_btn',
          cancel: 'confirmation_message.cancel_btn',
        } as IConfirmationMessage,
      })
      .beforeClosed()
      .pipe(
        filter((_) => _),
        switchMap(() =>
          this._conversationsService.acceptOpenRequestCase(this.conversation()),
        ),
      )
      .subscribe((confirmDialog) => {
        console.log('Dialog was confirmed for conversation', confirmDialog);
      });
  }

  private _rejectCaseHandler() {
    this._dialog
      .open(ConfirmationMessageComponent, {
        data: {
          title: 'confirmation_message.reject_case_title',
          message: 'confirmation_message.reject_case_message',
          question: 'confirmation_message.reject_case_question',
          confirm: 'confirmation_message.reject_case_btn',
          cancel: 'confirmation_message.cancel_btn',
        } as IConfirmationMessage,
      })
      .beforeClosed()
      .pipe(
        filter((_) => _),
        switchMap(() =>
          this._conversationsService.rejectOpenRequestCase(this.conversation()),
        ),
      )
      .subscribe((confirmDialog) => {
        console.log('Dialog was rejected for conversation', confirmDialog);
      });
  }

  private _requestVideoCall() {
    this.requestVideoCall.emit();
  }

  private _openAddUserPopup() {
    this._dialog.open(AddingUserComponent, {
      data: {
        conversation: this.conversation(),
        inviteTo: 'chat',
      },
    });
  }

  selectMenuItem(item: IConversationMenuItem) {
    this.closeMenu();
    item.selectHandler.call(this);
  }

  toggleMenu() {
    const currentState = this.isOpened();
    this.isOpened.set(!currentState);
  }

  closeMenu() {
    if (this.isOpened()) {
      this.isOpened.set(false);
    }
  }
}
