<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
  <g clip-path="url(#clip0_4124_63104)">
    <path d="M13.2383 2.95593C14.1151 2.95593 14.83 3.67075 14.83 4.5476C14.83 5.42444 14.1151 6.13926 13.2383 6.13926H3.70737C2.83052 6.13926 2.1157 5.42444 2.1157 4.5476C2.1157 3.67075 2.83052 2.95593 3.70737 2.95593H13.2383Z" [attr.fill]="fill()"/>
    <path d="M24.094 11.6103C24.1226 11.6484 24.1321 11.7056 24.1512 11.7533C24.1988 11.839 24.2369 11.9344 24.2655 12.0297C24.2846 12.0869 24.3132 12.1345 24.3322 12.1917C24.3418 12.2489 24.3322 12.2965 24.3322 12.3537C24.3322 12.4014 24.3608 12.449 24.3608 12.4967C24.3608 12.5443 24.3322 12.592 24.3322 12.6396C24.3227 12.6968 24.3418 12.7445 24.3322 12.8017C24.3227 12.8589 24.2846 12.9065 24.2655 12.9637C24.2369 13.059 24.1988 13.1543 24.1512 13.2401C24.1226 13.2877 24.1226 13.3354 24.094 13.3831L20.9202 18.1485C20.6152 18.606 20.11 18.8538 19.5954 18.8538C19.2904 18.8538 18.9854 18.768 18.7185 18.5869C17.9847 18.1009 17.794 17.1096 18.2801 16.3853L19.8146 14.0883H3.70733C2.83048 14.0883 2.11566 13.3735 2.11566 12.4967C2.11566 11.6198 2.83048 10.905 3.70733 10.905H19.8051L18.2706 8.60807C17.7845 7.87419 17.9847 6.8925 18.709 6.40642C19.4429 5.92035 20.4246 6.1205 20.9106 6.84485L24.094 11.6103Z" [attr.fill]="fill()"/>
    <path d="M3.70737 18.8442H13.2383C14.1151 18.8442 14.83 19.5591 14.83 20.4359C14.83 21.3127 14.1151 22.018 13.2383 22.018H3.70737C2.83052 22.018 2.1157 21.3032 2.1157 20.4264C2.1157 19.5495 2.83052 18.8442 3.70737 18.8442Z" [attr.fill]="fill()"/>
  </g>
  <defs>
    <clipPath id="clip0_4124_63104">
      <rect width="100%" height="100%" fill="white" transform="matrix(-1 0 0 1 24.8613 0.486694)"/>
    </clipPath>
  </defs>
</svg>
