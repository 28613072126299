import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { User, userFactory } from '../shared/models/user.model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  API_URL,
  AUTH_URL,
  PASSWORD_URL,
  REGISTER_REFERRAL_URL,
  RESEND_CONFIRMATION_URL,
  SIGN_IN_URL,
  SWITCH_EMAIL_NOTIFICATIONS,
  VALIDATE_TOKEN_URL,
} from 'src/config/config';
import { Ruleset } from '../shared/models/ruleset';
import { BaseResponse } from '../shared/models/base-response.interface';
import { Scheduling } from '../pages/usersettings/interfaces';
import { LocalStorageSegmentFactoryService } from '../services/local-storage/local-storage-segment-factory.service';
import {
  createAnonUserLocalStorageData,
  IAuthServiceLocalStorage,
} from './model/auth-service-local-storage.inteface';
import { HOME_PAGE_ROUTE } from '../../config/shared-routes';
import { toSignal } from '@angular/core/rxjs-interop';
import { SignupDTO } from './model/sign-up-dto.interface';
import {
  AnonymousUser,
  createAnonymousUser,
} from '../shared/models/user/anonymous-user.model';
import { IAnonymousUserResponse } from './model/anonymous-user-response.interface';
import { Conversation } from '../shared/models/conversation.model';

export const AcceptTokenStatuses = {
  CREATED: 'created',
  UNAUTHORIZED: 'unauthorized',
  GONE: 'gone',
  UNPROCESSABLE_ENTITY: 'unprocessable_entity',
  OK: 'ok',
  NOT_FOUND: 'not_found',
} as const;

export type AcceptTokenStatusesType =
  (typeof AcceptTokenStatuses)[keyof typeof AcceptTokenStatuses];

export interface AcceptToken {
  status: AcceptTokenStatusesType;
  message: string;
  token?: string;
  organization_id?: number;
  invite_email?: string;
  user_exists?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userId: number;

  private readonly _authorizedUser$ = new BehaviorSubject<User | AnonymousUser>(
    null,
  );
  // TODO: Refactor to make private
  readonly userSubject$ = this._authorizedUser$;

  readonly authorizedUser$ = this._authorizedUser$.asObservable();
  private readonly _authLocalStorage =
    this._localStorageSegmentFactoryService.getExistingOrCreate<IAuthServiceLocalStorage>(
      AuthService.name,
    );

  get localStorage() {
    return this._authLocalStorage;
  }

  readonly storageState$ = this._authLocalStorage.localStorageState$;

  readonly hasAuthorizationData$ = this.storageState$.pipe(
    map((_) => {
      return _.accessToken && _.tokenType && _.client && _.uid && _.expiry;
    }),
  );

  readonly anonymousUserId$ = this.storageState$.pipe(
    map((_) => parseInt(_.temp_user_id, 10)),
  );

  readonly isAnonymousUser$ = this.anonymousUserId$.pipe(
    map((_) => !Number.isNaN(_)),
  );
  readonly userIsSignedIn$ = combineLatest([
    this.hasAuthorizationData$,
    this.isAnonymousUser$,
  ]).pipe(
    map(
      ([hasAuthorizationData, isAnonymousUser]) =>
        hasAuthorizationData && !isAnonymousUser,
    ),
  );

  readonly authUserShareToken$ = this.userIsSignedIn$.pipe(
    filter((_) => !!_),
    switchMap(() => this.authorizedUser$),
    map((_) => _?.sharetoken),
  );

  readonly isAnonymousUser = toSignal(this.isAnonymousUser$);
  readonly _lastConversationId = toSignal(
    this.storageState$.pipe(map((_) => _.conversation_id)),
  );
  private readonly _anonymousUserId = toSignal(this.anonymousUserId$);

  readonly notAuthorizedOrAnonymousUser$ = combineLatest([
    this.storageState$,
    this.authorizedUser$,
  ]).pipe(map(([{ temp_user_id }, user]) => !!(!user || temp_user_id)));

  readonly allowNotAuthorizedUserToAuth$ = combineLatest([
    this.userIsSignedIn$,
    this.isAnonymousUser$,
    this.storageState$,
  ]).pipe(
    map(
      ([userIsSignedIn, isAnonymousUser, storageState]) =>
        (!userIsSignedIn && !isAnonymousUser) ||
        (isAnonymousUser &&
          Number.isInteger(storageState.last_completed_conversation_id)),
    ),
  );

  readonly userIsSignedIn = toSignal(this.userIsSignedIn$);
  readonly userProfileInfo$ = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private readonly _localStorageSegmentFactoryService: LocalStorageSegmentFactoryService,
    @Inject('SIGN_OUT_URL')
    private readonly _signOutUrl: string,
  ) {
    this._subscribeAuthUserOnLocalStorageState();
  }

  private _subscribeAuthUserOnLocalStorageState() {
    this.localStorage.localStorageState$.subscribe(({ temp_user_id }) => {
      if (temp_user_id) {
        const anonUser = createAnonymousUser(+temp_user_id);
        this._authorizedUser$.next(anonUser);
      }
    });
  }

  resetConfirmPassword(newpass: string) {
    return this.http.put(PASSWORD_URL, {
      password: newpass,
      password_confirmation: newpass,
    });
  }

  requestConfirmationEmail(email: string) {
    return this.http.post<User>(RESEND_CONFIRMATION_URL, { email });
  }

  resetPassword(email: string) {
    return this.http.post(PASSWORD_URL, {
      email,
      redirect_url: 'https://getme.global/auth?mode=login',
    });
  }

  fetchUserForProfileToken(token: string) {
    const url = API_URL + 'users?sharetoken=' + token;

    return this.http.get<User>(url).pipe(
      map((user) => {
        this.userProfileInfo$.next(user);
        return user;
      }),
    );
  }

  fetchUserByEmail(email: string) {
    const url = API_URL + 'userByEmail?email=' + email;

    return this.http.get<User>(url);
  }

  fetchUser(userId: number) {
    const url = API_URL + 'users/' + userId;
    return this.http.get<User>(url);
  }

  login(body: {
    email: string;
    password: string;
    invite_token?: string;
    user_anonymous_id?: number;
    conversation_id?: number;
  }) {
    body.user_anonymous_id = this._anonymousUserId() || null;
    body.conversation_id = this._lastConversationId() || null;

    return this.http.post(SIGN_IN_URL, body, { observe: 'response' }).pipe(
      tap((res: HttpResponse<any>) => this.storeAccessData(res.headers)),
      map((res: HttpResponse<any>) => res.body),
      map((res: { data: User }) => res.data),
      switchMap((res: User) => this.fetchUser(res.id)),
      tap((user: User) => this.userSubject$.next(user)),
    );
  }

  storeAccessData(fromHeaders: HttpHeaders) {
    const data: IAuthServiceLocalStorage = {
      accessToken: fromHeaders.get('access-token'),
      client: fromHeaders.get('client'),
      expiry: fromHeaders.get('expiry'),
      tokenType: 'Bearer',
      uid: fromHeaders.get('uid'),
    };
    this._authLocalStorage.replaceState(data);
  }

  signUp(signUpData: SignupDTO) {
    signUpData.user_anonymous_id = this._anonymousUserId() || null;
    signUpData.conversation_id = this._lastConversationId() || null;
    return this.http.post(AUTH_URL, signUpData, { observe: 'response' }).pipe(
      tap((res: HttpResponse<any>) => this.storeAccessData(res.headers)),
      map((res: HttpResponse<any>) => res.body),
      map((res: { data: User }) => res.data),
      switchMap((res: User) => this.fetchUser(res.id)),
      map((user) => userFactory(user)),
      tap((res) => this.userSubject$.next(res)),
    );
  }

  registerReferral(userId: number, referredByToken: string) {
    return this.http.post<User>(REGISTER_REFERRAL_URL, {
      userId,
      referredByToken,
    });
  }

  updateUser(user: User) {
    const url = API_URL + 'users/' + user.id;

    return this.http.put<User>(url, { user }).pipe(
      map((user) => userFactory(user)),
      tap((res) => this.userSubject$.next(res)),
    );
  }

  updateUserProfileImage(userId: number, profileImage: string) {
    const url = API_URL + 'users/' + userId;
    return this.http.patch<User>(url, { profileimage: profileImage });
  }

  public switchEmailNotification(): Observable<BaseResponse> {
    return this.http.put<BaseResponse>(SWITCH_EMAIL_NOTIFICATIONS, {});
  }

  autoLogin() {
    return this._authLocalStorage.localStorageState$.pipe(
      switchMap((_) =>
        _.accessToken && !this._anonymousUserId()
          ? this.http.get(VALIDATE_TOKEN_URL).pipe(
              map((res: { data: User }) => res.data),
              mergeMap((res: User) => this.fetchUser(res.id)),
              map((user) => userFactory(user)),
              tap((res: User) => this.userSubject$.next(res)),
            )
          : of(EMPTY),
      ),
    );
  }

  logout() {
    this.http.delete(this._signOutUrl).subscribe(() => {
      this._authLocalStorage.clearState();
      this.userSubject$.next(null);
      this.router.navigate([`/${HOME_PAGE_ROUTE}`]);
    });
  }

  isAnon(userId) {
    // TODO: Remove BACKDOOR -------- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    return !userId || userId === 156;
  }

  createScheduling(data: Ruleset) {
    const url = API_URL + 'rulesets';
    return this.http.post<Ruleset>(url, data);
  }

  getListScheduling() {
    const url = API_URL + `users/scheduling`;
    return this.http.get<Scheduling>(url);
  }

  deleteItemScheduling(id: number) {
    const url = API_URL + `rulesets/${id}`;
    return this.http.delete(url);
  }

  public sendInviteToken(token: string): Observable<AcceptToken> {
    return this.http.get<AcceptToken>(
      `${API_URL}organizations/accept_invite/${token}`,
    );
  }

  setAnonymousUser(userIdOrAuthObject: number | IAnonymousUserResponse) {
    if (Number.isInteger(userIdOrAuthObject)) {
      this.localStorage.replaceState({
        temp_user_id: userIdOrAuthObject.toString(),
      });
    } else if (typeof userIdOrAuthObject === 'object') {
      this.localStorage.replaceState(
        createAnonUserLocalStorageData(userIdOrAuthObject),
      );
    } else {
      throw new Error(`Invalid anonymous user id`);
    }
  }

  addTempUser(user_id: number): User {
    this.setAnonymousUser(user_id);
    return this.createAnonUser(user_id);
  }

  createAnonUser(user_id: number): User {
    return createAnonymousUser(user_id);
  }

  storeLastConversation(conversation: Conversation) {
    if (this.isAnonymousUser()) {
      this._authLocalStorage.updateState({ conversation_id: conversation.id });
    }
  }

  openLoginForm(returnUrl?: string) {
    this.router.navigate([{ outlets: { modal: 'auth' } }], {
      queryParams: { returnUrl, mode: 'login' },
    });
  }

  openSignUpForm(returnUrl?: string) {
    this.router.navigate([{ outlets: { modal: 'auth' } }], {
      queryParams: { returnUrl, mode: 'signup' },
    });
  }
}
