import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentModalComponent } from './payment-modal.component';
import { AddPaymentMethodFormModule } from '../../../../../shared/components/add-payment-method-form/add-payment-method-form.module';
import { ReceiptModule } from '../../../../../shared/components/receipt/receipt.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [PaymentModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    AddPaymentMethodFormModule,
    ReceiptModule,
  ],
})
export class PaymentModalModule {}
