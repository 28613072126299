<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <g clip-path="url(#clip0_5001_1302)">
    <path d="M7.91804 4.10314V6.50054C7.91804 6.88204 8.06959 7.24791 8.33935 7.51767C8.60911 7.78743 8.97498 7.93898 9.35648 7.93898C9.73798 7.93898 10.1039 7.78743 10.3736 7.51767C10.6434 7.24791 10.7949 6.88204 10.7949 6.50054V6.02106C10.7949 4.93889 10.4287 3.88856 9.75602 3.04087C9.08333 2.19318 8.14366 1.59797 7.08981 1.35203C6.03595 1.10609 4.92989 1.22389 3.95147 1.68626C2.97305 2.14864 2.17982 2.9284 1.70075 3.89875C1.22169 4.86911 1.08496 5.97299 1.31281 7.0309C1.54066 8.08881 2.11968 9.03854 2.95573 9.72565C3.79178 10.4128 4.83567 10.7968 5.91769 10.8155C6.9997 10.8341 8.05618 10.4861 8.91536 9.82813M7.91804 6.02106C7.91804 7.0803 7.05936 7.93898 6.00012 7.93898C4.94089 7.93898 4.08221 7.0803 4.08221 6.02106C4.08221 4.96182 4.94089 4.10314 6.00012 4.10314C7.05936 4.10314 7.91804 4.96182 7.91804 6.02106Z" [attr.stroke]="color()" [attr.stroke-width]="strokeWidth()" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_5001_1302">
      <rect width="100%" height="100%" fill="white" transform="translate(0.246338 0.265625)"/>
    </clipPath>
  </defs>
</svg>
