<svg
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4339_52843)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.1479 5.30421C13.6185 5.57696 13.7798 6.17812 13.509 6.64874L11.0504 10.907C10.7777 11.3757 10.1765 11.537 9.70591 11.2662C9.23726 10.9954 9.07594 10.3923 9.34674 9.9236L11.8053 5.66531C12.0761 5.19473 12.6792 5.03337 13.1479 5.30421Z"
      fill="#757575"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.2279 1.42394C14.9358 -0.477599 10.7255 0.649889 8.82402 3.94394L2.92352 14.1621C1.02198 17.4562 2.15139 21.6666 5.44352 23.566C8.73566 25.4676 12.946 24.3401 14.8474 21.046L17.7958 15.9387L17.7977 15.9367L20.7479 10.8274C22.6495 7.53336 21.5201 3.3234 18.2279 1.42394ZM16.094 14.9538L19.0443 9.84444C20.4022 7.49152 19.5955 4.48384 17.2445 3.1277C14.8936 1.76975 11.8859 2.57453 10.5278 4.92745L7.57754 10.0368C7.24909 10.6034 7.23756 11.4216 7.71391 12.382C8.18449 13.3366 9.09492 14.32 10.3607 15.0499C11.6264 15.7817 12.9345 16.0775 13.9947 16.0083C15.0646 15.9392 15.7656 15.5224 16.0921 14.9558L16.094 14.9538ZM14.3635 17.9521C14.2828 17.9597 14.2021 17.9655 14.1214 17.9713C12.6213 18.0692 10.933 17.6524 9.37724 16.7535C7.82148 15.8546 6.61714 14.6004 5.95063 13.2539C5.91413 13.1809 5.87956 13.1079 5.8469 13.035L4.62722 15.1459C3.26927 17.4988 4.07597 20.5065 6.42697 21.8626C8.77793 23.2205 11.7856 22.4158 13.1437 20.0628L14.3635 17.9521Z"
      fill="#757575"
    />
  </g>
  <defs>
    <clipPath id="clip0_4339_52843">
      <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
    </clipPath>
  </defs>
</svg>
