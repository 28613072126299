import { ConversationUserSkill } from '../../../shared/models/ConversationUserSkill.model';
import { Skill } from '../../../shared/models/skill.model';
import { ConversationUserSkillType } from '../../../shared/model-based-components/expertise-title-card/expertise-title-card.interfaces';
import { categoryFactory } from './responses/categoory.model';

class OpenRequestSkill implements ConversationUserSkillType {
  readonly skill;
}

const skillFactory = (from: Skill) => {
  const newSkill = new Skill();
  Object.assign(newSkill, {
    ...from,
    artcategory_skills: from.artcategory_skills.map(categoryFactory),
  });
  return newSkill;
};

export const openRequestSkillFactory = (
  from: Partial<ConversationUserSkill> & { skill: Skill },
) => {
  const newOpenRequestSkill = new OpenRequestSkill();
  Object.assign(newOpenRequestSkill, {
    ...from,
    skill: skillFactory(from.skill),
  });
  return newOpenRequestSkill;
};
