<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 40" fill="none">
  <g clip-path="url(#clip0_5223_6987)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.20703 9.85753C4.20703 14.9849 8.36306 19.1407 13.4902 19.1407C18.6195 19.1407 22.7753 14.9847 22.7753 9.85753C22.7753 4.72829 18.6193 0.572449 13.4902 0.572449C8.36287 0.572449 4.20703 4.72848 4.20703 9.85753ZM13.4902 16.2088C9.98289 16.2088 7.13897 13.3648 7.13897 9.85753C7.13897 6.34836 9.98294 3.50438 13.4902 3.50438C16.9994 3.50438 19.8434 6.34836 19.8434 9.85753C19.8434 13.3649 16.9994 16.2088 13.4902 16.2088Z" [attr.fill]="fill()"/>
    <path d="M25.7073 25.9829H22.7754V30.8682H17.8882V33.8002H22.7754V38.6873H25.7073V33.8002H30.5945V30.8682H25.7073V25.9829Z" [attr.fill]="fill()"/>
    <path d="M16.5118 22.0724L10.4702 38.6873H0.296143V22.0724H16.5118Z" [attr.fill]="fill()"/>
  </g>
  <defs>
    <clipPath id="clip0_5223_6987">
      <rect width="100%" height="100%" [attr.fill]="fill()" transform="translate(0.294678 0.336777)"/>
    </clipPath>
  </defs>
</svg>
