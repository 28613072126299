<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M18.738 1.7424H11.0391C10.0943 1.71933 9.27638 2.39149 9.11384 3.3216L8.54758 6.87847C8.46684 7.39858 8.61889 7.92919 8.96389 8.32764C9.34137 8.76595 9.89294 9.01657 10.4728 9.01238H11.3589L11.1974 9.59751C10.8839 10.7803 11.4554 12.0198 12.5585 12.5504C12.7389 12.6427 12.9402 12.6909 13.1436 12.6899C13.28 12.6899 13.4163 12.6678 13.5463 12.6259C13.8609 12.5252 14.122 12.3029 14.2709 12.0083L15.7662 9.0092H18.7379C18.9319 9.01025 19.1185 8.9337 19.2569 8.79633C19.3943 8.66002 19.4729 8.47337 19.4729 8.27937V2.48367C19.475 2.28758 19.3985 2.09883 19.2601 1.95937C19.1227 1.81991 18.9341 1.7424 18.738 1.7424ZM18.1309 7.67017H15.3931C15.1141 7.66807 14.8593 7.82536 14.7356 8.07493L13.1249 11.3255V11.3266C12.6258 11.0665 12.3689 10.4982 12.5041 9.95183L12.8774 8.59388C12.9393 8.37472 12.8942 8.14088 12.7569 7.95947C12.6121 7.77072 12.3856 7.66271 12.1476 7.67006H10.473C10.2863 7.67426 10.1081 7.59666 9.98434 7.45824C9.89416 7.35653 9.85431 7.22126 9.87424 7.08704L10.4405 3.53338C10.5055 3.25971 10.7572 3.07094 11.0393 3.08457H18.131L18.1309 7.67017Z"
    [attr.fill]="color()" />
  <path
    d="M17.0279 1.74243C16.6578 1.74243 16.3568 2.04338 16.3568 2.41354V8.34131C16.3568 8.71147 16.6578 9.01242 17.0279 9.01242C17.3991 9.01242 17.699 8.71147 17.699 8.34131V2.41354C17.699 2.23632 17.6288 2.0654 17.5029 1.93958C17.3771 1.81376 17.2062 1.74243 17.0279 1.74243Z"
    [attr.fill]="color()" />
  <path
    d="M11.0358 11.6726C10.6583 11.2343 10.1067 10.9837 9.52685 10.9879H8.64077L8.80225 10.4028C9.1158 9.21995 8.5443 7.98049 7.44116 7.44989C7.13498 7.29469 6.7795 7.26743 6.45337 7.37439C6.13878 7.47505 5.8777 7.69735 5.72879 7.99202L4.23348 10.9879H1.26181C1.06782 10.9868 0.881157 11.0633 0.742747 11.2007C0.605379 11.3381 0.526733 11.5237 0.526733 11.7177V17.5166C0.526733 17.7106 0.605379 17.8973 0.742747 18.0336C0.881162 18.1709 1.06781 18.2475 1.26181 18.2464H8.96077C9.90556 18.2706 10.7235 17.5984 10.886 16.6683L11.4523 13.1114C11.5299 12.5944 11.3776 12.068 11.0358 11.6726ZM10.1256 12.9131L9.55936 16.4668C9.49434 16.7405 9.24267 16.9292 8.96059 16.9156H1.86885V12.3301H4.60671C4.88565 12.3322 5.14045 12.1749 5.26418 11.9253L6.87484 8.67471V8.67366C7.37398 8.93372 7.63088 9.50205 7.49562 10.0484L7.12232 11.4063C7.06045 11.6255 7.10554 11.8593 7.24291 12.0408C7.38762 12.2295 7.61411 12.3375 7.85214 12.3302H9.53515C9.72075 12.326 9.90007 12.4036 10.0238 12.542C10.1108 12.6447 10.1476 12.78 10.1256 12.9131Z"
    [attr.fill]="color()" />
  <path
    d="M2.97189 10.9882C2.79363 10.9871 2.6227 11.0584 2.49688 11.1843C2.37 11.3101 2.29974 11.481 2.30079 11.6593V17.587C2.30079 17.9572 2.6007 18.2582 2.9719 18.2582C3.34206 18.2582 3.64301 17.9572 3.64301 17.587V11.6593C3.64406 11.481 3.57275 11.3101 3.44692 11.1843C3.32109 11.0584 3.15014 10.9871 2.97189 10.9882Z"
    [attr.fill]="color()" />
</svg>
