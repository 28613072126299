<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.55976 6.55473H9.27442V8.30474C9.27442 8.53771 9.42082 8.74424 9.64054 8.82171L9.6428 8.82248C9.69863 8.84158 9.75958 8.85316 9.82285 8.85316C9.98655 8.85316 10.1437 8.77985 10.2494 8.64894L10.2495 8.64887L11.9388 6.55473H19.9104C20.2132 6.55473 20.4588 6.30911 20.4588 6.00628V0.953258C20.4588 0.649524 20.2123 0.40566 19.9104 0.405651C19.9104 0.405651 19.9104 0.405651 19.9104 0.405651L7.55978 0.40481H7.55976C7.25695 0.40481 7.01132 0.650425 7.01132 0.953255V6.00628C7.01132 6.30909 7.25693 6.55473 7.55976 6.55473ZM11.677 5.45786H11.6769C11.5133 5.45786 11.3545 5.53136 11.2498 5.66283L10.3713 6.75153V6.0063C10.3713 5.70349 10.1257 5.45786 9.82286 5.45786H8.10821V1.50082H19.3629V5.45948L11.677 5.45786Z"
    [attr.fill]="color()"
    [attr.stroke]="color()"
    stroke-width="0.514478" />
  <path
    d="M10.9586 3.45271C10.9586 3.91456 10.5833 4.28995 10.1214 4.28995C9.65957 4.28995 9.28418 3.91456 9.28418 3.45271C9.28418 2.99087 9.65957 2.61548 10.1214 2.61548C10.5833 2.61548 10.9586 2.99087 10.9586 3.45271Z"
    [attr.fill]="color()" />
  <path
    d="M13.3676 3.45271C13.3676 3.91456 12.9922 4.28995 12.5303 4.28995C12.0685 4.28995 11.6931 3.91456 11.6931 3.45271C11.6931 2.99087 12.0685 2.61548 12.5303 2.61548C12.9922 2.61548 13.3676 2.99087 13.3676 3.45271Z"
    [attr.fill]="color()" />
  <path
    d="M15.7765 3.45271C15.7765 3.91456 15.4011 4.28995 14.9392 4.28995C14.4774 4.28995 14.102 3.91456 14.102 3.45271C14.102 2.99087 14.4774 2.61548 14.9392 2.61548C15.4011 2.61548 15.7765 2.99087 15.7765 3.45271Z"
    [attr.fill]="color()" />
  <path
    d="M18.1854 3.45271C18.1854 3.91456 17.81 4.28995 17.3481 4.28995C16.8863 4.28995 16.5109 3.91456 16.5109 3.45271C16.5109 2.99087 16.8863 2.61548 17.3481 2.61548C17.81 2.61548 18.1854 2.99087 18.1854 3.45271Z"
    [attr.fill]="color()" />
  <path
    d="M11.557 20.6651C11.557 23.3973 6.89909 23.0516 6.89909 23.0516C6.89909 23.0516 2.24121 23.3973 2.24121 20.6651C2.24121 17.9329 4.33149 15.7109 6.89909 15.7109C9.46668 15.7109 11.557 17.9329 11.557 20.6651Z"
    [attr.fill]="color()" />
  <path
    d="M6.89984 14.7232C8.49059 14.7232 9.78015 13.4336 9.78015 11.8429C9.78015 10.2521 8.49059 8.96259 6.89984 8.96259C5.30909 8.96259 4.01953 10.2521 4.01953 11.8429C4.01953 13.4336 5.30909 14.7232 6.89984 14.7232Z"
    [attr.fill]="color()" />
  <path
    d="M21.7069 20.6651C21.7069 23.3973 17.049 23.0516 17.049 23.0516C17.049 23.0516 12.3911 23.3973 12.3911 20.6651C12.3911 17.9329 14.4814 15.7109 17.049 15.7109C19.6166 15.7109 21.7069 17.9329 21.7069 20.6651Z"
    [attr.fill]="color()" />
  <path
    d="M17.0502 14.7232C18.641 14.7232 19.9305 13.4336 19.9305 11.8429C19.9305 10.2521 18.641 8.96259 17.0502 8.96259C15.4595 8.96259 14.1699 10.2521 14.1699 11.8429C14.1699 13.4336 15.4595 14.7232 17.0502 14.7232Z"
    [attr.fill]="color()" />
</svg>
