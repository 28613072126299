export const AUTH_TEXT_CONTENT = {
  SIGN_UP: {
    title: 'Welcome back to getme.global',
    subtitle: 'Please enter your email and password to sign in.',
  },
  SIGN_IN: {
    title: 'Sign Up for Free to get conversations started',
    subtitle: 'Please enter your email and a password to sign up.',
  },
};
