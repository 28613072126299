<app-filter-panel
  [filterItems]="artCategories()"
  (filtersChanged)="filtersChanged($event)"
></app-filter-panel>

<div class="items-list">
  @for (skill of notSelectedSkills(); track skill.id) {
    <app-expertise-card
      [skill]="skill"
      (click)="addToSelected(skill)"
    ></app-expertise-card>
  }
</div>

<div class="items-list">
  @for (skill of selectedSkills(); track skill.id) {
    <app-expertise-card
      [active]="true"
      [skill]="skill"
      (close)="removeFromSelected(skill)"
    ></app-expertise-card>
  }
</div>

<app-ice-breakers-list
  class="items-list"
  [iceBreakers]="iceBreakers()"
></app-ice-breakers-list>


