import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { BaseIconDirective } from '../base-icon.directive';

@Component({
  selector: 'app-requests-icon',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4954 21.7849C16.312 21.7849 16.1286 21.7291 15.9771 21.6254L10.8016 18.0368C8.0424 18.0129 5.4427 17.1357 3.47298 15.5568C1.41554 13.906 0.29113 11.705 0.29113 9.3366C0.29113 6.97613 1.42352 4.76718 3.47298 3.11644C5.46662 1.51355 8.1062 0.636353 10.9053 0.636353C13.7043 0.636353 16.3439 1.51355 18.3376 3.11644C20.395 4.76718 21.5194 6.96815 21.5194 9.3366C21.5194 12.1676 19.8846 14.7673 17.1095 16.4021L17.4125 20.804C17.4364 21.1549 17.261 21.4898 16.95 21.6653C16.8064 21.745 16.647 21.7849 16.4954 21.7849ZM11.0807 16.2346C11.2641 16.2346 11.4475 16.2745 11.5991 16.3781L15.4508 19.0337L15.2355 15.9475C15.2115 15.5807 15.4029 15.2378 15.7299 15.0703C18.202 13.7864 19.6853 11.6492 19.6853 9.34457C19.6853 5.55666 15.7458 2.47848 10.9053 2.47848C6.05674 2.4705 2.1173 5.54868 2.1173 9.3366C2.1173 13.1245 6.05674 16.2027 10.8973 16.2027C10.9451 16.2027 11.001 16.2186 11.0488 16.2186L11.0648 16.2346C11.0648 16.2346 11.0727 16.2346 11.0807 16.2346Z"
      [attr.fill]="currentColor()"
    />
    <path
      d="M15.6981 11.625C15.6981 12.9487 13.4413 12.7813 13.4413 12.7813C13.4413 12.7813 11.1845 12.9487 11.1845 11.625C11.1845 10.3012 12.1972 9.22461 13.4413 9.22461C14.6853 9.22461 15.6981 10.3012 15.6981 11.625Z"
      [attr.fill]="currentColor()"
    />
    <path
      d="M13.4411 8.74617C14.2119 8.74617 14.8367 8.12137 14.8367 7.35063C14.8367 6.57989 14.2119 5.95508 13.4411 5.95508C12.6704 5.95508 12.0456 6.57989 12.0456 7.35063C12.0456 8.12137 12.6704 8.74617 13.4411 8.74617Z"
      [attr.fill]="currentColor()"
    />
    <path
      d="M9.46175 8.81857H8.74404V8.10086C8.74404 7.74997 8.45696 7.46289 8.10607 7.46289C7.75519 7.46289 7.46811 7.74997 7.46811 8.10086V8.81857H6.7504C6.39952 8.81857 6.11243 9.10565 6.11243 9.45653C6.11243 9.80741 6.39952 10.0945 6.7504 10.0945H7.46811V10.8122C7.46811 11.1631 7.75519 11.4502 8.10607 11.4502C8.45696 11.4502 8.74404 11.1631 8.74404 10.8122V10.0945H9.46175C9.81263 10.0945 10.0997 9.80741 10.0997 9.45653C10.0997 9.10565 9.81263 8.81857 9.46175 8.81857Z"
      [attr.fill]="currentColor()"
    />
  </svg>`,
})
export class RequestsIconComponent extends BaseIconDirective {
  private readonly _defaultColor = '#757575';
  private readonly _activeColor = '#1E1E1E';
  readonly color = input(this._defaultColor);
  readonly isActive = input<boolean>(false);
  readonly currentColor = computed(() =>
    this.isActive() ? this._activeColor : this.color(),
  );
}
