import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';

@Injectable()
export class ResizeService {
  private readonly _BREAKPOINTS = {
    DESKTOP: 1280,
    MEDIUM: 940,
    SMALL: 768,
    SMALLEST: 400, // I don't know what for is it
  } as const;

  readonly resize$ = fromEvent(window, 'resize').pipe(
    map((_: Event & { target: Window }) => _.target.innerWidth),
    debounceTime(500),
    startWith(window.innerWidth),
  );

  readonly isDesktop$ = this.resize$.pipe(
    map((_) => _ >= this._BREAKPOINTS.DESKTOP),
  );

  readonly isNotDesktopScreen$ = this.resize$.pipe(
    map(_ => _ < this._BREAKPOINTS.DESKTOP),
  );

  readonly isMedium$ = this.resize$.pipe(
    map((_) => _ < this._BREAKPOINTS.DESKTOP && _ >= this._BREAKPOINTS.MEDIUM),
  );

  readonly isSmall$ = this.resize$.pipe(
    map((_) => _ < this._BREAKPOINTS.MEDIUM && _ >= this._BREAKPOINTS.SMALL),
  );

  readonly isNotSmall$ = this.resize$.pipe(
    map((_) => _ >= this._BREAKPOINTS.MEDIUM),
  );

  readonly isSmallest$ = this.resize$.pipe(
    map((_) => _ < this._BREAKPOINTS.SMALL),
  );
}
