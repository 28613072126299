import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostListener,
  input,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { Conversation } from '../../../../shared/models/conversation.model';

@Component({
  selector: 'app-conversation-request-more-button',
  templateUrl: './conversation-request-more-button.component.html',
  styleUrls: ['./conversation-request-more-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationRequestMoreButtonComponent {
  private readonly _mouseOver = signal(false);
  readonly textColor = computed(() =>
    this._mouseOver() ? '#1e1e1e' : '#303030',
  );
  public disable = input<boolean>(false);

  @HostListener('mouseover')
  mouseOverHandler() {
    this._mouseOver.set(true);
  }

  @HostListener('mouseleave')
  mouseLeaveHandler() {
    this._mouseOver.set(false);
  }
}
