<svg
  width="45"
  height="44"
  viewBox="0 0 45 44"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1 22C1 10.1259 10.6259 0.5 22.5 0.5C34.3741 0.5 44 10.1259 44 22C44 33.8741 34.3741 43.5 22.5 43.5C10.6259 43.5 1 33.8741 1 22Z"
    fill="#2C2C2C"
  />
  <path
    d="M1 22C1 10.1259 10.6259 0.5 22.5 0.5C34.3741 0.5 44 10.1259 44 22C44 33.8741 34.3741 43.5 22.5 43.5C10.6259 43.5 1 33.8741 1 22Z"
    stroke="#2C2C2C"
    stroke-linecap="round"
    [attr.stroke]="color()"
  />
  <g clip-path="url(#clip0_5275_4394)">
    <path
      d="M15.833 29.5V23.6667M15.833 20.3333V14.5M22.4997 29.5V22M22.4997 18.6667V14.5M29.1663 29.5V25.3333M29.1663 22V14.5M13.333 23.6667H18.333M19.9997 18.6667H24.9997M26.6663 25.3333H31.6663"
      [attr.stroke]="color()"
      [attr.stroke-width]="strokeWidth()"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_5275_4394">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="translate(12.5 12)"
      />
    </clipPath>
  </defs>
</svg>
