<div class="authform-container" [ngClass]="{login: isLoginMode}" #authRef>
  <div class="authform__image-container" [ngClass]="{signup: isSignupMode}">
    <div class="authform__logo-container">
      <app-ngo-ngo-logo-icon fill="#1E1E1E"></app-ngo-ngo-logo-icon>
    </div>
    <img [ngSrc]="isLoginMode ? 'assets/auth/login-image.jpg' : 'assets/auth/signup-image.jpg'" height="1724"
         width="1221"
         priority="true" [alt]=" 'header.image-alt' | translate ">
  </div>

  <div class="authform" [@slideTop]="startAnimations()" [ngClass]="{login: isLoginMode, signup: isSignupMode}">
    <div class="authform__greeter">
      @if (isLoginMode) {
        <app-open-heart-icon></app-open-heart-icon>
      } @else {
        <app-new-user-icon fill="#2C2C2C"></app-new-user-icon>
      }

      <h1 class="authform__title">{{ title() }}</h1>
    </div>

    <form class="form" [formGroup]="form" (submit)="onSubmit()">
      @if (form.get('username'); as nameForm) {
        <app-input [control]="form.get('username')" inputName="username" [placeholder]="'validation.unique' | translate"
                   type="text" [label]="'fields.username' | translate">

          @if (!(
            (nameForm.touched && nameForm.errors?.required) ||
            (nameForm.value && nameForm.errors?.pattern)
          )) {
            <span>{{ 'validation.error' | translate }}</span>
          }
          @if (nameForm.touched && nameForm.errors?.required) {
            <span
            >{{ 'validation.required-field' | translate }}</span
            >
          }
          @if (nameForm.value && nameForm.errors?.pattern) {
            <span>{{ 'validation.username-valid' | translate }}</span>
          }
        </app-input>
      }

      <app-input [control]="form.get('email')" inputName="email" placeholder="example@mail.org" type="email"
                 [label]="'fields.email' | translate">
        <span>{{ 'validation.email-valid' | translate }}</span>
      </app-input>

      <app-input [control]="form.get('password')" inputName="password"
                 [placeholder]="'validation.password-placeholder' | translate: {min: 6}" type="password"
                 [label]="'fields.password' | translate">
        <span>{{ 'validation.min-length' | translate:{ min: 6 } }}</span>
      </app-input>

      @if (form.get('newPassword')) {
        <app-input [control]="form.get('newPassword')" inputName="newPassword"
                   [placeholder]="'validation.password-placeholder' | translate: {min: 6}" type="password"
                   [label]="'fields.password' | translate">
          <span>{{ 'validation.min-length' | translate :{ min: 6 } }}</span>
        </app-input>
      }

      @if (isSignupMode) {
        <div class="form-field">
          <label class="form-label" for="findOrgInput">{{ 'fields.org' | translate }}
            <span>({{ 'fields.optional' | translate }})</span></label>
          <input
            [placeholder]="'fields.org-placeholder' | translate"
            id="findOrgInput"
            class="form-input"
            autocomplete="off"
            [formControl]="organizationsControl"
            [appAutocomplete]="autocomplete">
          <app-search-icon></app-search-icon>
        </div>

        <app-autocomplete (valueChange)="organizationSelected($event)" #autocomplete="appAutocomplete">
          <ng-template appAutocompleteContent>
            @for (option of organizations(); track option.id) {
              <app-autocomplete-option [value]="option">
                {{ option.full_name }}
              </app-autocomplete-option>
            }
            <app-autocomplete-option *ngIf="!organizations().length">{{ 'fields.no-result'  | translate }}
            </app-autocomplete-option>
          </ng-template>
        </app-autocomplete>
      }

      @if (isSignupMode && form.get('accepted')) {
        <div class="terms-checkbox">
          <mat-checkbox formControlName="accepted"
          >{{ 'fields.accept-terms' | translate }}
          </mat-checkbox>
          <a [href]="termsLink" target="_blank">{{ 'fields.read-terms' | translate }}</a>
        </div>
      }

      <div *ngIf="error">
        <p class="mat-error">{{ error }}</p>
        <button
          class="resend"
          mat-button
          *ngIf="shouldShowResendConfirmation"
          (click)="didRequestConfirmation(mail.value)"
        >
          {{ 'actions.resend-confirmation-email' | translate }}
        </button>
      </div>


      <div class="btn-group end">
        <app-button size="large" btnUIType="neutral" type="reset"
                    (click)="outletService.closeModalOutlet()">{{ 'actions.cancel' | translate }}
        </app-button>

        <app-button class="submit-btn" size="large" btnUIType="primary" type="submit"
                    [disabled]="form.invalid || (form.get('accepted') && form.get('accepted')?.value === false)">
          <div *ngIf="isLoading" class="spinner" role="status">
            <app-small-spinner [isWhite]="true" diameter="16"></app-small-spinner>
          </div>
          <ng-container *ngIf="isLoginMode">{{ 'header.sign-in' | translate }}</ng-container>
          <ng-container *ngIf="isSignupMode">{{ 'header.sign-up' | translate }}</ng-container>
          <ng-container *ngIf="isPasswordResetMode">{{ 'header.reset' | translate }}</ng-container>
        </app-button>
      </div>


      <div class="authform__login-switch" *ngIf="!isLoginMode">
        <p>
          {{ 'actions.already-have-an-account' | translate }}
          <a (click)="onSwitchMode('login')">{{ 'actions.sign-in-here' | translate }}</a>.
        </p>
      </div>
      <div class="authform__login-switch" *ngIf="!isSignupMode">
        <p>
          {{ 'actions.without-acc' | translate }}
          <a (click)="onSwitchMode('signup')">{{ 'actions.sign-up-here' | translate }}</a>.
        </p>
      </div>
      <div class="authform__login-switch forgot-password" *ngIf="!isPasswordResetMode">
        <p>
          {{ 'actions.forgot-password' | translate }}
          <a (click)="onSwitchMode('resetpassword')">{{ 'actions.reset-password' | translate }}</a>.
        </p>
      </div>
    </form>
  </div>


</div>


<!--<app-helpnavigation-->
<!--  url="DthGeVMDy54"-->
<!--  [toggle]="showIframe.asObservable()"-->
<!--  [hidden]="true"-->
<!--&gt;</app-helpnavigation>-->
