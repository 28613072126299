<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 25 25" fill="none">
  <g clip-path="url(#clip0_5980_4252)">
    <path d="M21.8125 4.82081C21.3018 4.30981 20.6953 3.90446 20.0279 3.62789C19.3604 3.35133 18.645 3.20898 17.9225 3.20898C17.2 3.20898 16.4846 3.35133 15.8172 3.62789C15.1497 3.90446 14.5433 4.30981 14.0325 4.82081L12.9725 5.88081L11.9125 4.82081C10.8808 3.78912 9.48156 3.20952 8.02253 3.20952C6.5635 3.20952 5.16422 3.78912 4.13253 4.82081C3.10084 5.8525 2.52124 7.25178 2.52124 8.71081C2.52124 10.1698 3.10084 11.5691 4.13253 12.6008L12.9725 21.4408L21.8125 12.6008C22.3235 12.0901 22.7289 11.4836 23.0054 10.8162C23.282 10.1487 23.4244 9.4333 23.4244 8.71081C23.4244 7.98832 23.282 7.27291 23.0054 6.60545C22.7289 5.938 22.3235 5.33157 21.8125 4.82081Z" [attr.stroke]="color()" [attr.stroke-width]="strokeWidth()" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_5980_4252">
      <rect width="100%" height="100%" fill="white" transform="translate(0.972534 0.210693)"/>
    </clipPath>
  </defs>
</svg>
