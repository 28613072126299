<div class="icon">
  @switch (activeRoute()) {
    @case ('/home') {
      <app-home-icon color="#1E1E1E" [strokeWidth]="2.5"></app-home-icon>
    }
    @case ('/conversations') {
      <app-conversations-icon color="#1E1E1E" [strokeWidth]="0.6"></app-conversations-icon>
    }
    @case ('/upload') {
      <app-upload-icon color="#1E1E1E"></app-upload-icon>
    }
    @case ('/settings') {
      <img src="../../../../../assets/header/settings.svg" alt="" />
    }
    @case ('/why') {
      <img src="../../../../../assets/header/why.svg" alt="">
    }
    @case ('/blog') {
      <app-blog-icon color="#1E1E1E"></app-blog-icon>
    }
    @case ('/open-requests') {
      <app-add-members-icon color="#1E1E1E" [strokeWidth]="1.38"></app-add-members-icon>
    }
    @case ('/help') {
      <app-like-icon color="#1E1E1E"></app-like-icon>
    }
    @case ('/settings') {
      <img src="../../../../../assets/header/settings.svg" alt="" />
    }
    @case ('/profile') {
      <img class="header__item-icon" src="assets/icons/user.svg" alt="" />
    }
    @case ('/news' || '/publicfeed') {
      <app-news-icon></app-news-icon>
    }
    @case ('/publicfeed') {
      <app-news-icon></app-news-icon>
    }
    @case ('/rateflow') {
      <app-icon-shape-impact></app-icon-shape-impact>
    }
    @case ('/insights') {
      <app-icon-option></app-icon-option>
    }
    @case ('/counsellor-desk') {
      <app-counsellor-desk-icon [strokeWidth]="2.1"></app-counsellor-desk-icon>
    }
    @case ('/ice-breaker') {
      <app-icon-capsule-closed></app-icon-capsule-closed>
    }
  }
</div>

@if (activeRoute() && menuItems[activeRoute()]) {
  <span class="route-title" [innerHTML]="menuItems[activeRoute()] | translate"></span>
}

