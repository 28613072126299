import { ChangeDetectionStrategy, Component, computed, effect, input, signal } from '@angular/core';
import { IceBreakerService } from '../../../profile/ice-breakers/services/ice-breaker.service';
import { IFilterPanelOutput } from '../../../../shared/complex-ui-components/filter-panel/filter-pannel.interface';
import { ArtCategory } from '../../../../services/artcategory/model/artcategory.model';
import { Skill } from '../../../../services/skill/model/skill.model';
import { toMeaningfulStr } from '../../../../shared/lib/string-helpers';
import { isArrayAndHasItems } from '../../../../shared/lib/array-helpers.lib';
import { take } from 'rxjs/operators';
import { IceBreaker } from '../../../profile/ice-breakers/services/model/response/ice-breaker.model';

@Component({
  selector: 'app-get-help-filter',
  templateUrl: './get-help-filter.component.html',
  styleUrls: ['./get-help-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetHelpFilterComponent {
  readonly artCategories = input<ArtCategory[]>([]);
  readonly inputSkills = input.required<Skill[]>();

  private readonly _loadedIceBreakers = signal<IceBreaker[]>([]);
  private readonly _skillFilters = signal<IFilterPanelOutput<ArtCategory> | null>(null);
  private readonly _filteredSkills = computed(() => {
    const skillFilters = this._skillFilters();
    let loadedSkills = [...this.inputSkills()];

    if (skillFilters) {
      const { textFilter, selectedFilters } = skillFilters;
      if (textFilter) {
        const meaningfulFilterStr = toMeaningfulStr(textFilter);
        loadedSkills = loadedSkills.filter(_ => _.contains(meaningfulFilterStr));
      }
      if (selectedFilters.length) {
        loadedSkills = loadedSkills.filter(_ => _.belongsTo(selectedFilters));
      }
    }
    return loadedSkills;
  });
  private readonly _selectedSkills = signal<Skill[]>([]);

  readonly notSelectedSkills = computed(() => {
    const selectedSkills = this._selectedSkills();
    return this._filteredSkills().filter(_ => !selectedSkills.some(skill => skill.isEqualTo(_)));
  });
  readonly selectedSkills = computed(() => {
    const filteredSkills = this._filteredSkills();
    return this._selectedSkills().filter(_ => filteredSkills.some(skill => skill.isEqualTo(_)));
  });
  readonly skillSelected = computed(() => this.selectedSkills().length);

  readonly iceBreakers = computed(() => {
    const selectedSkills = this.selectedSkills();
    const skillsAreSelected = isArrayAndHasItems(selectedSkills);
    let iceBreakers = skillsAreSelected ? this._loadedIceBreakers() : [];
    if (skillsAreSelected) {
      iceBreakers = iceBreakers.filter(_ => _.belongsTo(selectedSkills));
    }
    return iceBreakers;
  });


  constructor(
    private iceBreakerService: IceBreakerService,
  ) {
    effect(() => {
      const skills = this.inputSkills();
      this.iceBreakerService.fetchAllIceBreakers(skills)
        .pipe(take(1))
        .subscribe(_ => this._loadedIceBreakers.set(_));
    }, { allowSignalWrites: true });
  }


  filtersChanged(state: IFilterPanelOutput<ArtCategory>) {
    this._skillFilters.set(state);
  }

  addToSelected(skill: Skill) {
    const index = this._selectedSkills().findIndex(_ => _.isEqualTo(skill));
    if (index === -1) {
      const updatedSelectedList = [...this._selectedSkills()];
      updatedSelectedList.push(skill);
      this._selectedSkills.set(updatedSelectedList);
    }
  }

  removeFromSelected(skill: Skill) {
    const updatedSelectedList = this._selectedSkills().filter(_ => !_.isEqualTo(skill));
    this._selectedSkills.set(updatedSelectedList);
  }

}
