<app-conversation-request-more-button
  [disable]="isDisable()"
  (click)="toggleMenu()"
></app-conversation-request-more-button>

@if (isOpened()) {
  <app-dropdown-list
    [items]="menuItems()"
    alignBy='right'
    (selectedChange)="selectMenuItem($event)"
    (outsideClick)="closeMenu()"
  ></app-dropdown-list>
}

