<div class="avpresentation">
  <div class="avpresentation__wave">
    <div #waveformRef></div>

    <app-curves
      [file]="file"
      [avRatingParams]="avRatingParams"
      [duration]="duration"
      [playerExists]="!!wavesurfer"
      [toggleTimer$]="toggleTimer$"
      [filesFeedback$]="filesFeedback$"
      [mediaFileIsOver$]="mediaFileIsOver$"
      (seekTo)="seekTo($event)"
    ></app-curves>
  </div>

  <div class="avpresentation__rec-controls">
    <div class="avpresentation__track-controls">
      @if (displayPlayBtn()) {
        <img
          class="typeimage-av typeimage-av-v2 pulse"
          width="64"
          height="64"
          src="assets/flexfeedback/av-menu-active-v2.png"
          (click)="playpause()"
        />
      }
      <div class="avpresentation__track-controls--track">
        <p class="current-time">
          {{ currentTime | hms }}
        </p>

        <div class="track-line">
          <div class="track-line-progress" [style.width]="trackTimeWidth"></div>
        </div>

        <p class="track-time">
          {{ duration - currentTime | hms }}
        </p>
      </div>
    </div>
  </div>
</div>
