<div class="get-help">
  <div class="get-help__heading">
    <h2 class="get-help__title">{{ 'title' | translate }}</h2>
    <p class="get-help__subtitle">{{ 'subtitle' | translate }}</p>
  </div>
  <div class="get-help__content">
    @if (isLoading()) {
      <app-small-spinner [diameter]="26"></app-small-spinner>
    } @else {
      <app-get-help-filter
        [inputSkills]="loadedSkills()"
        [artCategories]="artCategories()"
      ></app-get-help-filter>
    }
  </div>
</div>
