import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '../../../models/user.model';
import { MatDivider } from '@angular/material/divider';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AddMembersIconComponent } from '../../../icons/add-members-icon/add-members-icon.component';
import { BlogIconComponent } from '../../../icons/blog-icon/blog-icon.component';
import { ConversationsIconComponent } from '../../../icons/conversations-icon/conversations-icon.component';
import { CounsellorDeskIconComponent } from '../../../icons/counsellor-desk-icon/counsellor-desk-icon.component';
import { HomeIconComponent } from '../../../icons/home-icon/home-icon.component';
import { LikeIconComponent } from '../../../icons/like-icon/like-icon.component';
import { NewsIconComponent } from '../../../icons/news-icon/news-icon.component';
import { RequestIconComponent } from '../../../icons/request-icon/request-icon.component';
import { HeaderMobileNavbarItemComponent } from '../header-mobile-navbar-item/header-mobile-navbar-item.component';
import { IconMoreVerticalComponent } from '../../../icons/icon-more-vertical/icon-more-vertical.component';
import { PermissionService } from '../../../../auth/service/permission.service';
import { IconOptionComponent } from '../../../icons/icon-option/icon-option.component';
import { AuthService } from '../../../../auth/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { GiveFeedbackIconComponent } from '../../../icons/give-feedback-icon/give-feedback-icon.component';
import { fromEvent } from 'rxjs';
import { IconShapeImpactComponent } from '../../../icons/icon-shape-impact/icon-shape-impact.component';
import { CounsellorDeskModule } from '../../../../pages/counsellor-desk/counsellor-desk.module';
import { IconCapsuleClosedComponent } from '../../../icons/icon-capsule-closed/icon-capsule-closed.component';
import { IconPublicProfileComponent } from '../../../icons/icon-public-profile/icon-public-profile.component';
import { DropdownListItemComponent } from '../../../UIkit/dropdown-list-item/dropdown-list-item.component';
import { IconGlobeComponent } from '../../../icons/icon-globe/icon-globe.component';
import { IconOrgChatComponent } from '../../../icons/icon-org-chat/icon-org-chat.component';
import { SettingsIconComponent } from '../../../icons/settings-icon/settings-icon.component';
import { IconBriefingsComponent } from '../../../icons/icon-briefings/icon-briefings.component';
import { IconLogOutComponent } from '../../../icons/icon-log-out/icon-log-out.component';
import { IconSettingsGearComponent } from '../../../icons/icon-settings-gear/icon-settings-gear.component';
import { ResizeService } from '../../../../services/resize.service';

@Component({
  selector: 'app-dropdown-menu',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconButton,
    MatIcon,
    MatMenu,
    TranslateModule,
    MatDivider,
    RouterLinkActive,
    MatMenuItem,
    RouterLink,
    MatMenuTrigger,
    AddMembersIconComponent,
    BlogIconComponent,
    ConversationsIconComponent,
    CounsellorDeskIconComponent,
    HomeIconComponent,
    LikeIconComponent,
    NewsIconComponent,
    RequestIconComponent,
    HeaderMobileNavbarItemComponent,
    IconMoreVerticalComponent,
    IconOptionComponent,
    GiveFeedbackIconComponent,
    IconShapeImpactComponent,
    CounsellorDeskModule,
    IconCapsuleClosedComponent,
    IconPublicProfileComponent,
    DropdownListItemComponent,
    IconGlobeComponent,
    IconOrgChatComponent,
    SettingsIconComponent,
    IconBriefingsComponent,
    IconLogOutComponent,
    IconSettingsGearComponent,
  ],
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
})
export class DropDownMenuComponent {
  readonly authorizedUser = toSignal(this._authService.userIsSignedIn$);
  readonly authUserShareToken = toSignal(this._authService.authUserShareToken$, { initialValue: null });
  readonly isSignedInAsClient = toSignal(this.permissionService.isClient$);
  readonly isSignedInAsCounselor = toSignal(this.permissionService.isCounselor$);
  readonly isAdmin = toSignal(this.permissionService.isAdmin$);
  readonly isNotDesktop = toSignal(this._resizeService.isNotDesktopScreen$);

  constructor(
    private readonly _authService: AuthService,
    readonly permissionService: PermissionService,
    private readonly _resizeService: ResizeService
  ) {}

  public logoutEvent = output();

  public onLogout() {
    this.logoutEvent.emit();
  }
}
