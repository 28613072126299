<svg
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.499023 0.710693H90.499V58.16H83.8753C83.6551 51.5087 81.2928 45.503 75.2822 42.4251C74.8618 40.9012 74.051 39.4973 72.9124 38.3587C71.1833 36.6296 68.8461 35.6587 66.4013 35.6587C63.954 35.6587 61.6167 36.6296 59.8901 38.3587C58.7515 39.4973 57.9408 40.9012 57.5204 42.4251C51.5072 45.503 49.1475 51.5087 48.9273 58.16H33.305L17.918 73.547V58.16H0.499023V0.710693ZM11.1316 12.6169H66.0034V18.2873H11.1316V12.6169ZM11.1316 27.9589H53.3264V33.6293H11.1316V27.9589ZM66.4013 82.08H47.0705V77.1829C47.0705 77.1829 55.1481 74.2627 53.8019 61.9385C53.0386 53.2929 55.5535 47.6525 61.9571 45.7783V44.8699C61.9571 43.6462 62.455 42.5352 63.2608 41.7294C64.0666 40.9262 65.1776 40.4257 66.4013 40.4257C67.6249 40.4257 68.736 40.9262 69.5392 41.7294C70.345 42.5352 70.8455 43.6462 70.8455 44.8699V45.7783C77.249 47.6525 79.7614 53.2929 79.0007 61.9385C77.6519 74.2627 85.7295 77.1829 85.7295 77.1829V82.08H66.4013ZM64.5896 45.2478C65.1701 45.1777 65.7732 45.1301 66.4013 45.1076C67.0294 45.1301 67.6324 45.1777 68.2105 45.2478V44.8699C68.2105 44.3744 68.0078 43.9215 67.6775 43.5912C67.3497 43.2634 66.8967 43.0607 66.4013 43.0607C65.9033 43.0607 65.4504 43.2634 65.1226 43.5912C64.7947 43.9215 64.5896 44.3744 64.5896 44.8699V45.2478ZM72.1817 84.0094C72.2218 84.2896 72.2443 84.5749 72.2443 84.8677C72.2443 88.0932 69.6268 90.7107 66.4013 90.7107C63.1732 90.7107 60.5582 88.0932 60.5582 84.8677C60.5582 84.5749 60.5783 84.2896 60.6208 84.0094H66.4013H72.1817Z"
    fill="black"
  />
</svg>
