<div
  class="chat__message-box"
  [ngClass]="{ 'chat__message-box--collapsed': isCollapsed }"
>
  <div
    class="input-wrapper"
    [ngStyle]="{ 'paddingRight.px': inputBtns.clientWidth }"
  >
    <div class="marquee" #marqueeRef *ngIf="!isRecordingStopped">
      <div class="inner" *ngIf="showAnimation">
        <p>{{ textPlaceholder | translate }}</p>
      </div>
      <ng-container *ngIf="!showAnimation">{{ textPlaceholder | translate }}</ng-container>
    </div>

    <input
      class="input"
      type="text"
      *ngIf="isRecordingStopped"
      #messageInput
      (focus)="onInputFocus()"
    />
  </div>

  <div class="chat__input-btns" #inputBtns>
    <button
      class="skip-record-title"
      (click)="stopAudioRecord(true)"
      *ngIf="isRecordingStopped && !isCollapsed"
    >
      Skip
    </button>

    <div class="chat__input-btns-black">
      <button
        class="chat__input-btn chat__clear-recorder"
        (click)="deleteRecording(true)"
        *ngIf="!isAudioMessageUploading"
      >
        <img src="/assets/icons/delete.svg" />
      </button>

      <span
        class="chat__recorded-time"
        [ngClass]="{ 'pl-0': isAudioMessageUploading }"
      >
        <ng-container *ngIf="!(isRecordingStopped && isCollapsed)">
          {{ recordedTime$ | async | hms }}
        </ng-container>
      </span>

      <button
        class="skip-record-title"
        (click)="stopAudioRecord(true)"
        *ngIf="isRecordingStopped && isCollapsed"
      > {{ 'audio_record.skip' | translate }} </button>

      <ng-container *ngIf="!isStopped">
        <button
          class="chat__input-btn chat__pause-recorder"
          (click)="pauseAudioRecord()"
          *ngIf="!isRecordingPaused"
        >
          <img src="assets/icons/pause.svg" alt="pause" />
        </button>

        <button
          class="chat__input-btn chat__play-recorder"
          (click)="startAudioRecord()"
          *ngIf="isRecordingPaused"
        >
          <img src="assets/icons/play.svg" alt="play" />
        </button>
      </ng-container>

      <button class="chat__input-btn chat__record-btn">
        <img
          src="/assets/icons/microphone.svg"
          [ngClass]="{
            white: isRecordingStopped,
            yellow: isRecordingPaused && !isRecordingStopped,
            red: !isRecordingPaused && !isRecordingStopped,
          }"
        />
      </button>

      <button
        (click)="stopAudioRecord()"
        class="chat__input-btn chat__send-btn"
        [disabled]="isAudioMessageUploading || !recordedTime$.value"
      >
        <img [attr.src]="sendBtnSrc" class="white" />
      </button>
    </div>
  </div>
</div>
