<svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_4840_1021)">
    <g clip-path="url(#clip1_4840_1021)">
      <path d="M14.3768 3.03074C16.6333 5.28725 17.2195 8.6932 15.9436 11.5395C15.8729 11.6972 15.7178 11.7987 15.5452 11.8086L15.4225 11.8156C15.0327 11.8379 14.7718 11.4033 14.9296 11.0461C15.9919 8.64163 15.4863 5.74023 13.5768 3.83074C11.8054 2.05931 9.17683 1.48789 6.87207 2.28789C6.51016 2.40217 6.16731 2.5736 5.82445 2.76408L6.1257 3.21595C6.26252 3.42119 6.12548 3.69753 5.87929 3.71283L3.61548 3.85352C3.36967 3.8688 3.19947 3.61231 3.30905 3.39175L4.31991 1.35723C4.42969 1.13628 4.73766 1.11743 4.87357 1.32335L5.19588 1.8117C5.51969 1.62122 5.86254 1.44979 6.22445 1.31646C9.0054 0.249791 12.2244 0.878363 14.3768 3.03074ZM9.0054 16.0403C9.93874 16.0403 10.8721 15.8688 11.7483 15.526C12.0911 15.3927 12.434 15.2212 12.7768 15.0307L13.0991 15.5191C13.235 15.725 13.543 15.7062 13.6528 15.4852L14.662 13.4539C14.772 13.2327 14.6003 12.9756 14.3538 12.9923L12.0896 13.1458C11.8443 13.1624 11.7086 13.438 11.845 13.6425L12.1483 14.0974C11.8054 14.2879 11.4625 14.4593 11.1006 14.5736C8.79588 15.3736 6.16731 14.8022 4.39588 13.0307C2.48444 11.1193 1.9798 8.22923 3.04636 5.81279C3.20373 5.45626 2.94314 5.02342 2.55406 5.04565L2.42754 5.05288C2.25494 5.06274 2.09983 5.16424 2.02911 5.322C0.753171 8.16829 1.33938 11.5742 3.59588 13.8307C5.06254 15.2784 7.02445 16.0403 9.0054 16.0403Z" [attr.fill]="fill()"/>
      <path d="M13.5454 7.10306C13.4701 6.34023 13.0557 5.65274 12.3964 5.21953C11.3982 4.5603 10.042 4.71098 9.15678 5.59624L9.08144 5.67158L8.95901 5.54915C8.41279 5.01234 7.66879 4.73923 6.89654 4.81457C6.13371 4.88991 5.44623 5.30429 5.01302 5.95411C4.35378 6.95238 4.50446 8.30852 5.38972 9.19378L8.5258 12.3299C8.67648 12.4805 8.86483 12.5559 9.08144 12.5559C9.28863 12.5559 9.4864 12.4711 9.63708 12.3299L12.8108 9.15611C13.3476 8.6193 13.6207 7.86589 13.5454 7.10306ZM12.1045 8.4592L9.08144 11.4823L6.09604 8.49687C5.54982 7.95065 5.44623 7.1219 5.85119 6.51917C6.1243 6.11421 6.52926 5.85993 7.00014 5.81284C7.05664 5.80343 7.10373 5.80343 7.16024 5.80343C7.57461 5.80343 7.96074 5.96353 8.2621 6.25547L8.73299 6.72635C8.82716 6.82053 8.94959 6.87704 9.08144 6.87704C9.21328 6.87704 9.34513 6.82053 9.42989 6.72635L9.8631 6.29314C10.4093 5.74692 11.2381 5.64333 11.8408 6.04828C12.2458 6.3214 12.5 6.72635 12.5471 7.19724C12.5942 7.66812 12.4341 8.12017 12.1045 8.4592Z" [attr.fill]="fill()" [attr.stroke]="color()"  [attr.stroke-width]="strokeWidth()"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_4840_1021">
      <rect width="100%" height="100%" [attr.fill]="fill()" transform="translate(0.986267 0.421143)"/>
    </clipPath>
    <clipPath id="clip1_4840_1021">
      <rect width="100%" height="100%" [attr.fill]="fill()" transform="translate(0.986267 0.421143)"/>
    </clipPath>
  </defs>
</svg>
