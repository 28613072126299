<li
  class="dropdown-list-item"
  [ngClass]="{
    'dropdown-list-item--selected': selected(),
    'dropdown-list-item--disabled': disabled()
  }"
>
  @if (isIconTemplate()) {
    <div class="dropdown-list-item_icon">
      <ng-container *ngTemplateOutlet="icon();"></ng-container>
    </div>
  } @else if (isIconImgSrc()) {
    <img
      class="dropdown-list-item_icon"
      [src]="icon()"
      [alt]="(menuItem().icon_alt || '') | translate "
    />
  }
  <div class="dropdown-list-item_text">
    <h4
      class="dropdown-list-item_title"
    >{{ menuItem().title | translate }}</h4>

    <p class="dropdown-list-item_description">
      @if (menuItem().description) {
        {{ menuItem().description | translate }}
      }
    </p>
  </div>
</li>
