<button
  class="scroll-down-btn"
  (click)="clickedScrollDown.emit()"
  [ngStyle]="{
  'top': top || null,
  'bottom': bottom || null,
  'left': left || null,
  'right': right || null
  }"
>
  <div *ngIf="shownNumber" class="scroll-down-btn__number">{{shownNumber}}</div>
  <app-arrow-down-icon class="scroll-down-btn__icon"></app-arrow-down-icon>
</button>
