<div class="close-btn"
     (mouseenter) = "closeIconMouseOverHandler()"
     (mouseleave)="closeIconMouseLeaveHandler()"
>
  <app-close-icon
    class="close-btn_icon"
    [color]="closeIconMouseOver ? '#ffffff' : '#FEE9E7'"
    strokeWidth="1.80475"
    (click)="closeIconClickHandler()"
  ></app-close-icon>
</div>

<div class="expertise-card-content">
  <div class="expertise-card-content_icon">
    <ng-content></ng-content>
  </div>
  <div class="expertise-card-content_text">
    <h4 class="expertise-card-content_title">
      {{skillFilterName()}}
    </h4>
    <p class="expertise-card-content_sub-title">
      {{skillFilterCounselorsAmount()}} {{ counselorsWord() }}
    </p>
  </div>
</div>
