<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8442_14363)">
    <path
      d="M14.2311 6.42228H9.76875C8.53888 6.42228 7.53809 5.42041 7.53809 4.19058V3.07575C7.53809 1.84589 8.53891 0.845093 9.76875 0.845093H14.2311C15.462 0.845093 16.4618 1.84592 16.4618 3.07575V4.19162C16.4628 5.42041 15.462 6.42228 14.2311 6.42228ZM9.76982 3.07575V4.19162H14.2322L14.2312 3.07575H9.76982Z"
      [attr.fill]="color()" />
    <path
      d="M17.578 11.9994H10.8849C10.2689 11.9994 9.76904 11.5006 9.76904 10.8835C9.76904 10.2665 10.2689 9.76767 10.8849 9.76767H17.578C18.1939 9.76767 18.6938 10.2665 18.6938 10.8835C18.6938 11.5006 18.1939 11.9994 17.578 11.9994Z"
      [attr.fill]="color()" />
    <path
      d="M7.53828 10.8836C7.53828 12.3718 5.30762 12.3718 5.30762 10.8836C5.30762 9.39653 7.53828 9.39653 7.53828 10.8836Z"
      [attr.fill]="color()" />
    <path
      d="M17.578 17.5766H10.8849C10.2689 17.5766 9.76904 17.0778 9.76904 16.4608C9.76904 15.8438 10.2689 15.3449 10.8849 15.3449H17.578C18.1939 15.3449 18.6938 15.8438 18.6938 16.4608C18.6938 17.0778 18.1939 17.5766 17.578 17.5766Z"
      [attr.fill]="color()" />
    <path
      d="M7.53828 16.4618C7.53828 17.9489 5.30762 17.9489 5.30762 16.4618C5.30762 14.9737 7.53828 14.9737 7.53828 16.4618Z"
      [attr.fill]="color()" />
    <path
      d="M17.5778 23.1549H6.42345C3.34786 23.1549 0.846261 20.6523 0.846261 17.5777L0.845215 8.65293C0.845215 5.57734 3.3478 3.07574 6.4224 3.07574H8.65307C9.26904 3.07574 9.76893 3.57459 9.76893 4.19161H14.2313C14.2313 3.57459 14.7312 3.07574 15.3472 3.07574H17.5779C20.6534 3.07574 23.155 5.57833 23.155 8.65293V17.5766C23.1561 20.6522 20.6535 23.1549 17.5778 23.1549ZM6.42345 5.30641C4.57764 5.30641 3.07692 6.80712 3.07692 8.65293V17.5766C3.07692 19.4225 4.57764 20.9232 6.42345 20.9232H17.5789C19.4247 20.9232 20.9254 19.4225 20.9254 17.5766L20.9244 8.65293C20.9244 6.80712 19.4237 5.30641 17.5779 5.30641H16.1629C15.777 5.97362 15.0554 6.42227 14.2313 6.42227H9.76893C8.9459 6.42227 8.22431 5.97362 7.83841 5.30641H6.42345Z"
      [attr.fill]="color()" />
  </g>
  <defs>
    <clipPath id="clip0_8442_14363">
      <rect width="100%" height="100%" fill="white" />
    </clipPath>
  </defs>
</svg>
