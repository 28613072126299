<svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8442_14428)">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M4.04529 2.61121C5.39066 2.61121 6.48219 3.70274 6.48219 5.0481V34.8315C6.48219 37.9715 9.02826 40.5176 12.1683 40.5176H41.9543C43.2997 40.5176 44.3912 41.6091 44.3912 42.9545C44.3912 44.2999 43.2997 45.3914 41.9543 45.3914H12.1683C6.33747 45.3914 1.6084 40.6649 1.6084 34.8315V5.0481C1.6084 3.70274 2.69993 2.61121 4.04529 2.61121ZM39.8807 14.5442L41.3174 18.8113C41.7464 20.0881 43.1299 20.7735 44.4041 20.3445C45.6809 19.913 46.3663 18.5321 45.9374 17.2553L42.5968 7.34522C42.1729 6.08108 40.8097 5.39317 39.5405 5.80185L29.5213 9.0333C28.2419 9.44707 27.5363 10.8204 27.95 12.0998C28.3638 13.3817 29.7371 14.0848 31.0165 13.6711L35.6085 12.1912L30.2118 22.6315L24.1475 17.7958C23.6043 17.3617 22.9036 17.1815 22.2182 17.2983C21.5328 17.4176 20.9287 17.8212 20.5606 18.4101L12.8106 30.833C12.0973 31.9753 12.4451 33.4781 13.5874 34.1914C14.7297 34.9047 16.2324 34.5569 16.9432 33.4147L23.2487 23.3116L29.4932 28.2946C30.0644 28.749 30.8081 28.9241 31.5215 28.7718C32.2348 28.6195 32.844 28.155 33.1791 27.5077L39.8807 14.5442Z"
          [attr.fill]="color()"
    />
  </g>
  <defs>
    <clipPath id="clip0_8442_14428">
      <rect width="100%" height="100%" fill="white" />
    </clipPath>
  </defs>
</svg>

