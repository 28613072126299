<a
  class="header-nav-item"
  [routerLink]="link()"
  [queryParams]="queryParams()"
  routerLinkActive="active"
>
  <div class="header-nav-item__icon">
    <ng-content></ng-content>
  </div>
  <span class="header__item-text" [innerHTML]="title() | translate"></span>
</a>
