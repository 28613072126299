<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M3.57197 1.39524C3.57197 0.78728 3.07912 0.294434 2.47116 0.294434C1.86321 0.294434 1.37036 0.78728 1.37036 1.39524V14.6049C1.37036 15.2128 1.86321 15.7057 2.47116 15.7057C3.07912 15.7057 3.57197 15.2128 3.57197 14.6049V1.39524Z" fill="#F3F3F3"/>
  <path d="M7.42501 1.58211V3.77824C6.65435 3.66825 5.75726 3.59663 4.6731 3.59663V1.39502C5.76842 1.39502 6.65454 1.46665 7.42501 1.58211Z" fill="#F3F3F3"/>
  <path d="M4.67285 4.69751C5.77913 4.69751 6.65978 4.77462 7.42476 4.89008V7.08073C6.6541 6.97074 5.75702 6.89912 4.67285 6.89912V4.69751Z" fill="#F3F3F3"/>
  <path d="M4.67285 8C5.77913 8 6.65978 8.07711 7.42476 8.19257V10.3887C6.6541 10.273 5.76798 10.2016 4.67285 10.2016V8Z" fill="#F3F3F3"/>
  <path d="M10.0225 8.80352C10.5894 8.96868 11.1728 9.13385 11.8278 9.27143V11.4621C10.6499 11.1979 9.7033 10.8566 8.52539 10.5924V8.40723C9.0483 8.52288 9.53265 8.66046 10.0225 8.80352Z" fill="#F3F3F3"/>
  <path d="M15.6809 9.65133V10.7232C15.6809 11.3565 15.1452 11.8569 14.5129 11.8232C13.926 11.7919 13.4053 11.7373 12.929 11.6659V9.46973C13.6996 9.5799 14.5967 9.65133 15.6809 9.65133Z" fill="#F3F3F3"/>
  <path d="M10.0225 5.50127C10.5894 5.66644 11.1728 5.83161 11.8278 5.96918V8.1432C11.3049 8.02754 10.8205 7.88996 10.3307 7.74691C9.76377 7.58174 9.18039 7.41677 8.52539 7.27899V5.10498C9.0483 5.22064 9.53265 5.35821 10.0225 5.50127Z" fill="#F3F3F3"/>
  <path d="M15.6809 6.3486V8.55021C14.5746 8.55021 13.6939 8.4731 12.929 8.35764V6.16699C13.6996 6.27717 14.5967 6.3486 15.6809 6.3486Z" fill="#F3F3F3"/>
  <path d="M8.52539 1.78589C9.7033 2.05008 10.6499 2.39138 11.8278 2.65557V4.84074C11.3049 4.72508 10.8205 4.58751 10.3307 4.44445C9.76377 4.27928 9.18039 4.11431 8.52539 3.97654V1.78589Z" fill="#F3F3F3"/>
  <path d="M15.6809 4.12202V5.24807C14.5746 5.24807 13.6939 5.17096 12.929 5.0555V2.85938C13.4405 2.93609 14.0031 2.99343 14.6441 3.02337C15.2265 3.05057 15.6809 3.53904 15.6809 4.12202Z" fill="#F3F3F3"/>
</svg>
