<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
  <g clip-path="url(#clip0_7226_11188)">
    <path d="M19.3638 10.0487V12.0487C19.3638 13.9053 18.6263 15.6857 17.3135 16.9985C16.0008 18.3112 14.2203 19.0487 12.3638 19.0487M12.3638 19.0487C10.5073 19.0487 8.72678 18.3112 7.41402 16.9985C6.10127 15.6857 5.36377 13.9053 5.36377 12.0487V10.0487M12.3638 19.0487V22.7809M12.2832 1.32592C11.4875 1.32592 10.7245 1.64199 10.1618 2.2046C9.59924 2.76721 9.36377 3.25309 9.36377 4.04874V12.0487C9.36377 12.8444 9.67984 13.6074 10.2424 14.1701C10.8051 14.7327 11.5681 15.0487 12.3638 15.0487C13.1594 15.0487 13.9225 14.7327 14.4851 14.1701C15.0477 13.6074 15.3638 12.8444 15.3638 12.0487V4.04874C15.3638 3.25309 14.9671 2.76721 14.4045 2.2046C13.8419 1.64199 13.0788 1.32592 12.2832 1.32592Z" [attr.stroke]="color()" [attr.stroke-width]="strokeWidth()" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_7226_11188">
      <rect width="100%" height="100%" fill="white" transform="translate(0.36377 0.0488281)"/>
    </clipPath>
  </defs>
</svg>
