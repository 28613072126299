import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OutletService {
  constructor(
    private readonly _router: Router,
    private readonly _location: Location,
  ) {
  }

  private readonly _navigationEnd$ = this._router.events
    .pipe(filter(event => event instanceof NavigationEnd));

  readonly isModalActivate$ = this._navigationEnd$
    .pipe(map(() => 'modal' in this._router.parseUrl(this._router.url).root.children));

  closeModalOutlet(): void {
    this._router.navigate([{ outlets: { modal: null } }]);
  }

  navigateBack() {
    this._location.back();
  }
}
