<svg xmlns="http://www.w3.org/2000/svg" width="78" height="66" viewBox="0 0 78 66" fill="none">
  <g clip-path="url(#clip0_6027_11367)">
    <path d="M62.7904 52.5231C62.7108 53.1702 63.0142 53.3137 63.3882 53.3371C65.2702 53.4426 67.1109 53.8379 68.9782 54.0399C69.3581 54.0809 69.576 54.0341 69.3934 54.6051C69.0577 55.6505 68.5982 56.6403 68.236 57.671C68.1329 57.9639 67.9532 58.0517 67.5763 57.8907C65.9682 57.2054 64.3307 56.5934 62.7108 55.9375C61.7154 55.5334 61.6329 55.5685 61.3649 56.5817C61.0763 57.671 60.7906 58.7633 60.4578 59.8409C60.2664 60.4559 60.5962 60.693 61.0792 60.819C62.1159 61.0884 63.1644 61.3226 64.204 61.5803C65.1111 61.8029 66.0124 62.0489 66.9224 62.2509C67.2287 62.3183 67.2582 62.491 67.2758 62.7341C67.3406 63.5686 67.0962 64.3622 66.9313 65.1616C66.8311 65.6477 66.4424 65.352 66.2362 65.2905C65.1877 64.9742 64.151 64.617 63.1173 64.2597C61.1499 63.5833 59.1943 62.8776 57.2211 62.2216C56.0695 61.838 56.0106 61.7062 56.3463 60.5613C56.9383 58.5378 57.4596 56.4939 58.0192 54.4645C58.5051 52.7046 59.0029 50.9477 59.5036 49.1907C59.907 47.7792 60.2163 46.3444 60.6905 44.9505C60.8524 44.4761 60.9968 44.5025 61.415 44.6459C63.6386 45.4219 65.8828 46.1423 68.1182 46.8919C69.4229 47.3283 70.7158 47.7968 72.0293 48.2155C72.3504 48.318 72.4858 48.4615 72.3739 48.7514C72.103 49.4571 71.7849 50.1453 71.5228 50.8539C71.3166 51.4162 70.9337 51.3957 70.4979 51.22C68.6719 50.4762 66.8547 49.7031 65.0199 48.9828C64.4603 48.7631 63.9036 48.5699 63.7593 49.5831C63.6386 50.4528 63.1968 51.2786 62.9052 52.1248C62.8404 52.3122 62.7992 52.5055 62.7963 52.5201L62.7904 52.5231Z" fill="#FAD44F"/>
    <path d="M55.1122 61.5159C53.8929 61.7472 52.7796 61.431 51.6899 61.1499C51.2275 61.0298 51.0861 60.3651 50.8417 59.9317C49.643 57.7999 48.4856 55.6476 47.3075 53.507C46.8834 52.7368 46.0676 52.3737 45.4255 51.6914C45.1163 52.5289 45.1958 53.3606 45.0338 54.1454C44.8336 55.1029 44.6156 56.0956 44.6009 57.0824C44.5862 58.1161 44.1356 59.0766 44.1827 60.1074C44.1974 60.4441 43.9765 60.5466 43.6849 60.5466C43.4552 60.5466 43.2255 60.5437 42.9987 60.5203C41.8442 60.3973 41.7942 60.3592 41.8501 59.2289C42.0681 54.9214 42.4922 50.6226 42.61 46.3063C42.6424 45.0969 42.875 43.8817 42.8809 42.6576C42.8809 42.3911 42.8898 42.1042 43.255 42.113C43.7291 42.1247 44.1385 41.8289 44.5832 41.8231C47.0925 41.7996 49.6018 41.3516 52.1111 41.7908C54.7087 42.2447 55.5569 44.1745 55.0297 46.4556C54.5438 48.5582 53.51 50.2419 51.8136 51.548C51.6369 51.6856 51.4543 51.8818 51.2511 51.914C50.2703 52.078 50.3145 52.5143 50.7239 53.2669C52.2024 55.9726 53.6249 58.7106 55.1092 61.513L55.1122 61.5159ZM45.3018 48.5406C45.296 49.5362 47.228 50.9769 48.4208 50.4791C49.6784 49.955 50.8181 49.2141 51.7076 48.1628C52.2318 47.545 52.5028 46.4878 51.5574 45.7675C51.1686 45.4717 50.774 45.217 50.341 45.0178C49.0334 44.4087 47.7169 43.8202 46.2325 43.7821C45.8997 43.7733 45.617 43.7645 45.5934 44.233C45.5227 45.5654 45.4167 46.8949 45.3018 48.5406Z" fill="#FAD44F"/>
    <path d="M44.66 24.7803C44.6218 26.4553 44.1181 28.0454 43.8649 29.6823C43.6351 31.1581 43.2847 32.6135 42.9872 34.0777C42.9519 34.2446 42.9106 34.4086 42.8782 34.5755C42.7928 35.0206 43.0314 35.656 42.3923 35.8054C41.7237 35.9635 40.2924 35.0528 40.1304 34.5257C39.5119 32.5169 38.8699 30.5168 38.2396 28.511C37.6034 26.4787 36.929 24.4553 36.3488 22.4054C35.9394 20.9559 35.9983 20.9823 37.5386 20.9179C38.2308 20.8886 38.5047 21.1902 38.6931 21.7583C39.509 24.2239 40.3395 26.6837 41.1582 29.1464C41.2054 29.287 41.2407 29.4422 41.4056 29.448C41.6589 29.4597 41.7355 29.246 41.7826 29.0527C42.033 28.0688 42.2745 27.0819 42.516 26.0951C42.9842 24.1771 43.4496 22.259 43.9208 20.341C44.0416 19.852 44.4451 19.9076 44.8161 19.8812C45.3139 19.8432 45.7527 19.9427 45.9029 20.4698C46.5126 22.5724 47.0869 24.6866 47.6965 26.7891C48.0411 27.978 48.4269 29.1552 48.798 30.347C49.122 30.2738 49.0101 29.9985 49.0366 29.8082C49.5019 26.4172 49.9643 23.0233 50.4178 19.6294C50.4797 19.1667 50.6829 19.117 51.0481 19.3337C51.7726 19.7641 52.4971 20.1975 53.2334 20.6104C53.5633 20.7949 53.5486 21.0818 53.4749 21.3513C52.8358 23.7437 52.1201 26.1185 51.5517 28.5256C51.1954 30.0307 50.7683 31.5125 50.409 33.0147C50.2323 33.7526 49.979 34.4759 49.8259 35.2168C49.7169 35.7527 49.2574 35.8991 48.9129 36.1597C48.6743 36.3412 48.4623 36.2534 48.3592 35.9049C47.7937 33.9898 47.2164 32.0776 46.6215 30.1684C46.059 28.3616 45.4759 26.5636 44.9016 24.7598C44.8191 24.7657 44.7366 24.7715 44.6571 24.7774L44.66 24.7803Z" fill="#FAD44F"/>
    <path d="M58.5316 11.4653C58.8085 9.21051 59.0824 6.95279 59.3651 4.69799C59.4564 3.96299 59.5889 3.23384 59.6478 2.49883C59.6861 2.00395 59.91 1.80776 60.3841 1.74919C60.979 1.67598 61.5769 1.57935 62.1512 1.41829C62.9111 1.2016 63.0907 1.36851 62.914 2.16208C62.4811 4.10355 62.1335 6.06258 61.7477 8.01576C61.1175 11.1959 60.499 14.376 59.8393 17.5474C59.7715 17.8724 59.9394 18.464 59.3445 18.4669C58.7407 18.4669 58.1428 18.4903 57.7894 17.7289C56.7704 15.5386 55.6571 13.3892 54.5851 11.2252C54.0078 10.0656 53.4365 8.90304 52.6737 7.76685C52.6118 8.08311 52.5411 8.39644 52.4911 8.71562C52.0964 11.272 51.687 13.8284 51.3307 16.3907C51.2482 16.9734 51.0833 17.0144 50.5826 16.8065C48.8332 16.0745 48.8155 16.0891 49.0335 14.2121C49.5371 9.88988 50.0878 5.57355 50.5355 1.24552C50.6533 0.112267 51.528 0.355316 52.1347 0.0946971C52.8003 -0.192277 52.8651 0.422667 53.0507 0.79749C54.6941 4.11233 56.3286 7.43303 57.9661 10.7508C58.0869 10.9997 58.2224 11.2398 58.349 11.4858C58.4109 11.4829 58.4757 11.477 58.5375 11.4741L58.5316 11.4653Z" fill="#1F62AE"/>
    <path d="M22.9278 7.05819C24.7921 7.7727 26.518 8.43449 28.2438 9.09336C29.3925 9.53261 30.5087 10.0861 31.6926 10.3818C33.3714 10.8006 33.7425 12.0802 33.8691 13.439C34.0252 15.0964 33.3655 16.3936 31.7633 17.1901C29.0832 18.5254 26.1911 19.1989 23.2547 20.1126C23.4403 18.2941 22.8719 16.7216 22.1621 15.0407C23.1929 15.275 24.1059 15.5503 25.0395 15.6762C26.5268 15.8782 28.0259 15.9749 29.4779 15.3892C29.9462 15.1989 30.3997 15.0085 30.4557 14.4346C30.5087 13.8665 30.0964 13.5737 29.6811 13.3043C29.0891 12.9177 28.4147 12.786 27.7314 12.701C25.8789 12.4726 24.1942 11.8313 22.6981 10.7127C22.1886 10.332 21.7586 9.87815 21.417 9.34812C20.1947 7.45058 20.4038 5.93958 22.1945 4.57792C24.2649 3.00542 26.4532 1.69061 29.2364 1.8868C29.9697 1.93951 30.2583 2.15035 30.1553 2.90878C30.061 3.58229 30.0492 4.27337 30.0728 4.95274C30.0934 5.52962 29.8549 5.6526 29.3571 5.52962C29.3012 5.51497 29.2393 5.52962 29.1863 5.51205C26.8478 4.68334 25.0012 5.8898 22.9308 7.05526L22.9278 7.05819Z" fill="#1F62AE"/>
    <path d="M42.8518 6.37004C42.8518 4.14745 42.8636 2.41097 42.843 0.674483C42.8371 0.203026 42.9137 -0.001955 43.4615 0.138604C44.2626 0.346513 45.0813 0.487072 45.8971 0.636415C46.2859 0.706695 46.4773 0.841397 46.4567 1.30114C46.2564 5.42419 46.1062 9.54725 45.8883 13.6703C45.8029 15.316 43.4055 17.3453 41.5147 17.4068C40.2836 17.4449 39.0643 17.278 37.8686 16.9383C36.6051 16.581 35.9837 15.7113 35.8158 14.4932C35.218 10.2061 34.9735 5.88687 34.6348 1.57347C34.5906 0.999525 34.7909 0.85311 35.3387 0.832612C37.4062 0.759404 37.4033 0.741834 37.4651 2.7975C37.5564 5.78437 37.5711 8.76832 38.0188 11.7376C38.2603 13.3453 39.0408 13.7845 40.6371 14.01C41.7886 14.174 42.3541 13.6762 42.4984 12.5283C42.7753 10.3203 42.8253 8.10066 42.8518 6.36711V6.37004Z" fill="#1F62AE"/>
    <path d="M39.9831 61.8468C37.783 61.7941 37.6034 61.5832 37.353 59.4192C37.2235 58.3035 36.8965 57.2113 36.6815 56.1044C36.6226 55.794 36.443 55.7647 36.1985 55.7618C34.508 55.7501 32.8528 56.1835 31.1594 56.1249C30.6852 56.1102 30.8236 56.5641 30.7942 56.8482C30.6263 58.368 30.4348 59.8878 30.32 61.4105C30.2581 62.2216 29.781 62.494 29.1007 62.5262C27.1893 62.6199 27.2423 63.1089 27.6193 61.0298C27.9639 59.1352 28.12 57.2054 28.4969 55.3196C28.8445 53.5773 29.0919 51.8144 29.3687 50.0662C29.6514 48.2829 30.0579 46.5113 30.1727 44.7016C30.2228 43.9109 30.4879 43.1642 30.588 42.3794C30.6764 41.6971 31.5334 41.5507 32.0488 41.1905C33.0914 40.4555 33.168 40.5668 33.6156 41.8084C34.4226 44.0398 35.3003 46.2477 36.1426 48.4644C37.2352 51.34 38.3308 54.2156 39.4147 57.0942C39.9065 58.4031 40.3571 59.7267 40.846 61.0386C41.1611 61.8849 41.17 61.8907 40.2452 61.8644C40.1598 61.8644 40.0744 61.8556 39.989 61.8497L39.9831 61.8468ZM33.2416 53.9989C33.2416 53.9989 33.2416 54.0194 33.2416 54.0312C33.9573 54.0312 34.6759 53.9784 35.3857 54.0458C36.1838 54.119 36.2044 53.7442 35.9305 53.2171C34.7436 50.9564 33.5391 48.7075 32.3227 46.4644C32.2137 46.2624 32.2756 45.8436 31.9339 45.8963C31.6188 45.9432 31.6129 46.3121 31.5599 46.5933C31.1535 48.8158 30.7618 51.0384 30.3259 53.2552C30.2169 53.8174 30.429 53.9931 30.9267 53.9989C31.6983 54.0048 32.47 53.9989 33.2416 53.9989Z" fill="#FAD44F"/>
    <path d="M11.2822 53.8115C11.294 52.0223 11.4265 50.2507 11.8801 48.5113C12.3101 46.8539 13.4587 45.9695 15.0314 45.5215C17.0813 44.9387 19.1723 44.5815 21.2958 44.3736C21.77 44.3267 21.929 44.4936 21.8966 44.93C21.8878 45.0705 21.8907 45.214 21.8848 45.3575C21.8583 45.9519 22.0439 46.7016 21.7493 47.0998C21.3635 47.6181 20.748 46.8949 20.1972 46.889C17.9854 46.8656 16.2949 47.87 14.9814 49.5479C13.2555 51.7558 13.5147 54.2449 15.0756 56.4236C16.4098 58.286 18.1238 59.756 20.1913 60.7399C21.499 61.3636 22.4886 61.5364 23.6873 60.0078C23.808 59.8526 23.914 59.6857 24.0436 59.5334C24.3293 59.1967 24.4972 58.4499 24.8565 58.5729C25.3277 58.7369 25.4278 59.4514 25.6428 59.9551C26.1347 61.1118 25.6664 61.8878 24.774 62.6784C23.7462 63.592 22.7271 64.3798 21.3547 64.7516C20.3445 65.0269 19.5051 64.86 18.6834 64.3505C16.7867 63.1733 15.0255 61.7999 13.4145 60.2713C11.8624 58.8013 11.3205 56.8482 11.2852 54.7515C11.2793 54.4382 11.2852 54.1248 11.2852 53.8115H11.2822Z" fill="#FAD44F"/>
    <path d="M73.3253 45.0324C72.4682 44.5932 71.7555 44.2359 71.0545 43.8611C70.6511 43.6473 70.5951 43.2403 70.5274 42.8421C70.2122 40.9914 69.8647 39.1465 69.5879 37.29C69.4524 36.3822 69.0607 35.6267 68.4952 34.8654C68.2213 35.1025 68.0917 35.4042 67.9386 35.6941C67.0668 37.3603 66.1774 39.0148 65.3262 40.6898C65.1112 41.1114 64.9433 41.1993 64.4986 40.9679C63.3883 40.3852 63.3706 40.4057 63.833 39.293C65.4705 35.3515 67.1227 31.4158 68.7426 27.4655C68.9811 26.8857 69.2609 26.713 69.8971 26.833C72.2149 27.2664 74.4886 27.8081 76.5208 29.0615C77.9168 29.9224 78.279 31.0761 77.5957 32.5696C76.618 34.7131 74.9863 36.0747 72.6125 36.4203C71.9764 36.5111 72.0647 36.8156 72.1325 37.2344C72.5242 39.7615 72.907 42.2915 73.3223 45.0295L73.3253 45.0324ZM71.3873 34.555C72.3563 34.3998 73.3989 34.227 74.3031 33.6296C75.0983 33.1025 75.2543 32.4319 74.6771 31.6764C73.7877 30.5168 72.7038 29.5651 71.3785 28.8975C70.8867 28.6486 70.6776 28.7745 70.5009 29.2577C70.1828 30.1361 69.8205 31 69.47 31.8668C68.9782 33.0849 69.9825 34.5579 71.3844 34.5579L71.3873 34.555Z" fill="#1F62AE"/>
    <path d="M58.5672 28.6984C58.5643 29.12 58.7646 29.243 59.1504 29.3045C60.4875 29.5183 61.8334 29.4334 63.1735 29.5036C64.0747 29.5534 64.1571 29.6559 63.8832 30.4963C63.7772 30.8185 63.683 31.1406 63.5681 31.4598C63.0498 32.9356 63.0439 32.9473 61.5949 32.4759C60.4403 32.101 59.2888 31.7116 58.1549 31.2841C57.7602 31.1376 57.7102 31.2372 57.613 31.6032C57.342 32.634 57.3833 33.7145 57.0151 34.7307C56.8237 35.2519 57.127 35.5535 57.6601 35.574C59.1769 35.6355 60.6053 36.2036 62.1103 36.3178C62.5403 36.35 62.7729 36.5579 62.7582 37.0352C62.7405 37.6619 62.8024 38.4145 62.4166 38.8303C61.9601 39.3193 61.2915 38.719 60.7555 38.5843C58.4848 38.0133 56.2523 37.2871 53.9934 36.6575C53.5781 36.5404 53.5074 36.3764 53.5987 36.0045C54.123 33.8668 54.4941 31.6969 54.9506 29.5446C55.4071 27.3982 55.9431 25.2722 56.3318 23.1112C56.4379 22.5167 56.6794 22.177 57.5099 22.4552C59.3241 23.0643 61.2031 23.486 63.0586 23.9779C63.9598 24.2181 64.861 24.4728 65.774 24.6632C66.2953 24.7744 66.2482 25.0995 66.1451 25.4274C65.9655 25.9897 65.724 26.5285 65.5208 27.0819C65.3735 27.4773 65.1055 27.3748 64.8228 27.2781C63.3266 26.7598 61.8275 26.2386 60.3284 25.732C59.8071 25.5563 59.3447 25.3806 59.2328 26.262C59.1268 27.0878 58.794 27.8843 58.5613 28.6925L58.5672 28.6984Z" fill="#FAD44F"/>
    <path d="M30.3227 21.0204C32.3726 20.994 33.2944 21.5914 34.0631 23.4831C34.9879 25.7613 35.624 28.1039 35.8773 30.5608C36.1748 33.4217 34.6492 34.8625 32.3461 36.2329C31.9043 36.4964 31.3653 36.5111 30.8411 36.4964C27.5454 36.4027 24.0701 33.5037 23.7697 30.2533C23.5223 27.5915 24.185 25.0029 25.5427 22.6514C25.8461 22.1243 26.3527 21.7759 26.9535 21.5972C28.055 21.2693 29.1712 21.0379 30.3257 21.0204H30.3227ZM33.0058 30.1596C33.047 29.082 33.1118 28.0541 32.9056 27.0439C32.6465 25.7818 32.3873 24.426 30.9471 23.9926C28.6028 23.2898 26.5058 24.0307 26.0169 26.6954C25.7077 28.3821 25.9757 30.0483 26.6266 31.6267C27.5337 33.8229 28.5203 34.4378 30.9088 34.3998C32.0221 34.3822 32.4432 34.0542 32.7495 32.9883C33.0205 32.0425 32.835 31.0586 33.0028 30.1567L33.0058 30.1596Z" fill="#1F62AE"/>
    <path d="M8.5407 45.4922C7.55996 43.7791 6.61456 42.1276 5.66622 40.4789C4.00809 37.5916 2.37058 34.6926 0.674166 31.8287C0.291295 31.1816 0.420882 30.9707 1.05998 30.6867C3.51036 29.5915 5.94012 28.4524 8.36104 27.2986C8.83522 27.0732 9.01782 27.0995 9.18863 27.6354C9.75411 29.4012 9.77767 29.3953 8.15488 30.3119C7.13586 30.8887 6.13744 31.5037 5.09486 32.0337C4.57062 32.3002 4.56473 32.5667 4.79445 33.0264C5.1626 33.7643 5.49245 34.5198 5.81053 35.2812C5.98135 35.6912 6.14628 35.7614 6.56155 35.5155C7.90454 34.7131 9.28583 33.9752 10.6377 33.1875C11.0146 32.9678 11.1884 32.9854 11.4152 33.3954C12.3635 35.1289 12.3812 35.1201 10.6995 36.1216C9.86602 36.6194 9.04727 37.1436 8.19022 37.6004C7.67777 37.8728 7.5629 38.1012 7.89571 38.6312C8.77042 40.0309 9.56562 41.4775 10.4256 42.886C10.6789 43.2989 10.6759 43.5624 10.2842 43.8846C9.6952 44.3707 9.15624 44.9153 8.53775 45.4951L8.5407 45.4922Z" fill="#1F62AE"/>
    <path d="M17.4733 40.719C16.9903 40.76 17.0227 40.3471 16.9255 40.0895C15.4765 36.1685 14.0422 32.2445 12.602 28.3206C12.3664 27.6793 12.1602 27.0263 11.8892 26.4026C11.7125 25.9985 11.8303 25.8228 12.2191 25.7378C12.2456 25.732 12.2751 25.7232 12.3016 25.7144C13.0968 25.4948 14.051 24.9033 14.643 25.1551C15.2644 25.4216 15.2055 26.5988 15.4205 27.3835C16.2687 30.4524 17.111 33.5213 17.9151 36.5989C18.0859 37.2549 18.3068 37.3896 18.9576 37.1289C20.6541 36.4496 22.3888 35.8698 24.0881 35.1992C24.6212 34.9883 24.8892 35.0411 25.1778 35.5623C26.0054 37.0469 26.026 37.0294 24.4651 37.7029C22.2886 38.6399 20.1151 39.5829 17.9416 40.5228C17.7855 40.5902 17.6264 40.6546 17.4733 40.7161V40.719Z" fill="#1F62AE"/>
  </g>
  <defs>
    <clipPath id="clip0_6027_11367">
      <rect width="77.4725" height="65.4212" fill="white" transform="translate(0.461914 0.0126953)"/>
    </clipPath>
  </defs>
</svg>
