import { Component, input, output, signal } from '@angular/core';
import { Skill } from '../../models/skill.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-expertise-card',
  templateUrl: './expertise-card.component.html',
  styleUrls: ['./expertise-card.component.scss'],
})
export class ExpertiseCardComponent {
  public skill = input<Skill>();
  public active = input<boolean>(false);
  public fullWidth = input<boolean>(false);
  public close = output<number>();
  public counselorsText = signal<string>('test');

  constructor(private translate: TranslateService) {
    this.translate.get('card.counselors').subscribe((message) => {
      this.counselorsText.set(message);
    });
  }

  public closeCard() {
    this.close.emit(this.skill().id);
  }
}
