<div class="sponsor-box">
    <div class="sponsors">
      <a href="https://welcome-alliance.org/en/" target="_blank"> <img
        loading="lazy"
        src="assets/sponsors/Welcome-Alliance.png"
        alt="Company logo"
        class="object-contain self-stretch my-auto sponsor-logo"
        style="width: 253.631px; height: 105.68px;"
      /></a>
      <a href="https://www.bmi.bund.de/DE/startseite/startseite-node.html" target="_blank">
      <img
        loading="lazy"
        src="assets/sponsors/BMI Sponsor.svg"
        alt="Partner logo"
        class="object-contain shrink-0 self-stretch my-auto sponsor-logo"
        style="fill: rgba(255, 255, 255, 0.60); stroke-width: 1px; stroke: #000;"
      />
      </a>
    </div>
    <div class="sponsor-headline">
      United in our commitment to a future defined by 'We'.
    </div>
  </div>
