<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 68" fill="none">
  <g clip-path="url(#clip0_5448_1114)">
    <path d="M27.4306 19.3412C28.422 19.7932 29.4133 19.9426 30.3828 19.8041C31.345 19.6692 32.2416 19.2574 33.0689 18.5649C33.8926 17.8797 34.4466 17.0123 34.72 15.9808C35.274 14.3189 35.0188 12.8683 33.94 11.578C32.8721 10.3097 31.5163 9.56254 29.8653 9.36572C27.7951 9.43133 26.206 10.153 25.1016 11.538C24.001 12.9156 23.9317 14.738 24.8939 17.0159C25.59 18.113 26.4283 18.8893 27.4306 19.3412Z" fill="#2C2C2C"/>
    <path d="M45.0896 31.6891L44.47 31.7438L38.336 31.5944C37.7893 31.3866 36.7396 30.2313 35.1869 28.1283C33.6343 26.0289 32.4279 24.5091 31.5641 23.5761C30.704 22.6503 29.432 22.0234 27.7445 21.7173C26.0497 21.4038 24.7085 21.2544 23.7098 21.2544C22.7112 21.2544 21.7271 21.2544 20.7649 21.2544C15.9357 21.2544 12.6336 21.9906 10.8332 23.474C9.03998 24.9538 7.42538 26.9365 5.97479 29.4149C4.5315 31.9005 4.38936 33.6172 5.56295 34.5903C7.35249 35.6181 8.90148 34.8965 10.2136 32.4181C11.522 29.9324 12.5571 28.5875 13.3152 28.3907H17.3462C17.1421 28.7333 16.2455 30.8363 14.6637 34.6924C13.0746 38.5557 12.0068 44.5804 11.4528 52.7919C4.42216 53.4078 0.71916 54.2643 0.340113 55.3687C-0.0389341 56.473 0.0485382 57.5081 0.606175 58.4776C1.15288 59.4398 5.49734 59.6439 13.625 59.0935C16.1107 59.0243 17.6232 58.2042 18.1808 56.6115C18.866 47.9918 19.2415 43.9316 19.3143 44.4054C19.3872 44.8975 19.8829 46.1367 20.816 48.1339C21.749 50.1312 23.2652 53.2292 25.3682 57.4425C27.4639 61.6375 29.1003 64.0576 30.2812 64.6735C31.4475 65.2968 32.5336 65.2968 33.5323 64.6735C34.5309 64.0576 34.8589 63.1173 34.52 61.8817C34.1664 60.6425 33.2261 58.4156 31.6735 55.2156C30.1208 52.0083 29.093 49.8032 28.5682 48.5968C28.0543 47.3904 27.4639 45.9289 26.8151 44.2013C26.1554 42.4774 25.8821 41.2273 25.9878 40.4291C26.108 39.5143 26.2721 38.7015 26.6037 37.3821C26.9135 36.1357 27.555 33.5516 28.5172 29.6262C29.4101 30.4463 30.1719 31.2372 30.7951 31.9953C31.4074 32.757 32.1327 33.5516 32.9673 34.3716C33.7947 35.2063 34.6512 35.9316 35.5478 36.5475C36.3022 37.0687 42.3633 37.0031 45.1187 36.6933C45.3155 36.7261 47.6591 36.9083 47.4112 34.1894C47.2873 32.819 46.3725 31.6891 45.0896 31.6891Z" fill="#2C2C2C"/>
    <path d="M53.0471 6.16045C55.5717 3.61561 60.0265 0.583984 66.9504 0.583984H67.1803C77.913 1.34783 81.7257 7.55358 84.9677 14.3886C85.1223 14.7329 85.4671 14.9546 85.8476 14.9546C86.2281 14.9546 86.5768 14.7329 86.7354 14.3886C89.8704 7.55358 94.563 0.583984 105.541 0.583984H105.811C114.534 1.14203 120.907 6.17628 123.242 14.3886C125.79 23.3371 122.643 33.3106 118.39 39.2354C114.709 44.3567 109.596 48.7419 104.65 52.9806C103.833 53.6772 103.033 54.3698 102.236 55.0545C99.204 57.6904 96.6913 59.5188 94.0279 61.466C91.6895 63.1718 89.0579 65.0874 85.7287 67.8064C82.8791 65.2536 79.4746 62.6375 75.8878 59.879C75.1545 59.317 74.3857 58.7154 73.6366 58.1376V17.1511L60.0159 13.6762V45.6153C59.5006 45.1047 57.9656 44.6298 57.4622 44.1073C53.4632 39.7815 49.4405 34.8462 48.1762 26.5943C46.8326 17.8002 48.513 10.7316 53.0471 6.16045Z" fill="#B3B3B3"/>
    <path d="M52.6193 8.37803C54.941 6.0345 59.0376 3.24268 65.4049 3.24268H65.6163C75.4861 3.9461 78.9923 9.66096 81.9736 15.9553C82.1158 16.2724 82.4328 16.4765 82.7827 16.4765C83.1326 16.4765 83.4534 16.2724 83.5991 15.9553C86.4821 9.66096 90.7974 3.24268 100.893 3.24268H101.141C109.163 3.75658 115.024 8.39261 117.17 15.9553C119.514 24.196 116.62 33.3806 112.709 38.8367C109.323 43.5529 104.622 47.5912 100.073 51.4947C99.3223 52.1361 98.5861 52.774 97.8535 53.4045C95.0653 55.8318 92.7546 57.5157 90.3054 59.3089C88.155 60.8797 85.7349 62.6438 82.6734 65.1477C80.0529 62.7968 76.9221 60.3877 73.6236 57.8473C72.9494 57.3298 72.2423 56.7758 71.5535 56.2437V18.4993L58.1082 15.2993V44.7119C57.6344 44.2417 57.1424 43.8044 56.6795 43.3233C53.002 39.3396 49.3027 34.7947 48.14 27.1955C46.9045 19.0971 48.4498 12.5876 52.6193 8.37803Z" fill="#2C2C2C"/>
  </g>
  <defs>
    <clipPath id="clip0_5448_1114">
      <rect width="100%" height="100%" fill="white"/>
    </clipPath>
  </defs>
</svg>
