import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { IceBreaker } from '../../../profile/ice-breakers/services/model/response/ice-breaker.model';

@Component({
  selector: 'app-ice-breakers-list',
  templateUrl: './ice-breakers-list.component.html',
  styleUrls: ['./ice-breakers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IceBreakersListComponent {
  readonly iceBreakers = input.required<IceBreaker[]>();
  readonly opened = signal<IceBreaker | null>(null);

  isOpened(iceBreaker: IceBreaker) {
    const current = this.opened();
    return current && current.isEqualTo(iceBreaker);
  }

  open(iceBreaker: IceBreaker) {
    this.opened.set(iceBreaker);
  }

  toggleOpened(iceBreaker: IceBreaker) {
    const next = this.isOpened(iceBreaker) ? null : iceBreaker;
    this.opened.set(next);
  }
}
