<div class="header">
  <a class="logo" routerLink="/home">
    @if (organization()) {
      <img [src]="organization().org_icon" [alt]="'header.org_icon' | translate">
    } @else {
      <app-ngo-ngo-logo-icon></app-ngo-ngo-logo-icon>
    }
  </a>

  <div class="header__container">

    @if (!userIsSignedIn()) {
      <div class="header__items mobile">
        <app-button [routerLink]="[{outlets: {modal: ['auth']}}]"
                    [queryParams]="{ mode: 'signup', returnUrl: returnUrl }"
                    size="medium" btnUIType="primary">{{ 'header.sign_up-line' | translate }}
        </app-button>
        <app-button [routerLink]="[{outlets: {modal: ['auth']}}]"
                    [queryParams]="{ mode: 'login' }" class="login-btn" size="medium" iconBtn btnUIType="neutral">
          <app-login-icon [strokeWidth]="2.5"></app-login-icon>
        </app-button>
      </div>
    }

    <div class="header__items desktop">
      <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
    </div>

    <div
      class="project-actions desktop"
    >
      @if (userIsSignedIn()) {
        <app-dropdown-menu (logoutEvent)="onLogout()"></app-dropdown-menu>
      }
    </div>

    <div class="header__mobile-menu">
      <app-mobile-current-route></app-mobile-current-route>
      <app-dropdown-menu (logoutEvent)="onLogout()"></app-dropdown-menu>

    </div>
  </div>
</div>

<ng-template #mainMenu>
  <app-header-nav-bar-item link="''" title="{{languageService.currentLanguageTitle()}}"
                           [matMenuTriggerFor]="langSelect">
    <app-icon-globe></app-icon-globe>
  </app-header-nav-bar-item>
  <mat-menu #langSelect="matMenu">
    @for (language of languageService.languagesCodeTitle; track language[0]) {
      <button mat-menu-item (click)="languageService.setCurrentLanguage(language[0])">
        {{ language[1] }}
      </button>
    }
  </mat-menu>
  @if (!userIsSignedIn()) {
    <app-header-nav-bar-item link="/home" title="header.home">
      <app-home-icon [strokeWidth]="2.5"></app-home-icon>
    </app-header-nav-bar-item>
  }
  <app-header-nav-bar-item link="/blog" title="header.blog">
    <app-blog-icon></app-blog-icon>
  </app-header-nav-bar-item>


  <app-header-nav-bar-item link="publicfeed" title="header.news">
    <app-news-icon [strokeWidth]="2.5"></app-news-icon>
  </app-header-nav-bar-item>

  @if (!isSignedInCounselor()) {
    <app-header-nav-bar-item link="/help" title="header.get-help">
      <app-like-icon></app-like-icon>
    </app-header-nav-bar-item>
  }

  @if (isSignedInCounselor()) {
    @if (isDesktop()) {
      <app-header-nav-bar-item link="open-requests" title="header.request_desk">
        <app-request-icon></app-request-icon>
      </app-header-nav-bar-item>
    }

    <app-header-nav-bar-item link="counsellor-desk" title="header.counsellor_desk">
      <app-counsellor-desk-icon></app-counsellor-desk-icon>
    </app-header-nav-bar-item>
  }

  @if (userIsSignedIn()) {
    <app-header-nav-bar-item class="conversation-icon" link="conversations" [title]="'header.inbox'">
      <app-conversations-icon [strokeWidth]="0.6"></app-conversations-icon>
    </app-header-nav-bar-item>
  }

  @if (!userIsSignedIn()) {
    <div class="signup-menu-item">
      <a [routerLink]="[{outlets: {modal: ['auth']}}]"
         [queryParams]="{ mode: 'signup', returnUrl: returnUrl }"
         [innerHTML]="'header.sign_up' | translate"
      ></a>
    </div>


    <app-header-nav-bar-item [link]="[{outlets: {modal: ['auth']}}]"
                             [queryParams]="{ mode: 'login', returnUrl: returnUrl }"
                             [title]="'header.login'">
      <app-login-icon [strokeWidth]="2.5"></app-login-icon>
    </app-header-nav-bar-item>
  }

</ng-template>

