import { IMenuItem } from '../../../main-content-menu/model/menu-item';

export const MENU_ITEMS_FOR_ORG_ADMIN: IMenuItem[] = [
  {
    title: 'menu.individual.name',
    param: 'general',
    description: 'menu.individual.text',
    icon: 'assets/user-settings/menu/individual.svg',
  },
  {
    title: 'menu.organization.name',
    param: 'organization',
    description: 'menu.organization.text',
    icon: 'assets/user-settings/menu/ngo.svg',
  },
  {
    title: 'menu.my_topics.name',
    param: 'skills',
    description: 'menu.my_topics.text',
    icon: 'assets/user-settings/menu/myTopics.svg',
  },
  {
    title: 'menu.user-management.name',
    param: 'user-managemaent',
    description: 'menu.user-management.text',
    icon: 'assets/user-settings/menu/myTopics.svg',
  },
  {
    title: 'menu.notifications.name',
    param: 'notifications',
    description: 'menu.notifications.text',
    icon: 'assets/user-settings/menu/notifications.svg',
  },
];

export const MENU_ITEMS_FOR_CONSULT_AND_ORG_MEMBER: IMenuItem[] = [
  {
    title: 'menu.individual.name',
    param: 'general',
    description: 'menu.individual.text',
    icon: 'assets/user-settings/menu/individual.svg',
  },
  {
    title: 'menu.my_topics.name',
    param: 'skills',
    description: 'menu.my_topics.text',
    icon: 'assets/user-settings/menu/myTopics.svg',
  },
  {
    title: 'menu.notifications.name',
    param: 'notifications',
    description: 'menu.notifications.text',
    icon: 'assets/user-settings/menu/notifications.svg',
  },
];

export const MENU_ITEMS_FOR_USER_WITHOUT_PERMISSIONS: IMenuItem[] = [
  {
    title: 'menu.individual.name',
    param: 'general',
    description: 'menu.individual.text',
    icon: 'assets/user-settings/menu/individual.svg',
  },
  {
    title: 'menu.notifications.name',
    param: 'notifications',
    description: 'menu.notifications.text',
    icon: 'assets/user-settings/menu/notifications.svg',
  },
];

export const CREATE_ORG: IMenuItem = {
  title: 'menu.create-organisation.name',
  param: 'create-organisation',
  description: 'menu.create-organisation.text',
  icon: 'assets/user-settings/menu/create-org.svg',
};
