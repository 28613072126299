export const LANGUAGES_CODE = {
  ENGLISH: 'en',
  GERMAN: 'de',
  UKRAINIAN: 'uk',
  RUSSIAN: 'ru',
} as const;

export type LANGUAGES = keyof typeof LANGUAGES_CODE;
export type LANGUAGES_CODE_TYPE = (typeof LANGUAGES_CODE)[LANGUAGES];

export const DEFAULT_LANGUAGE_CODE = LANGUAGES_CODE.ENGLISH;

export const LANGUAGES_TITLES = {
  [LANGUAGES_CODE.ENGLISH]: 'English',
  [LANGUAGES_CODE.GERMAN]: 'Deutsch',
  [LANGUAGES_CODE.UKRAINIAN]: 'Українська',
  [LANGUAGES_CODE.RUSSIAN]: 'Русский',
} as const;

export const LOCALE_NAMES = {
  [LANGUAGES_CODE.ENGLISH]: 'en-US',
  [LANGUAGES_CODE.GERMAN]: 'de-DE',
  [LANGUAGES_CODE.UKRAINIAN]: 'uk-UA',
  [LANGUAGES_CODE.RUSSIAN]: 'ru-RU',
} as const;

export const USER_SELECT_LANGUAGES = {
  ENGLISH: LANGUAGES_CODE.ENGLISH,
  GERMAN: LANGUAGES_CODE.GERMAN,
  UKRAINIAN: LANGUAGES_CODE.UKRAINIAN,
  RUSSIAN: LANGUAGES_CODE.RUSSIAN,
  SPANISH: 'es',
  PORTUGUESE: 'pt',
  FRENCH: 'fr',
  ITALIAN: 'it',
  CHINESE: 'zh',
  POLISH: 'pl',
  KOREAN: 'ko',
  TURKISH: 'tr',
  HINDI: 'hi',
  JAPANESE: 'ja',
  CZECH: 'cs',
  HUNGARIAN: 'hu',
  DUTCH: 'nl',
  SWEDISH: 'sv',
  DANISH: 'da',
  GREEK: 'el',
  THAI: 'th',
  NORWEGIAN: 'no',
  CATALAN: 'ca',
  FLEMISH: 'nl-BE',
  ARABIC: 'ar',
  MANDARIN: 'zh-CN',
  MALAY: 'ms',
  PERSIAN: 'fa',
  BANGLA: 'bn',
} as const;

export const USER_SELECT_LANGUAGES_TITLES = {
  [USER_SELECT_LANGUAGES.ENGLISH]: 'English',
  [USER_SELECT_LANGUAGES.GERMAN]: 'Deutsch',
  [USER_SELECT_LANGUAGES.UKRAINIAN]: 'Українська',
  [USER_SELECT_LANGUAGES.RUSSIAN]: 'Русский',
  [USER_SELECT_LANGUAGES.SPANISH]: 'Español',
  [USER_SELECT_LANGUAGES.PORTUGUESE]: 'Português',
  [USER_SELECT_LANGUAGES.FRENCH]: 'Français',
  [USER_SELECT_LANGUAGES.ITALIAN]: 'Italiano',
  [USER_SELECT_LANGUAGES.CHINESE]: '中文',
  [USER_SELECT_LANGUAGES.POLISH]: 'Polski',
  [USER_SELECT_LANGUAGES.KOREAN]: '한국어',
  [USER_SELECT_LANGUAGES.TURKISH]: 'Türkçe',
  [USER_SELECT_LANGUAGES.HINDI]: 'हिन्दी',
  [USER_SELECT_LANGUAGES.JAPANESE]: '日本語',
  [USER_SELECT_LANGUAGES.CZECH]: 'Čeština',
  [USER_SELECT_LANGUAGES.HUNGARIAN]: 'Magyar',
  [USER_SELECT_LANGUAGES.DUTCH]: 'Nederlands',
  [USER_SELECT_LANGUAGES.SWEDISH]: 'Svenska',
  [USER_SELECT_LANGUAGES.DANISH]: 'Dansk',
  [USER_SELECT_LANGUAGES.GREEK]: 'Ελληνικά',
  [USER_SELECT_LANGUAGES.THAI]: 'ไทย',
  [USER_SELECT_LANGUAGES.NORWEGIAN]: 'Norsk',
  [USER_SELECT_LANGUAGES.CATALAN]: 'Català',
  [USER_SELECT_LANGUAGES.FLEMISH]: 'Vlaams',
  [USER_SELECT_LANGUAGES.ARABIC]: 'العربية',
  [USER_SELECT_LANGUAGES.MANDARIN]: '普通话',
  [USER_SELECT_LANGUAGES.MALAY]: 'Bahasa Melayu',
  [USER_SELECT_LANGUAGES.PERSIAN]: 'فارسی',
  [USER_SELECT_LANGUAGES.BANGLA]: 'বাংলা',
} as const;
