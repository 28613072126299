import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dummy-explainer',
  templateUrl: './dummy-explainer.component.html',
  styleUrls: ['./dummy-explainer.component.scss'],
})
export class DummyExplainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
