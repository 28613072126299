<svg
  width="101"
  height="114"
  viewBox="0 0 101 114"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M96.4223 49.1227H88.0617V35.7485C88.0617 16.5473 69.6997 0.925293 50.5023 0.925293C31.2972 0.925293 12.9351 16.5473 12.9351 35.7485V49.1266H4.57075C2.05611 49.1266 0.0222778 51.1643 0.0222778 53.6828V108.91C0.0222778 111.421 2.05992 113.462 4.57075 113.462H96.4222C98.9407 113.462 100.971 111.424 100.971 108.91L100.975 53.6828C100.975 51.1644 98.9445 49.1228 96.4221 49.1228L96.4223 49.1227ZM26.2904 35.7485C26.2904 23.9119 38.6614 14.28 50.502 14.28C62.3386 14.28 74.7096 23.9113 74.7096 35.7485V49.1266L26.2904 49.1228V35.7485ZM47.842 76.7845C47.842 78.8718 46.1477 80.5661 44.0604 80.5661C41.9694 80.5661 40.2789 78.8718 40.2789 76.7845C40.2789 74.6973 41.9732 73.003 44.0604 73.003C46.1477 73.003 47.842 74.6973 47.842 76.7845ZM16.4524 82.4359C16.4524 82.1039 16.5936 81.8063 16.8149 81.5926V81.5811C23.9162 75.3651 32.7466 71.0838 42.4918 69.5462C38.1875 72.2288 35.3103 76.991 35.3103 82.4359C35.3103 87.8811 38.1836 92.6431 42.4918 95.3217C32.746 93.7839 23.9197 89.5025 16.8149 83.2867V83.2753C16.5936 83.0654 16.4524 82.7678 16.4524 82.4358V82.4359ZM84.1803 83.2792V83.2906C77.079 89.5067 68.2486 93.788 58.5034 95.3256C62.8077 92.643 65.6849 87.8808 65.6849 82.4398C65.6849 79.1734 64.6356 76.1627 62.8765 73.69L43.8579 106.434C43.2779 107.43 42.2285 107.991 41.1525 107.991C40.6183 107.991 40.0802 107.854 39.5841 107.568C38.0922 106.702 37.5846 104.786 38.4508 103.294L65.0664 57.4733C65.9326 55.9814 67.8444 55.4738 69.3402 56.34C70.8321 57.2062 71.3397 59.1218 70.4735 60.6138L64.5017 70.8972C71.8701 72.9959 78.5704 76.6707 84.1806 81.5821V81.5936C84.4019 81.8034 84.5431 82.1049 84.5431 82.4369C84.5431 82.7689 84.4019 83.0665 84.1806 83.2802L84.1803 83.2792Z"
    fill="black"
    [attr.stroke]="color()"
    [attr.stroke-width]="strokeWidth()"
  />
  <path
    d="M67.7748 55.9148C68.3091 55.9148 68.8471 56.0522 69.3432 56.3383C70.8352 57.2046 71.3427 59.1202 70.4765 60.6121L43.8609 106.433C43.2809 107.429 42.2315 107.99 41.1555 107.99C40.6213 107.99 40.0832 107.852 39.5872 107.566C38.0952 106.7 37.5876 104.784 38.4538 103.292L65.0694 57.4717C65.6494 56.4758 66.6988 55.9148 67.7748 55.9148ZM67.7748 52.1638C65.3289 52.1638 63.0508 53.4765 61.8259 55.5867L35.2103 101.407C34.2869 102.995 34.0389 104.845 34.5082 106.624C34.9776 108.402 36.1109 109.886 37.7021 110.81C38.7514 111.42 39.9458 111.741 41.1516 111.741C43.5937 111.741 45.8756 110.428 47.1005 108.318L73.7161 62.4972C74.6395 60.9098 74.8875 59.0591 74.4182 57.281C73.9488 55.5028 72.8155 54.0184 71.2243 53.0949C70.1788 52.4882 68.9844 52.1638 67.7748 52.1638V52.1638Z"
    fill="black"
    [attr.stroke]="color()"
    [attr.stroke-width]="strokeWidth()"
  />
</svg>
