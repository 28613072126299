import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  model,
} from '@angular/core';
import { IceBreakerConversation } from '../../../../shared/models/conversation.model';
import { IceBreakerService } from '../../../profile/ice-breakers/services/ice-breaker.service';
import { Router } from '@angular/router';
import { AUTH_MODULE_PATH } from '../../../../auth/auth-routing.module';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { CONVERSATIONS_PATH } from '../../../conversations/conversations-routing.module';
import { TextInputComponent } from '../../../../shared/components/text-input/text-input.component';
import { AudioRecordComponent } from '../../../../shared/components/audio-record/audio-record.component';

@Component({
  selector: 'app-icebreaker-conversation-actions',
  templateUrl: './icebreaker-conversation-actions.component.html',
  styleUrls: ['./icebreaker-conversation-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IcebreakerConversationActionsComponent {
  readonly conversation = model<IceBreakerConversation>();
  public textInput = input<TextInputComponent>();
  public audioInput = input<AudioRecordComponent>();
  private readonly _signedInUser = toSignal(this._authService.userIsSignedIn$);

  constructor(
    private readonly _iceBreakerService: IceBreakerService,
    private readonly _router: Router,
    private readonly _authService: AuthService,
  ) {
    effect(() => {
      if (this.conversation().isCompleted() && !this._signedInUser()) {
        this._navigateToAuthPage();
      }
    });
  }

  private _navigateToAuthPage() {
    this._authService.openSignUpForm(CONVERSATIONS_PATH);
  }

  nextIceBreakerQuestion(conversation: IceBreakerConversation): void {
    if (this.textInput()) {
      this.textInput().send();
    }
    if (this.audioInput()) {
      this.audioInput().stopAudioRecord(true);
    }
    this._iceBreakerService
      .nexIcebreakerQuestion(conversation)
      .subscribe((updatedIceBreakerConversation) => {
        this.conversation.set(updatedIceBreakerConversation);
      });
  }

  completeIceBreaker(conversation: IceBreakerConversation): void {
    this._iceBreakerService
      .completeIcebreaker(conversation)
      .subscribe((completedIceBreakerConversation) => {
        this.conversation.set(completedIceBreakerConversation);
        this._authService.storeLastConversation(conversation);
      });
  }
}
