export enum FunnelColors {
  'blue' = 1,
  'yellow' = 2,
  'green' = 3,
}

export enum FunnelStudentNames {
  'new connection' = 1,
  'interested connection' = 2,
  'regular connection' = 3,
}

export enum FunnelAdvisorNames {
  'new connector' = 1,
  'interesting connector' = 2,
  'regular connector' = 3,
}
