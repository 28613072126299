<svg
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5 21.5H19C20.1046 21.5 21 20.6046 21 19.5V5.5C21 4.39543 20.1046 3.5 19 3.5H5C3.89543 3.5 3 4.39543 3 5.5V19.5C3 20.6046 3.89543 21.5 5 21.5ZM5 21.5L16 10.5L21 15.5M10 9C10 9.82843 9.32843 10.5 8.5 10.5C7.67157 10.5 7 9.82843 7 9C7 8.17157 7.67157 7.5 8.5 7.5C9.32843 7.5 10 8.17157 10 9Z"
    [attr.stroke]="color()"
    [attr.stroke-width]="strokeWidth()"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
