import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import {
  Conversation,
  createConversation,
  IConversationUserInfo,
} from '../../../../shared/models/conversation.model';
import { ConversationsService } from '../../../../services/conversations.service';
import { Observable, switchMap } from 'rxjs';
import { ChatStateService } from '../../../../services/chat-state.service';
import { takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { User } from '../../../../shared/models/user.model';
import { PermissionService } from '../../../../auth/service/permission.service';
import { Permissions } from '../../../../auth/model/permissions.model';
import { ResizeService } from '../../../../services/resize.service';
import { BaseComponent } from '../../../../shared/components/base.component';
import { AuthService } from '../../../../auth/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { AnonymousUser } from '../../../../shared/models/user/anonymous-user.model';

type ConversationManageItems =
  | 'files'
  | 'summary'
  | 'client_chat'
  | 'internal_chat'
  | 'tasks';

@Component({
  selector: 'app-conversation-manage-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './conversation-manage-panel.component.html',
  providers: [ChatStateService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./conversation-manage-panel.component.scss'],
})
export class ConversationManagePanelComponent
  extends BaseComponent
  implements OnInit
{
  // Partner - это тот, кто не текущий пользователь
  readonly activeConversationPartners = input<IConversationUserInfo[]>([]);
  // Rater - это тот, кому платят деньги (адвизор)
  readonly activeConversationRater = input<IConversationUserInfo>();
  public conversation = input<Conversation>();
  public currentUser = input<User>(null);
  public internalConversationId = input<number>();

  public selected = signal<ConversationManageItems>('tasks');

  public internalChatConversation = signal<Conversation>(null);
  public currentPermissions = signal<Permissions>(null);
  public isDesktop$ = this.resizeService.isDesktop$;
  public isNotAuthorized = toSignal(
    this._authService.authorizedUser$.pipe(
      map(
        (authorizedUser) =>
          !authorizedUser || (authorizedUser as AnonymousUser).isAnonymous,
      ),
    ),
  );

  private readonly _isNotAuthorizedOrAnonymousUser = toSignal(
    this._authService.notAuthorizedOrAnonymousUser$,
  );
  private readonly _isNotDesktop = toSignal(
    this.resizeService.isDesktop$.pipe(map((_) => !_)),
  );

  constructor(
    private conversationService: ConversationsService,
    private chatStateService: ChatStateService,
    private permissionService: PermissionService,
    private resizeService: ResizeService,
    private readonly _authService: AuthService,
  ) {
    super();
  }

  private _initPanelSelectedState() {
    if (this._isNotDesktop() && this._isNotAuthorizedOrAnonymousUser()) {
      this.selected.set('client_chat');
    }
  }

  ngOnInit(): void {
    this._initPanelSelectedState();
    this.permissionService.permissions$
      .pipe(takeUntil(this.destroyed))
      .subscribe((permissions) => {
        this.currentPermissions.set(permissions);
        if (permissions.isCounselor() && this.internalConversationId()) {
          this.getInternalChat();
        }
        this.listenState();
      });
  }

  private getInternalChat(): void {
    let conversation: Conversation;
    this.conversationService
      .fetchConversation(this.internalConversationId())
      .pipe(
        switchMap((conversationResponse) => {
          conversation = conversationResponse;
          return this.conversationService.getChatMessages(
            this.internalConversationId(),
            this.chatStateService.paginationStateSnapshot.itemsPerPage,
          );
        }),
        map((messages) => ({ ...conversation, messages })),
      )
      .subscribe((res: Conversation) => {
        this.internalChatConversation.set(createConversation(res));
      });
  }

  private listenState(): void {
    this.isDesktop$.pipe(takeUntil(this.destroyed)).subscribe((value) => {
      if (value && this.currentPermissions().isCounselor()) {
        this.selected.set('internal_chat');
      }
      if (value && this.selected() === 'client_chat') {
        this.selected.set('tasks');
      }

      if (!value && !this.includeInMembers(this.conversation())) {
        this.selected.set('internal_chat');
      }

      if (!value && this.includeInMembers(this.conversation())) {
        this.selected.set('client_chat');
      }
    });
  }

  private includeInMembers(conversation: Conversation): boolean {
    const membersIds = conversation.members.map((member) => member.user_id);
    return membersIds.includes(this.currentUser().id);
  }
}
