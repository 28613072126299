<svg
  width="25"
  height="25"
  viewBox="0 0 25 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4339_52848)">
    <path
      d="M20.3691 10.186C19.8973 10.186 19.5138 9.8024 19.5138 9.33063V7.86856C19.5138 6.12717 18.0049 4.71049 16.1472 4.71049L8.85945 4.71183C7.00177 4.71183 5.49293 6.12847 5.49293 7.86989V9.33063C5.49293 9.8024 5.1107 10.186 4.63759 10.186C4.16583 10.186 3.78226 9.8024 3.78226 9.33063V7.86856C3.78226 5.18226 6.06094 3 8.85952 3L16.1405 3.00134C18.939 3.00134 21.2177 5.18377 21.2177 7.86989V9.33063C21.2191 9.5565 21.1295 9.77301 20.9705 9.93337C20.8114 10.0937 20.5949 10.1846 20.3691 10.186Z"
      fill="#757575"
      [attr.stroke]="color()"
      [attr.stroke-width]="strokeWidth()"
    />
    <path
      d="M22.9457 13.3966H2.05436C1.58259 13.3966 1.19902 13.013 1.19902 12.5412C1.19902 12.0695 1.58259 11.6859 2.05436 11.6859H22.9457C23.4175 11.6859 23.801 12.0695 23.801 12.5412C23.801 13.013 23.4175 13.3966 22.9457 13.3966Z"
      fill="#757575"
      [attr.stroke]="color()"
      [attr.stroke-width]="strokeWidth()"
    />
    <path
      d="M16.1405 22.0813H8.8596C6.06105 22.0813 3.78234 19.8949 3.78234 17.21V15.7519C3.78234 15.2788 4.1659 14.8966 4.63767 14.8966C5.11077 14.8966 5.493 15.2788 5.493 15.7519V17.21C5.493 18.9514 7.00188 20.3707 8.85953 20.3707H16.1405C17.9982 20.3707 19.507 18.9514 19.507 17.21V15.7519C19.507 15.2788 19.8892 14.8966 20.3623 14.8966C20.8341 14.8966 21.2177 15.2788 21.2177 15.7519V17.21C21.2177 19.8843 18.9391 22.0813 16.1405 22.0813Z"
      fill="#757575"
      [attr.stroke]="color()"
      [attr.stroke-width]="strokeWidth()"
    />
  </g>
  <defs>
    <clipPath id="clip0_4339_52848">
      <rect
        width="24"
        height="24"
        fill="white"
        transform="translate(0.5 0.5)"
      />
    </clipPath>
  </defs>
</svg>
