import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { combineLatest, fromEvent, throwError, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth.service';
import {
  catchError,
  debounceTime,
  delay,
  filter,
  finalize,
  skip,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '../services/shared.service';
import { SeoService } from '../services/seo.service';
import { OnlineService } from '../services/online.service';
import { WebsocketService } from '../services/websocket.service';
import { BaseComponent } from '../shared/components/base.component';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { OrganizationService } from '../services/organization.service';
import { OrganizationShort } from '../shared/models/organizationShort';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ResizeService } from '../services/resize.service';
import { SignupDTO } from './model/sign-up-dto.interface';
import { OutletService } from '../services/outlet.service';
import { DOCUMENT } from '@angular/common';

const rippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: rippleConfig },
    OrganizationService,
  ],
  animations: [
    trigger('slideTop', [
      state('false', style({ bottom: '-306px' })),
      state('true', style({ bottom: '0px' })),
      transition('false => true', animate('300ms ease-out')),
    ]),
  ],
})
export class AuthComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('authRef') private authRef: ElementRef;

  public form = new UntypedFormGroup({
    email: new UntypedFormControl(null, [
      Validators.required,
      Validators.email,
    ]),
  });

  public organizationsControl = new FormControl(null, []);

  public isLoginMode: boolean;
  public isSignupMode: boolean;
  public isPasswordResetMode: boolean;

  public title = signal<string>(null);
  public error: string;

  public shouldShowResendConfirmation: boolean;
  public isLoading: boolean;

  public showIframe = new EventEmitter();

  public termsLink = `${environment.baseUrl}terms`;
  public organizations = signal<OrganizationShort[]>([]);
  public organizationsInitValue = signal<OrganizationShort[]>([]);

  public imgSrc: string;
  private returnUrl: string;
  public startAnimations = signal(false);
  private forceAnimations = signal(false);

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private dialog: MatDialog,
    private onlineService: OnlineService,
    private webSocketService: WebsocketService,
    private cdr: ChangeDetectorRef,
    private organizationService: OrganizationService,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
    private resizeService: ResizeService,
    readonly outletService: OutletService,
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _authComponentRef: ElementRef,
  ) {
    super();
    effect(() => {
      this.translateService.use(this.languageService.currentLanguageCode());
    });
  }

  ngAfterViewInit(): void {
    this._subscribeClosingModal();
  }

  ngOnInit() {
    const mode = this.route.snapshot.queryParams.mode;
    this.resizeService.resize$
      .pipe(debounceTime(100), skip(1), delay(200))
      .subscribe((res) => {
        this.handleAnimate(res);
      });
    this.switchMode(mode || 'login');
    this.reinit();
    this.getReturnUrl();

    this.sharedService.isAuthPage$.next(true);
    this.getAllOrganization();
    this.route.queryParams
      .pipe(takeUntil(this.destroyed))
      .subscribe((params) => {
        if (
          (params.mode && params.mode === 'login') ||
          params.mode === 'signup' ||
          params.mode === 'resetpassword'
        ) {
          this.switchMode(params.mode || 'login');
          this.reinit();
          this.cdr.detectChanges();
        }
      });

    this.filteringOrganization();
  }

  private reinit() {
    this.initForm();
    this.getImgSrc();
  }

  private fillExistingFields(): void {
    if (
      this.route.snapshot.queryParams.email &&
      this.route.snapshot.queryParams.organization
    ) {
      const emailField = this.form.get('email');
      const organizationField = this.form.get('organization');
      emailField.setValue(this.route.snapshot.queryParams.email);
      organizationField.setValue(this.route.snapshot.queryParams.organization);
      emailField.disable();
      organizationField.disable();
    }
  }

  private initForm() {
    this.form.removeControl('newPassword');
    this.form.removeControl('password');
    this.form.removeControl('isteam');
    this.form.removeControl('username');
    if (this.isLoginMode) {
      this.form.addControl(
        'password',
        new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
      );
    }

    if (this.isSignupMode) {
      this.form.addControl(
        'password',
        new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
      );
      this.form.addControl(
        'username',
        new UntypedFormControl(null, [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9_]*$/),
        ]),
      );
      this.form.addControl('organization', new UntypedFormControl(null, []));
      this.form.addControl(
        'accepted',
        new FormControl(false, [Validators.required]),
      );
    }
  }

  private getImgSrc() {
    this.imgSrc = 'assets/auth/';

    if (this.isLoginMode) {
      this.imgSrc += 'sign-in.svg';
    } else if (this.isPasswordResetMode) {
      this.imgSrc += 'reset-password.svg';
    } else {
      this.imgSrc += 'sign-up.svg';
    }
  }

  private getReturnUrl() {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
  }

  private setMeta(mode: string): void {
    this.seoService.setSettings(this.route.snapshot.data[mode].meta);
  }

  private switchMode(mode: string): void {
    this.isSignupMode = false;
    this.isLoginMode = false;
    this.isPasswordResetMode = false;
    switch (mode) {
      case 'signup':
        this.translateService
          .get('header.sign-up')
          .pipe(take(1))
          .subscribe((res: string) => this.title.set(res));
        this.isSignupMode = true;
        this.isLoginMode = false;
        this.isPasswordResetMode = false;
        this.startAnimations.set(false);
        break;
      case 'login':
        this.translateService
          .get('header.sign-in')
          .pipe(take(1))
          .subscribe((res: string) => this.title.set(res));
        this.isLoginMode = true;
        this.isSignupMode = false;
        this.isPasswordResetMode = false;
        timer(300)
          .pipe(take(1))
          .subscribe(() => {
            this.handleAnimate();
          });
        break;
      case 'resetpassword':
        this.isPasswordResetMode = true;
        this.isLoginMode = false;
        this.startAnimations.set(false);
        this.isSignupMode = false;
        this.translateService
          .get('header.reset')
          .pipe(take(1))
          .subscribe((res: string) => this.title.set(res));
        break;
      default:
        throw new Error("Mode doesn't exist");
    }

    this.setMeta(mode);

    if (this.isSignupMode || this.isLoginMode) {
      const queryParams = this.route.snapshot.queryParams;
      if (queryParams.email) {
        const emailField = this.form.get('email');
        emailField.setValue(queryParams.email);
        emailField.disable();
      }
    }
  }

  onSwitchMode(mode: string): void {
    this.switchMode(mode);
    this.reinit();
    this.router.navigate([{ outlets: { modal: 'auth' } }], {
      queryParams: { mode, returnUrl: this.returnUrl },
    });
  }

  didRequestConfirmation(email: string) {
    this.authService
      .requestConfirmationEmail(email)
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.error = 'Confirmation email sent to ' + email;
      });
  }

  onSubmit() {
    this.isLoading = true;

    if (this.isPasswordResetMode) {
      this.resetPassword();
      return;
    }

    if (this.isLoginMode) {
      this.signin();
      return;
    }

    this.signup();
  }

  resetPassword() {
    const email = this.form.value.email;

    this.authService
      .resetPassword(email)
      .pipe(
        takeUntil(this.destroyed),
        catchError((err) => {
          this.error = err.error.errors?.[0];
          return throwError(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((res: { message: string }) => {
        this.title.set(res.message);
      });
  }

  private navigate(): void {
    this.router.navigate([{ outlets: { modal: null } }]).then(() => {
      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      }
    });
  }

  signin() {
    if (this.form.invalid) {
      return;
    }
    this.webSocketService.closeConnection();
    const value = this.form.value;

    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      value.invite_token = token;
    }

    this.authService
      .login(value)
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => (this.isLoading = false)),
        catchError((err: HttpErrorResponse) => {
          this.error = err.error.errors?.[0];
          this.shouldShowResendConfirmation =
            this.error.includes('confirmation');
          return throwError(err);
        }),
        tap(() => {
          this.form.reset();
          this.navigate();
        }),
        switchMap(() => this.onlineService.subscribeToOnline()),
      )
      .subscribe();
  }

  signup() {
    if (
      this.form.invalid ||
      (this.form.get('accepted') && this.form.get('accepted')?.value === false)
    ) {
      return;
    }
    const value = this.form.getRawValue();
    const body: SignupDTO = {
      email: value.email,
      username: value.username,
      password: value.password,
      accepted: value.accepted,
    };
    const token = this.route.snapshot.queryParamMap.get('token');
    if (value.organization) {
      body.org_id = value.organization.id;
    }
    if (token) {
      body.invite_token = token;
    }

    this.authService
      .signUp(body as any)
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => (this.isLoading = false)),
        catchError((err: HttpErrorResponse) => {
          this.error = err.error.errors?.full_messages[0];
          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.form.reset();
        this.navigate();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sharedService.isAuthPage$.next(false);
  }

  public organizationSelected(value: string): void {
    this.form.get('organization').setValue(value);
  }

  private getAllOrganization(): void {
    if (this.isSignupMode) {
      this.organizationService
        .getOrganizationsList()
        .subscribe((organizations) => {
          this.organizations.set(organizations);
          this.organizationsInitValue.set(organizations);
          this.signupByToken();
        });
    }
  }

  private filteringOrganization(): void {
    if (this.isSignupMode) {
      this.organizationsControl.valueChanges
        .pipe(takeUntil(this.destroyed))
        .subscribe((find) => {
          const organizations = this.organizationsInitValue();
          this.organizations.set(
            organizations.filter((org) =>
              org.full_name.toLowerCase().includes(find.toLowerCase()),
            ),
          );
        });
    }
  }

  public signupByToken(): void {
    const queryParams = this.route.snapshot.queryParams;
    if (!queryParams.token) {
      return;
    }
    const orgId = queryParams.organization;
    if (orgId) {
      const org = this.organizations().find((org) => org.id === +orgId);
      if (org) {
        const orgField = this.form.get('organization');
        orgField.setValue(org);
        orgField.disable();
        this.organizationsControl.setValue(org.full_name);
        this.organizationsControl.disable();
      }
    }
  }

  private handleAnimate(size = window.innerWidth): void {
    const isPortrait = window.innerHeight > window.innerWidth;
    if (this.isLoginMode && size < 768 && isPortrait) {
      this.startAnimations.set(true);
    } else {
      this.startAnimations.set(false);
    }
  }

  private _subscribeClosingModal(): void {
    fromEvent<MouseEvent>(this._document, 'mouseup')
      .pipe(
        // @ts-ignore
        filter(
          (event) =>
            !this._authComponentRef.nativeElement.contains(
              event.target as Node,
              // @ts-ignore
            ) && this.isNotDropdownElement(event.target),
        ),
        take(1),
      )
      .subscribe(() => {
        this.outletService.closeModalOutlet();
      });
  }

  private isNotDropdownElement(target: HTMLElement): boolean {
    return (
      !target.classList.contains('option') &&
      !target.classList.contains('cdk-overlay-pane') &&
      !target.classList.contains('autocomplete')
    );
  }
}
