<div class="item-media-view-container" #contentContainer>
  <p class="item-media-view-container__title">{{ title }}</p>
  <div
    class="item-media-view"
    [ngClass]="{
      active: selectedFile?.id === file.id || (isWinnerFile(file) && readonly),
    }"
    [class]="file.feedformat"
    *ngFor="let file of files"
  >
    <div class="item-action-view" *ngIf="!selectedFile && !readonly">
      <img class="zoom-in" src="assets/newsfeed/eye.svg" alt="" />
      <img
        class="like-it"
        src="assets/newsfeed/thumbs-up.svg"
        alt=""
        (click)="fileDidTap(file)"
      />
    </div>

    <div
      class="item-stats-view"
      [ngClass]="{
        active:
          selectedFile?.id === file.id || (isWinnerFile(file) && readonly),
      }"
      *ngIf="selectedFile || readonly"
    >
      <p>{{ file.likecount }}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="like-it-active feather feather-thumbs-up"
      >
        <path
          d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"
        ></path>
      </svg>
    </div>

    <app-small-spinner diameter="20" *ngIf="!file"></app-small-spinner>

    <img
      class="media"
      lightbox
      [fullImage]="{ path: file.url }"
      *ngIf="file && file.kind === 'image'"
      [src]="file.url"
      alt=""
    />
  </div>
</div>
