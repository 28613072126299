import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HomeIconComponent } from '../../../icons/home-icon/home-icon.component';
import { ConversationsIconComponent } from '../../../icons/conversations-icon/conversations-icon.component';
import { UploadIconComponent } from '../../../icons/upload-icon/upload-icon.component';
import { BlogIconComponent } from '../../../icons/blog-icon/blog-icon.component';
import { RequestIconComponent } from '../../../icons/request-icon/request-icon.component';
import { SharedModule } from '../../../shared.module';
import { AddMembersIconComponent } from '../../../icons/add-members-icon/add-members-icon.component';
import { LikeIconComponent } from '../../../icons/like-icon/like-icon.component';
import { NewsIconComponent } from '../../../icons/news-icon/news-icon.component';
import { IconShapeImpactComponent } from '../../../icons/icon-shape-impact/icon-shape-impact.component';
import { IconOptionComponent } from '../../../icons/icon-option/icon-option.component';
import { CounsellorDeskIconComponent } from '../../../icons/counsellor-desk-icon/counsellor-desk-icon.component';
import { IconCapsuleClosedComponent } from '../../../icons/icon-capsule-closed/icon-capsule-closed.component';

const MenuItems = {
  '/home': 'header.home',
  '/conversations': 'header.inbox',
  '/upload': 'header.menu.upload',
  '/settings': 'header.menu.settings',
  '/why': 'header.menu.signed_in_as',
  '/admin': 'header.menu.open_admin',
  '/blog': 'header.blog',
  '/open-requests': 'header.request_desk-line',
  '/counsellor-desk': 'header.counsellor_desk-line',
  '/help': 'header.get-help-line',
  '/profile': 'header.profile-title',
  '/news': 'header.news-line',
  '/publicfeed': 'header.news-line',
  '/insights': 'header.insights-mobile',
  '/rateflow': 'header.shape_impact',
  '/create-ice-breaker': 'header.create_capsule',
  login: 'header.login',
  signup: 'header.sign_up-line',
} as const;

type MenuItems = keyof typeof MenuItems;

@Component({
  selector: 'app-mobile-current-route',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    HomeIconComponent,
    ConversationsIconComponent,
    UploadIconComponent,
    BlogIconComponent,
    RequestIconComponent,
    SharedModule,
    AddMembersIconComponent,
    LikeIconComponent,
    NewsIconComponent,
    IconShapeImpactComponent,
    IconOptionComponent,
    CounsellorDeskIconComponent,
    IconCapsuleClosedComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mobile-current-route.component.html',
  styleUrls: ['./mobile-current-route.component.scss'],
})
export class MobileCurrentRouteComponent implements OnInit {
  public activeRoute = signal<MenuItems | string>(null);
  public routes: MenuItems[] = Object.keys(MenuItems) as MenuItems[];
  public menuItems = MenuItems;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        const path = ev.url.replace(/^\/([^\/?]*).*/, '/$1');
        const patternAuth = /modal:auth/;
        if (patternAuth.test(path)) {
          this.activeRoute.set(ev.url.includes('login') ? 'login' : 'signup');
        } else {
          this.activeRoute.set(path);
        }
      });
  }
}
