import { Component, input } from '@angular/core';
import { MarkIconComponent } from '../../icons/mark-icon/mark-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { IChipsItem } from './chips-item.interface';

@Component({
  selector: 'app-chips-item',
  standalone: true,
  templateUrl: './chips-item.component.html',
  imports: [MarkIconComponent, TranslateModule, NgClass],
  styleUrls: ['./chips-item.component.scss'],
})
export class ChipsItemComponent<F extends IChipsItem> {
  readonly chipsItem = input.required<F>();
  readonly isSelected = input(false);
}
