import { ComingSoon } from '../interfaces';

export const COMMING_SOON: ComingSoon[] = [
  {
    img: 'assets/coming-soon/notifications.svg',
    text: 'coming_soon.notifications',
    color: '#E3BE30',
  },
  {
    img: 'assets/coming-soon/privacy.svg',
    text: 'coming_soon.privacy',
    color: '#00C67E',
  },
  {
    img: 'assets/coming-soon/visibility.svg',
    text: 'coming_soon.visibility',
    color: '#F17E7E',
  },
];
