import { computed, Injectable } from '@angular/core';
import {
  CreatedIceBreaker,
  IceBreaker, IcebreakerMember,
  IceBreakerTemplateMessage,
} from '../../ice-breaker-template/ice-breaker-template-messages';
import { Observable, of, throwError } from 'rxjs';
import { API_URL } from '../../../../../config/config';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Stripeintent } from '../../../../shared/models/stripeintent.model';
import { MatDialog } from '@angular/material/dialog';
import { BasePaymentRequest } from '../../../../shared/models/payment-request';
import { ICreateNewChat } from './model/response/create-new-chat.interface';
import { AuthService } from '../../../../auth/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { IAllIceBreakers } from './model/response/all-ice-breakers.interface';
import { IceBreakerConversation } from '../../../../shared/models/conversation.model';
import { createHttpParams } from '../../../../shared/functions/http-params';
import { iceBreakerFactory } from './model/response/ice-breaker.model';
import { Skill } from '../../../../services/skill/model/skill.model';


@Injectable({
  providedIn: 'root',
})
export class IceBreakerService {
  private readonly _notAuthorizedUser = toSignal(this._authService.authorizedUser$.pipe(map(_ => !_)));
  private readonly _anonUserId = toSignal(this._authService.anonymousUserId$);
  private readonly _notAuthorizedOrAnonymousUser = computed(() => this._notAuthorizedUser() || this._anonUserId());
  private readonly _hasAuthorizationData = toSignal(this._authService.hasAuthorizationData$);

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private readonly _authService: AuthService,
  ) {
  }

  createIceBreaker(iceBreaker: IceBreaker): Observable<any> {
    return this.http.post(`${API_URL}icebreakers`, { ...iceBreaker }).pipe(
      catchError((err) => {
        console.error(err);
        this.matSnackBar.open('Sorry. Something went wrong!');
        return throwError(err);
      }),
    );
  }

  delete(id: number): Observable<unknown> {
    return this.http.delete(`${API_URL}icebreakers/${id}`);
  }

  join(iceBreaker: IceBreaker): Observable<void> {
    // if (iceBreaker.icebreaker_member?.paid && iceBreaker.price) {
    //   return this.createConversation(iceBreaker.icebreaker_member);
    // }
    const anonymousUserParams = this._notAuthorizedOrAnonymousUser()
      ? { conversation_status: 'no_stage', user_anonymous_id: this._anonUserId() }
      : {};

    return this.http
      .post<ICreateNewChat>(`${API_URL}create_new_chat`, {
        icebreaker_id: iceBreaker.id,
        conversation_type: 'client_chat',
        ...anonymousUserParams,
      })
      .pipe(
        tap(_ => {
          if (!this._hasAuthorizationData() && Number.isInteger(_.conversation.user_anonymous_id)) {
            // @ts-ignore
            this._authService.setAnonymousUser(_.conversation);
          }
          this.router.navigate([
            `/conversations/${_.conversation.id}`,
          ]);
          // console.log('Navigating to conversation '+ _.conversation.id);


          // return this.buyIceBreaker(createdIceBreaker.id).pipe(
          //   switchMap((res: Stripeintent) => {
          //     if (!res) {
          //       return this.createConversation(createdIceBreaker);
          //     }
          //     return this.dialog
          //       .open(BankCardFormModalComponent, {
          //         maxWidth: '500px',
          //         width: '100%',
          //         height: '220px',
          //         data: res.client_secret,
          //       })
          //       .afterClosed()
          //       .pipe(
          //         filter((isPaid) => isPaid),
          //         switchMap(() =>
          //           this.updatePaymentStatus(res.payment_id).pipe(
          //             switchMap((iceBreakerCreated: CreatedIceBreaker) =>
          //               this.createConversation(iceBreakerCreated),
          //             ),
          //           ),
          //         ),
          //       );
          //   }),
          // );
        }),
        catchError((err) => {
          console.warn(err);
          this.matSnackBar.open(err.error.error, '', {
            duration: 4000,
          });
          return of(err);
        }),
      );
  }

  private buyIceBreaker(iceBreakerMemberId: number): Observable<Stripeintent> {
    return this.http.post<Stripeintent>(`${API_URL}icebreaker_member_payment`, {
      icebreaker_member_id: iceBreakerMemberId,
    });
  }

  // tslint:disable-next-line:variable-name
  private updatePaymentStatus(
    payment_id: string,
  ): Observable<CreatedIceBreaker> {
    return this.http.put<CreatedIceBreaker>(
      `${API_URL}icebreaker_members/update_status`,
      {
        payment_id,
      },
    );
  }

  private createConversation(
    createdIceBreaker: CreatedIceBreaker,
  ): Observable<void> {
    return this.http
      .post<CreatedIceBreaker>(
        `${API_URL}icebreaker_members/add_conversation/${createdIceBreaker.id}`,
        {},
      )
      .pipe(
        map(
          ({ conversation_id }) =>
            void this.router.navigate([`/conversations/${conversation_id}`]),
        ),
      );
  }

  nexIcebreakerQuestion(conversation: IceBreakerConversation) {
    const params = this._notAuthorizedOrAnonymousUser()
      ? {
        user_anonymous_id: this._anonUserId(),
      } : {};
    return this.http.post<IcebreakerMember>(`${API_URL}icebreaker_members/next_step/${conversation.icebreakerId}`, params)
      .pipe(map(_ => conversation.updateIcebreakerMember(_)));
  }

  completeIcebreaker(conversation: IceBreakerConversation) {
    const params = this._notAuthorizedOrAnonymousUser()
      ? {
        user_anonymous_id: this._anonUserId(),
      } : {};
    return this.http.put(`${API_URL}icebreaker_members/complete/${conversation.icebreakerId}`, params)
      .pipe(map(() => conversation.completeIcebreaker()));
  }

  updateIceBreaker(update: {
    icebreaker_member_id: number;
    step_id: number;
    icebreaker_id: number;
    questions: IceBreakerTemplateMessage[];
  }): Observable<any> {
    return this.http.put(`${API_URL}icebreakers/questions`, update);
  }

  addNewQuestionsToIceBreaker(request: any): Observable<any> {
    return this.http.put(`${API_URL}icebreakers/questions`, request);
  }

  updateTitlePrice(
    id: number,
    body: { title?: string; price?: number; preview_media_url?: string },
  ): Observable<any> {
    const filteredParams = JSON.parse(JSON.stringify(body));
    return this.http.put(`${API_URL}icebreakers/${id}`, filteredParams);
  }

  getIceBreakerInvoiceInfo(
    amount: number,
    allow_quantity: number,
  ): Observable<BasePaymentRequest> {
    return this.http.get<BasePaymentRequest>(`${API_URL}icebreakers/invoice`, {
      params: { amount, allow_quantity },
    });
  }

  fetchAllIceBreakers(updateWithSkills: Skill[]) {
    return this.http.get<IAllIceBreakers[]>(API_URL + 'icebreakers/all_icebreakers')
      .pipe(map(_ => _.map((ib) => iceBreakerFactory(ib, updateWithSkills))));
  }
}
