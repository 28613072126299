<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 21 21" fill="none">
<path
    d="M3.00051 7.25112H17.9993C18.4579 7.25112 18.8329 6.87296 18.8329 6.4105C18.8329 5.94805 18.4579 5.56989 17.9993 5.56989H11.3335V3.58694H13.1333C13.2833 3.58694 13.3583 3.4018 13.2497 3.30096L10.6163 0.645194C10.5499 0.57823 10.4499 0.57823 10.3827 0.645194L7.74934 3.30096C7.64076 3.41046 7.71575 3.58694 7.86574 3.58694H9.6656V5.56989L3.00053 5.57068C2.54196 5.57068 2.16699 5.94883 2.16699 6.41129C2.16699 6.87375 2.54196 7.25191 3.00053 7.25191L3.00051 7.25112Z"
    fill="black"
  />
  <path
    d="M17.9993 9.15991H3.00053C2.54196 9.15991 2.16699 9.53807 2.16699 10.0005C2.16699 10.463 2.54196 10.8411 3.00053 10.8411H17.9993C18.4579 10.8411 18.8329 10.463 18.8329 10.0005C18.8329 9.53807 18.4579 9.15991 17.9993 9.15991Z"
    fill="black"
  />
  <path
    d="M17.9993 12.7493H3.00053C2.54196 12.7493 2.16699 13.1274 2.16699 13.5899C2.16699 14.0523 2.54196 14.4305 3.00053 14.4305H9.6664V16.4134H7.86654C7.71655 16.4134 7.64156 16.5986 7.75014 16.6994L10.3835 19.3552C10.4499 19.4222 10.5499 19.4222 10.6171 19.3552L13.2505 16.6994C13.3591 16.5899 13.2841 16.4134 13.1341 16.4134H11.3343V14.4305H18.0001C18.4587 14.4305 18.8337 14.0523 18.8337 13.5899C18.8337 13.1274 18.4587 12.7493 18.0001 12.7493H17.9993Z"
    fill="black"
  />
</svg>
