<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7 13L12 18L17 13"
    stroke="#B0B0B0"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    [attr.stroke]="color()"
    [attr.stroke-width]="strokeWidth()"
  />
  <path
    d="M7 6L12 11L17 6"
    stroke="#B0B0B0"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    [attr.stroke]="color()"
    [attr.stroke-width]="strokeWidth()"
  />
</svg>
