<app-filter-panel [currentUser]="currentUser" (filterChanged)="handleFilterChange($event)"
                  (newConversation)="addNewConversation($event)"></app-filter-panel>

<div class="activity-container">
  @if (currentUser) {
    <div
      infinite-scroll
      (scrolled)="loadMore()"
      [infiniteScrollDisabled]="isLoading"
      [infiniteScrollDistance]="3"
      class="conversations"
    >
      @if (conversations$ | async; as conversations) {
        @for (conversation of conversations; track conversation.id) {
          <app-conversation-row
            [conversation]="conversation"
            [currentUser]="currentUser"
            class="conversation"
          ></app-conversation-row>
        } @empty {
          <span class="conversations__empty">
                 {{ 'conversations.empty' | translate }}
              </span>

        }
      }
      <app-small-spinner [diameter]="50" *ngIf="isLoading"></app-small-spinner>
    </div>
  }
</div>
