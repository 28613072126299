<div class="card" [ngClass]="{'active' : active(), 'full-width': fullWidth()}">
  <div class="card__content">
    <div class="card__icon-container">
      <img class="card__icon" [src]="skill().icon" [alt]="skill().name">
    </div>
    <div class="card__text">
      <p class="card__name">{{skill().name}}</p>
      <p class="card__counselors">{{skill().advisors_count + ' ' + counselorsText()}} </p>
    </div>
  </div>
  @if (active()){
    <button class="card__close-btn" (click)="closeCard()">
      <app-close-card-icon></app-close-card-icon>
    </button>
  }
</div>
