<svg
  viewBox="0 0 20 20" fill="none"
  preserveAspectRatio="xMidYMid meet"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4875_3170)">
    <path
      d="M3.33325 17.5V11.6667M3.33325 8.33333V2.5M9.99992 17.5V10M9.99992 6.66667V2.5M16.6666 17.5V13.3333M16.6666 10V2.5M0.833252 11.6667H5.83325M7.49992 6.66667H12.4999M14.1666 13.3333H19.1666"
      [attr.stroke]="color()"
      [attr.stroke-width]="strokeWidth()"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_4875_3170">
      <rect width="100%" height="100%" fill="white" />
    </clipPath>
  </defs>
</svg>

