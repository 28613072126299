<svg
  viewBox="0 0 48 48" fill="none"
  preserveAspectRatio="xMidYMid meet"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M40 12L18 34L8 24" [attr.stroke]="color()"
        [attr.stroke-width]="strokeWidth()"
        stroke-linecap="round"
        stroke-linejoin="round"
  />
</svg>

