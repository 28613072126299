import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Skill, skillFactory } from './model/skill.model';
import { map } from 'rxjs/operators';
import { ISkill } from './model/response/skill.interface';
import { SKILL_API_URL_PROVIDER_KEY } from './skill-api-urls.provider';
import { ApiUrlsProvider } from '../../shared/lib/api-urls-provider.lib';

@Injectable({
  providedIn: 'any',
})
export class SkillsService {

  constructor(
    @Inject(SKILL_API_URL_PROVIDER_KEY)
    private readonly _skillApiUrls: ApiUrlsProvider,
    private readonly _httpClient: HttpClient,
  ) {
  }

  private readonly _skills$ = new BehaviorSubject<Skill[]>([]);
  readonly skills$ = this._skills$.asObservable();
  private readonly _isLoading$ = new BehaviorSubject(false);
  readonly isLoading$ = this._isLoading$.asObservable();

  private _setLoadingStarts() {
    this._isLoading$.next(true);
  }

  private _setLoadingCompleted() {
    this._isLoading$.next(false);
  }

  private _setSkills(skills: Skill[]) {
    this._skills$.next(skills);
  }

  fetchSkills() {
    this._setLoadingStarts();
    this._httpClient.get<ISkill[]>(this._skillApiUrls.GET)
      .pipe(map(_ => _.map(skillFactory)))
      .subscribe({
        next: _ => this._setSkills(_),
        complete: () => this._setLoadingCompleted(),
      });
  }

}
