<svg
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_5362_4377)">
    <path
      d="M83.0006 58.7181L79.4551 45.4908C79.1824 44.5363 78.0915 26.1272 78.0915 25.9908C77.9551 25.4454 77.4097 24.8999 76.7279 24.8999H10.046C9.36421 24.8999 8.81876 25.309 8.6824 25.9908C6.90967 33.6272 7.86421 41.809 11.2733 48.8999C18.7733 64.7181 17.2733 80.2635 17.4097 82.7181C17.4097 83.3999 18.0915 83.9454 18.7733 83.9454H59.4097C60.0915 83.9454 60.7733 83.3999 60.7733 82.5817V74.1272H68.9551C71.2733 74.1272 73.1824 72.2181 73.1824 69.8999V62.809C73.1824 61.9908 73.8642 61.309 74.8188 61.309C76.1824 61.1726 77.4097 61.309 78.5006 61.309C79.046 61.309 79.5915 61.309 80.0006 61.309C82.7279 61.4454 83.4097 60.0817 83.0006 58.7181Z"
      fill="black"
    />
    <path
      d="M13.5914 14.6727L16.5914 13.9909L18.3642 22.3091H28.7278L26.4096 11.8091L29.4096 11.1272C30.5005 10.8545 30.9096 9.62724 30.0914 8.80906L19.5914 -1.00912C19.3187 -1.28185 18.7733 -1.41821 18.3642 -1.41821C17.9551 -1.28185 17.546 -1.00912 17.4096 -0.600031L11.9551 12.7636C11.546 13.8545 12.5005 14.9454 13.5914 14.6727Z"
      fill="black"
    />
    <path
      d="M74.2732 22.3091L76.5914 16.8546L62.955 10.991L58.0459 22.3091H74.2732Z"
      fill="black"
    />
    <path
      d="M78.7739 0.354513C78.7739 -0.190941 78.5012 -0.736396 77.9557 -0.87276C77.4102 -1.14549 76.8648 -1.00912 76.4557 -0.600032C75.3648 0.490877 66.6375 6.7636 64.3193 8.67269L77.4102 14.2636C77.5466 11.4 78.7739 3.08179 78.7739 0.354513Z"
      fill="black"
    />
    <path
      d="M54.9094 17.5364L50.6821 16.1728C50.0003 15.9001 49.5912 15.2183 49.8639 14.4001C50.1366 13.7183 50.8184 13.3092 51.6366 13.5819L55.8639 14.9455L57.0912 10.991L51.3639 9.21825C50.6821 8.94553 50.273 8.26371 50.4094 7.44553C50.6821 6.76371 51.3639 6.35462 52.1821 6.49098L57.9094 8.26371L59.1366 4.03644C59.4094 3.35462 58.8639 2.53644 58.1821 2.40007L47.5457 -0.736291C46.8639 -0.872655 46.0457 -0.599927 45.9094 0.218255L39.2275 22.3092H53.5457L54.9094 17.5364Z"
      fill="black"
    />
  </g>
  <defs>
    <clipPath id="clip0_5362_4377">
      <rect width="100" height="100" fill="white" transform="translate(0.5)" />
    </clipPath>
  </defs>
</svg>
