<section class="conversation-manage-panel">
  <div class="manage-menu">
    <div class="manage-menu-wrapper">
      <div class="manage-menu-item"  role="button" [ngClass]="{selected: selected() === 'tasks'}" (click)="selected.set('tasks')">
        <app-task-icon  class="icon" [color]="selected() === 'tasks' ? '#1E1E1E' : '#757575'" strokeWidth="2.5"></app-task-icon>
        {{ 'conversation_manage_panel.tasks' | translate }}
      </div>
      <div class="manage-menu-item"  role="button" [ngClass]="{selected: selected() === 'files'}" (click)="selected.set('files')">
        <app-upload-icon class="icon" [color]="selected() === 'files' ? '#1E1E1E' : '#757575'" strokeWidth="2.5"></app-upload-icon>
        {{ 'conversation_manage_panel.file_vault' | translate }}
      </div>


      <div class="manage-menu-item client"  role="button" [ngClass]="{selected: selected() === 'client_chat'}" (click)="selected.set('client_chat')">
          <app-message-icon class="icon"  [color]="selected() === 'client_chat' ? '#1E1E1E' : '#757575'" strokeWidth="2.5"></app-message-icon>
      {{ 'conversation_manage_panel.client_chat' | translate }}
      </div>

      @if(internalConversationId() && currentPermissions && currentPermissions()?.isCounselor())
      {
        <div class="manage-menu-item"  role="button" [ngClass]="{selected: selected() === 'internal_chat'}" (click)="selected.set('internal_chat')">
          <app-message-icon class="icon"  [color]="selected() === 'internal_chat' ? '#1E1E1E' : '#757575'" strokeWidth="2.5"></app-message-icon>
          {{ 'conversation_manage_panel.internal_chat' | translate }}
        </div>
      }
      <div class="manage-menu-item summary"  role="button" [ngClass]="{selected: selected() === 'summary'}" (click)="selected.set('summary')">
        <app-activity-icon class="icon"  [color]="selected() === 'summary' ? '#1E1E1E' : '#757575'" strokeWidth="2.5"></app-activity-icon>
        {{ 'conversation_manage_panel.summary' | translate }}
      </div>
    </div>

    <app-button
      class="menu"
      size="medium"
      btnUIType="subtle"
      [disabled]="isNotAuthorized()"
    >
      <app-icon-more-vertical
        [color]="isNotAuthorized() ? '#b3b3b3' : '#1E1E1E'"
        class="conversation-request-more-button_icon"
      ></app-icon-more-vertical>
    </app-button>
<!--    // TODO need implemented-->
<!--    @if (isOpened()) {-->
<!--      <app-dropdown-list-->
<!--        [items]="menuItems()"-->
<!--        alignBy='right'-->
<!--        (selectedChange)="selectMenuItem($event)"-->
<!--      ></app-dropdown-list>-->
<!--    }-->
  </div>

  <div class="content">


    @if (selected() === 'internal_chat' && currentPermissions && currentPermissions()?.isCounselor() && this.internalChatConversation()) {
      <app-conversation-detail
        [isInternal]="true"
        [conversation]="internalChatConversation()"
        ></app-conversation-detail>
    }

    @if (selected() === 'client_chat' && conversation()) {
     <app-conversation-detail
        [conversation]="conversation()"
        [activeConversationRater]="activeConversationRater()"
        [activeConversationPartners]="activeConversationPartners()"></app-conversation-detail>
    }

    @if(['summary', 'files', 'tasks'].includes(selected())) {
      <span class="coming-soon"> {{ 'conversation_manage_panel.coming_soon' | translate }}</span>
    }
  </div>
</section>
