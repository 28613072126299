import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AuthService } from 'src/app/auth/auth.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OnlineService } from 'src/app/services/online.service';
import { SharedService } from 'src/app/services/shared.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Observable } from 'rxjs';
import { OrganizationShort } from '../../../models/organizationShort';
import { LanguageService } from '../../../../services/language.service';
import { PermissionService } from '../../../../auth/service/permission.service';
import { ICE_BREAKER_CREATE_PATH } from '../../../../ice-breaker/routing/ice-breaker.paths';
import { toSignal } from '@angular/core/rxjs-interop';
import { ResizeService } from '../../../../services/resize.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent implements OnInit {
  readonly userIsSignedIn = toSignal(this.authService.userIsSignedIn$);
  readonly isSignedInClient = toSignal(this.permissionService.isClient$);
  readonly isSignedInCounselor = toSignal(this.permissionService.isCounselor$);
  readonly isDesktop = toSignal(this._resizeService.isDesktop$);
  public organization = input<OrganizationShort>(null);

  public returnUrl: string;
  public isMediumScreen$: Observable<boolean>;
  public showNewFeedback = true;

  readonly createIceBreakerPath = ICE_BREAKER_CREATE_PATH;

  public get unreadCount(): number {
    return this.messagesService.unreadMessages$.value;
  }

  public get isInsights(): boolean {
    return /insights/.test(this.router.url);
  }

  public get isCreatives(): boolean {
    return /creatives/.test(this.router.url);
  }

  public get isAdvisors(): boolean {
    return /advisors/.test(this.router.url);
  }

  public get isPublicFeed(): boolean {
    return /publicfeed/.test(this.router.url);
  }

  constructor(
    public authService: AuthService,
    private messagesService: MessagesService,
    private onlineService: OnlineService,
    private webSocketService: WebsocketService,
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    public sharedService: SharedService,
    public readonly languageService: LanguageService,
    readonly permissionService: PermissionService,
    private readonly _resizeService: ResizeService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToRouter();
    this.observeBreakpoint();
  }

  private observeBreakpoint() {
    this.isMediumScreen$ = this.breakpointObserver
      .observe('(max-width: 1180px)')
      .pipe(map((res) => res.matches));
  }

  subscribeToRouter() {
    this.router.events
      .pipe(
        takeUntil(this.destroyed),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        if (this.isInsights) {
          this.showNewFeedback = false;
        }

        this.returnUrl = location.pathname + location.search;
      });
  }

  onLogout() {
    this.webSocketService.closeConnection();
    this.onlineService.subscribeToOnline().subscribe();
    this.sharedService.feedbackRequestsCount = 0;
    this.messagesService.unreadMessages$.next(0);
    this.authService.logout();
  }
}
