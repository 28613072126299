<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
  <g clip-path="url(#clip0_5151_3394)">
    <path d="M23.8022 6L14.3022 15.5L9.30225 10.5L1.80225 18M23.8022 6H17.8022M23.8022 6V12" [attr.stroke]="color()"
          [attr.stroke-width]="strokeWidth()" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_5151_3394">
      <rect width="100%" height="100%" fill="white" transform="translate(0.802246)"/>
    </clipPath>
  </defs>
</svg>
