import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { AuthService } from './auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { fadeAnimation, fader } from './shared/animations';
import { AnalyticsService } from './services/analytics.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import * as hammerjs from 'hammerjs';
import { SharedService } from './services/shared.service';
import { OnlineService } from './services/online.service';
import { filter } from 'rxjs/operators';
import LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';
// import { PixelService } from 'ngx-pixel';
import { OneSignal } from 'onesignal-ngx';
import { WebPushService } from './services/web-push.service';
import { OutletService } from './services/outlet.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    // <-- add your animations here
    fadeAnimation,
    fader,
    trigger('slideInOut', [
      state('in', style({ top: 0 })),
      transition(':leave', [
        style({ top: 0 }),
        animate(300, style({ top: '-200px' })),
        // group([
        // animate('200ms ease-in-out', style({opacity: '0'}))
        // ])
      ]),
      transition(':enter', [
        style({ top: '-200px' }),
        animate(300, style({ top: 0 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  showCookieNotification: boolean;
  hammerjs = hammerjs;
  public hideFooter = signal(false);
  public isAuthPage: boolean;
  readonly isModalActive = toSignal(this._outletService.isModalActivate$);

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    // private pixelService: PixelService,
    private oneSignal: OneSignal,
    private readonly webPushService: WebPushService,
    private readonly _outletService: OutletService,
    onlineService: OnlineService,
  ) {
    this.router.events
      .pipe(filter((res) => res instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.analyticsService.trackPageview(event.urlAfterRedirects);
        if (event.url.includes('/conversations/')) {
          this.hideFooter.set(true);
        } else {
          this.hideFooter.set(false);
        }
        // this.pixelService.track('PageView');
      });

    onlineService.subscribeToOnline().subscribe();
  }

  ngOnInit(): void {
    this.sharedService.init();
    this.analyticsService.initAnalytics();
    this.checkWebPushSubscription();

    if (!this.isTouchDevice()) {
      document.querySelector('body').classList.add('no-touch-screen');
    }

    this.sharedService.isAuthPage$.subscribe((res) => {
      this.isAuthPage = res;
      this.cdr.detectChanges();
    });

    if (environment.production && environment.logRocketAppId) {
      LogRocket.init(environment.logRocketAppId);

      this.authService.userSubject$
        .pipe(filter((res) => !!res))
        .subscribe((res) => {
          LogRocket.identify(res ? res.id.toString() : null, {
            name: res?.username || null,
            email: res?.email || null,
          });
        });
    }
    this.authService.userSubject$
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.webPushNotifications();
      });
  }

  private webPushNotifications(): void {
    this.oneSignal.init({
      appId: environment.oneSignal.appId,
      safari_web_id: environment.oneSignal.safari_web_id,
      notifyButton: {
        enable: true,
        colors: {
          'circle.background': 'rgb(0,0,0)',
        },
      },
      serviceWorkerPath: 'OneSignalSDKWorker.js',
      allowLocalhostAsSecureOrigin:
        environment.oneSignal.allowLocalhostAsSecureOrigin,
    });
  }

  private checkWebPushSubscription(): void {
    this.oneSignal.on('subscriptionChange', (isSubscribed: boolean) => {
      if (isSubscribed) {
        this.oneSignal.getUserId((userId: string) => {
          this.webPushService.subscribedToWebPush(userId).subscribe();
        });
      } else {
        this.oneSignal.getUserId((userId: string) => {
          this.webPushService.unsubscribeToWebPush(userId).subscribe();
        });
      }
    });
  }

  ngAfterViewInit() {
    this.showCookieNotification = !localStorage.getItem(
      'hideCookieNotification',
    );
    if (localStorage.getItem('cookiesAccepted') && environment.production) {
      // this.pixelService.initialize();
    }
    this.cdr.detectChanges();
  }

  hideNotification() {
    this.showCookieNotification = false;
    localStorage.setItem('hideCookieNotification', 'true');
    localStorage.setItem('cookiesAccepted', 'true');

    if (environment.production) {
      // this.pixelService.initialize();
    }
  }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }
}
