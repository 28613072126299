import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-header-nav-bar-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './header-nav-bar-item.component.html',
  styleUrls: ['./header-nav-bar-item.component.scss'],
})
export class HeaderNavBarItemComponent {
  public link = input.required<unknown[] | string>();
  public queryParams = input<{ [key: string]: string }>();
  public title = input.required<string>();
}
