@if (conversation().hasNextSteps()) {
  <app-button
    class="ice-breaker-btn"
    [btnUIType]="'primary'"
    size="medium"
    (click)="nextIceBreakerQuestion(conversation())"
  >
    <span class="ice-breaker-btn_text"
          [innerHTML]="'ice-breaker.next_btn_text' | translate"
    ></span>
    <app-right-arrow-icon
      class="ice-breaker-btn_icon"
      color="#f5f5f5"
    ></app-right-arrow-icon>
  </app-button>
}
@if (!conversation().isCompleted() && conversation().isLastStep()) {
  <app-button
    class="ice-breaker-btn"
    [btnUIType]="'primary'"
    size="medium"
    (click)="completeIceBreaker(conversation())"
  >
    <span class="ice-breaker-btn_text"
          [innerHTML]="'ice-breaker.finish_btn_text' | translate: {title: conversation().icebreakerTitle} "
    ></span>
  </app-button>
}
