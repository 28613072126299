import { Country } from '../interfaces';

export const CountriesItems: Country[] = [
  {
    name: 'finances_stripe.country.argentina',
    code: 'AR',
  },
  {
    name: 'finances_stripe.country.australia',
    code: 'AU',
  },
  {
    name: 'finances_stripe.country.austria',
    code: 'AT',
  },
  {
    name: 'finances_stripe.country.belgium',
    code: 'BE',
  },
  {
    name: 'finances_stripe.country.bolivia',
    code: 'BO',
  },
  {
    name: 'finances_stripe.country.bulgaria',
    code: 'BG',
  },
  {
    name: 'finances_stripe.country.canada',
    code: 'CA',
  },
  {
    name: 'finances_stripe.country.costa_rica',
    code: 'CR',
  },
  {
    name: 'finances_stripe.country.croatia',
    code: 'HR',
  },
  {
    name: 'finances_stripe.country.cyprus',
    code: 'CY',
  },
  {
    name: 'finances_stripe.country.czech_republic',
    code: 'CZ',
  },
  {
    name: 'finances_stripe.country.denmark',
    code: 'DK',
  },
  {
    name: 'finances_stripe.country.dominican_republic',
    code: 'DO',
  },
  {
    name: 'finances_stripe.country.egypt',
    code: 'EG',
  },
  {
    name: 'finances_stripe.country.finland',
    code: 'FI',
  },
  {
    name: 'finances_stripe.country.france',
    code: 'FR',
  },
  {
    name: 'finances_stripe.country.germany',
    code: 'DE',
  },
  {
    name: 'finances_stripe.country.greece',
    code: 'GR',
  },
  {
    name: 'finances_stripe.country.hong_kong',
    code: 'HK',
  },
  {
    name: 'finances_stripe.country.hungary',
    code: 'HU',
  },
  {
    name: 'finances_stripe.country.iceland',
    code: 'IS',
  },
  {
    name: 'finances_stripe.country.india',
    code: 'IN',
  },
  {
    name: 'finances_stripe.country.indonesia',
    code: 'ID',
  },
  {
    name: 'finances_stripe.country.ireland',
    code: 'IE',
  },
  {
    name: 'finances_stripe.country.israel',
    code: 'IL',
  },
  {
    name: 'finances_stripe.country.italy',
    code: 'IT',
  },
  {
    name: 'finances_stripe.country.latvia',
    code: 'LY',
  },
  {
    name: 'finances_stripe.country.liechtenstein',
    code: 'LI',
  },
  {
    name: 'finances_stripe.country.lithuania',
    code: 'LT',
  },
  {
    name: 'finances_stripe.country.luxembourg',
    code: 'LU',
  },
  {
    name: 'finances_stripe.country.malta',
    code: 'MT',
  },
  {
    name: 'finances_stripe.country.mexico',
    code: 'MX',
  },
  {
    name: 'finances_stripe.country.netherlands',
    code: 'NL',
  },
  {
    name: 'finances_stripe.country.new_zealand',
    code: 'NZ',
  },
  {
    name: 'finances_stripe.country.norway',
    code: 'NO',
  },
  {
    name: 'finances_stripe.country.peru',
    code: 'PE',
  },
  {
    name: 'finances_stripe.country.poland',
    code: 'PL',
  },
  {
    name: 'finances_stripe.country.portugal',
    code: 'PT',
  },
  {
    name: 'finances_stripe.country.romania',
    code: 'RO',
  },
  {
    name: 'finances_stripe.country.singapore',
    code: 'SG',
  },
  {
    name: 'finances_stripe.country.slovakia',
    code: 'SK',
  },
  {
    name: 'finances_stripe.country.slovenia',
    code: 'SI',
  },
  {
    name: 'finances_stripe.country.spain',
    code: 'ES',
  },
  {
    name: 'finances_stripe.country.sweden',
    code: 'SE',
  },
  {
    name: 'finances_stripe.country.switzerland',
    code: 'CH',
  },
  {
    name: 'finances_stripe.country.thailand',
    code: 'TH',
  },
  {
    name: 'finances_stripe.country.trinidad_and_tobago',
    code: 'TT',
  },
  {
    name: 'finances_stripe.country.united_kingdom',
    code: 'UK',
  },
  {
    name: 'finances_stripe.country.uruguay',
    code: 'UY',
  },
  {
    name: 'finances_stripe.country.brazil',
    code: 'BR',
  },
  {
    name: 'finances_stripe.country.chile',
    code: 'CL',
  },
  {
    name: 'finances_stripe.country.colombia',
    code: 'CO',
  },
  {
    name: 'finances_stripe.country.paraguay',
    code: 'PY',
  },
];
