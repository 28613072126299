import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TransactionsService } from '../../../../../services/transactions.service';
import { AuthService } from '../../../../../auth/auth.service';
import { User } from '../../../../../shared/models/user.model';
import { PaymentMethods } from '../../../../../shared/models/transaction.model';
import { BaseComponent } from '../../../../../shared/components/base.component';
import { IceBreakerService } from '../../../ice-breakers/services/ice-breaker.service';
import { BasePaymentRequest } from '../../../../../shared/models/payment-request';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PriceConditionsResult } from '../../ice-breaker-template-messages';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  paymentMethods!: PaymentMethods;
  currentUser$: Observable<User> = this.authService.userSubject$;
  paymentRequestInvoice$!: Observable<BasePaymentRequest>;
  isShowCardForm = false;

  constructor(
    private readonly transactionService: TransactionsService,
    private readonly iceBreakerService: IceBreakerService,
    private readonly authService: AuthService,
    private readonly dialogRef: MatDialogRef<PaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: PriceConditionsResult,
  ) {
    super();
  }

  ngOnInit(): void {
    this.paymentRequestInvoice$ =
      this.iceBreakerService.getIceBreakerInvoiceInfo(
        this.data.price,
        this.data.countOfPeople,
      );
    this.checkPaymentMethods();
  }

  attachPaymentMethods(id: string): void {
    this.transactionService
      .attachPaymentMethod(id)
      .pipe(
        tap(() => this.checkPaymentMethods()),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  private checkPaymentMethods(): void {
    this.transactionService
      .fetchPaymentMethods()
      .pipe(
        tap((res: PaymentMethods) => {
          this.paymentMethods = { ...res };
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  close(paymentRequest?: BasePaymentRequest): void {
    if (!paymentRequest) {
      this.dialogRef.close(undefined);
      return;
    }
    this.dialogRef.close({ ...paymentRequest, ...this.data });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
