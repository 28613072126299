import { ChangeDetectionStrategy, Component, computed, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SkillsService } from '../../services/skill/skills.service';
import { ArtcategoriesService } from '../../services/artcategory/artcategories.service';

@Component({
  selector: 'app-get-help',
  templateUrl: './get-help.component.html',
  styleUrls: ['./get-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetHelpComponent implements OnInit {
  readonly loadedSkills = toSignal(this._skillsService.skills$);
  readonly artCategories = toSignal(this._artCategoriesService.artCategories$);
  private readonly _artCategoriesLoading = toSignal(this._artCategoriesService.isLoading$);
  private readonly _skillsLoading = toSignal(this._skillsService.isLoading$);
  readonly isLoading = computed(() => this._artCategoriesLoading() || this._skillsLoading());

  constructor(
    private readonly _skillsService: SkillsService,
    private readonly _artCategoriesService: ArtcategoriesService,
  ) {
  }

  ngOnInit() {
    this._artCategoriesService.fetchArtcategories({ bound_to_skill: true });
    this._skillsService.fetchSkills();
  }
}
