import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
  signal,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IChipsItem } from '../../UIkit/chips-item/chips-item.interface';
import { IFilterPanelOutput } from './filter-pannel.interface';
import { InputComponent } from '../../UIkit/input/input.component';
import { SearchInputComponent } from '../../UIkit/inputs/search-input/search-input.component';
import { FiltersListHorizontalComponent } from '../filters-list-horizontal/filters-list-horizontal.component';
import { ButtonFilterComponent } from '../../UIkit/buttons/button-filter/button-filter.component';
import { ResizeService } from '../../../services/resize.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-filter-panel',
  standalone: true,
  imports: [CommonModule, InputComponent, SearchInputComponent, FiltersListHorizontalComponent, ButtonFilterComponent],
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent<F extends IChipsItem> {
  readonly filterItems = input.required<F[]>();
  readonly actionsTemplate = input<null | TemplateRef<unknown>>(null);
  readonly filtersChanged = output<IFilterPanelOutput<F>>();

  readonly selectedFilters = signal<F[]>([]);
  readonly searchInputValue = signal<string>('');
  readonly isActiveFilterButton = computed(
    () => !!(this.selectedFilters().length
      || (!this.actionsTemplate() && this.searchInputValue())),
  );
  readonly isHiddenFilters = signal(false);
  readonly isMobileScreen = toSignal(this._resizeService.isSmallest$);

  constructor(
    private readonly _resizeService: ResizeService,
  ) {
    effect(() => {
      this.filtersChanged.emit({
        textFilter: this.searchInputValue(),
        selectedFilters: this.selectedFilters(),
      });
    });

    effect(() => {
      if (this.isMobileScreen()) {
        this._hideFilters();
      } else {
        this._showFilters();
      }
    }, { allowSignalWrites: true });
  }

  toggleDisplayFilters() {
    if (this.isHiddenFilters()) {
      this._showFilters();
    } else {
      this._hideFilters();
    }
  }

  private _showFilters() {
    this.isHiddenFilters.set(false);
  }

  private _hideFilters() {
    if (this.isMobileScreen()) {
      this.isHiddenFilters.set(true);
    }
  }
}
