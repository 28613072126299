import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-screenshot-full-view',
  templateUrl: './screenshot-full-view.component.html',
  styleUrls: ['./screenshot-full-view.component.scss'],
})
export class ScreenshotFullViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
