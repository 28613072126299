<svg
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M82.2978 38.812C77.745 33.4725 72.0007 29.278 65.5286 26.567C65.3664 26.5253 65.2116 26.4589 65.0696 26.3703C58.8516 23.8128 52.1879 22.5146 45.4645 22.5509C38.7192 22.5059 32.0323 23.8042 25.7939 26.3703C25.6519 26.4589 25.4971 26.5253 25.3349 26.567C18.8864 29.2869 13.1655 33.4808 8.6313 38.812C7.01589 40.6656 6.12598 43.0413 6.12598 45.5C6.12598 47.9587 7.01589 50.3344 8.6313 52.188C13.1841 57.5275 18.9284 61.722 25.4005 64.433C25.5651 64.4673 25.7211 64.5342 25.8595 64.6297C32.0775 67.1872 38.7412 68.4854 45.4645 68.4491C52.2099 68.4941 58.8968 67.1958 65.1352 64.6297C65.2735 64.5342 65.4296 64.4673 65.5941 64.433C72.0663 61.722 77.8105 57.5275 82.3633 52.188C83.982 50.3359 84.874 47.9597 84.874 45.5C84.874 43.0403 83.982 40.664 82.3633 38.812H82.2978ZM45.4645 66.8098C41.2498 66.8098 37.1298 65.56 33.6254 63.2185C30.121 60.8769 27.3897 57.5488 25.7768 53.6549C24.1639 49.7611 23.7419 45.4764 24.5642 41.3427C25.3864 37.2089 27.416 33.4119 30.3962 30.4317C33.3764 27.4514 37.1735 25.4219 41.3072 24.5996C45.4409 23.7774 49.7256 24.1994 53.6195 25.8123C57.5133 27.4252 60.8415 30.1565 63.183 33.6609C65.5246 37.1653 66.7744 41.2853 66.7744 45.5C66.7744 51.1517 64.5292 56.572 60.5329 60.5683C56.5365 64.5647 51.1163 66.8098 45.4645 66.8098Z"
    fill="black"
  />
  <path
    d="M45.465 27.4683C41.8987 27.4683 38.4125 28.5258 35.4473 30.5071C32.482 32.4884 30.1709 35.3045 28.8062 38.5993C27.4414 41.8942 27.0843 45.5197 27.7801 49.0174C28.4758 52.5152 30.1931 55.7281 32.7149 58.2498C35.2366 60.7715 38.4495 62.4889 41.9472 63.1846C45.445 63.8803 49.0705 63.5233 52.3653 62.1585C55.6601 60.7938 58.4762 58.4826 60.4576 55.5174C62.4389 52.5521 63.4964 49.0659 63.4964 45.4997C63.4964 40.7174 61.5967 36.1311 58.2151 32.7495C54.8336 29.368 50.2472 27.4683 45.465 27.4683ZM55.3003 51.5648C55.3003 52.1299 55.0758 52.672 54.6762 53.0716C54.2765 53.4712 53.7345 53.6958 53.1693 53.6958C53.0662 53.7129 52.961 53.7129 52.8579 53.6958C52.56 53.6591 52.2738 53.558 52.019 53.3995C51.7642 53.2409 51.547 53.0288 51.3826 52.7778C50.5747 51.5504 49.4749 50.5425 48.1818 49.8445C46.8888 49.1465 45.4428 48.7801 43.9733 48.7781H41.3342L43.711 54.7285C43.7909 54.9286 43.8305 55.1426 43.8275 55.3581C43.8246 55.5736 43.7792 55.7864 43.694 55.9843C43.6087 56.1822 43.4852 56.3614 43.3307 56.5116C43.1761 56.6618 42.9934 56.78 42.7931 56.8594C42.6 56.9358 42.3942 56.9747 42.1866 56.9742C41.8583 56.9745 41.5374 56.8762 41.2656 56.6921C40.9938 56.508 40.7836 56.2465 40.6621 55.9415L37.7443 48.6306C36.5829 48.3396 35.5676 47.6349 34.8887 46.6487C34.2098 45.6625 33.914 44.4625 34.0568 43.2738C34.1997 42.085 34.7712 40.9892 35.6644 40.1919C36.5575 39.3945 37.7109 38.9504 38.9081 38.9428H43.9733C45.4398 38.9444 46.8838 38.583 48.1767 37.8908C49.4695 37.1986 50.5709 36.1972 51.3826 34.9759C51.547 34.7249 51.7642 34.5127 52.019 34.3542C52.2738 34.1957 52.56 34.0946 52.8579 34.0579C52.961 34.0408 53.0662 34.0408 53.1693 34.0579C53.7289 34.0579 54.266 34.2779 54.6647 34.6704C55.0634 35.063 55.2917 35.5966 55.3003 36.1561V51.5648Z"
    fill="black"
  />
  <path
    d="M76.6101 71.7273C75.8706 71.7343 75.1422 71.908 74.4792 72.2355L74.2988 72.0224L68.8238 66.5474C67.7747 67.0719 66.6929 67.5309 65.5454 67.9735L71.922 74.35L72.1351 74.5304C71.6774 75.4913 71.5439 76.5749 71.7546 77.6182C71.9652 78.6615 72.5087 79.6083 73.3034 80.3164C74.0981 81.0245 75.1011 81.4557 76.1617 81.5451C77.2223 81.6345 78.2834 81.3774 79.1854 80.8124C80.0874 80.2474 80.7818 79.405 81.1642 78.4116C81.5466 77.4183 81.5965 76.3277 81.3062 75.3037C81.0159 74.2797 80.4012 73.3774 79.5544 72.7326C78.7077 72.0877 77.6745 71.7349 76.6101 71.7273Z"
    fill="black"
  />
  <path
    d="M14.32 19.2724C15.0595 19.2654 15.7879 19.0918 16.4509 18.7643L16.6312 18.9774L22.1062 24.4524C23.1553 23.9278 24.2372 23.4688 25.3847 23.0263L19.0081 16.6497L18.795 16.4694C19.2527 15.5084 19.3862 14.4249 19.1755 13.3816C18.9648 12.3382 18.4213 11.3914 17.6267 10.6833C16.832 9.97523 15.829 9.54409 14.7684 9.45465C13.7077 9.36522 12.6467 9.62231 11.7447 10.1873C10.8426 10.7523 10.1483 11.5948 9.76586 12.5881C9.38344 13.5814 9.33363 14.672 9.6239 15.696C9.91418 16.7201 10.5289 17.6223 11.3757 18.2672C12.2224 18.9121 13.2556 19.2648 14.32 19.2724Z"
    fill="black"
  />
  <path
    d="M47.1042 80.2187C47.1143 80.1206 47.1143 80.0217 47.1042 79.9236V71.7275H43.8258V79.9236C43.8168 80.0163 43.8168 80.1096 43.8258 80.2023C42.7321 80.589 41.8103 81.3499 41.2233 82.3505C40.6364 83.3511 40.422 84.5269 40.6182 85.6703C40.8144 86.8136 41.4084 87.8508 42.2953 88.5985C43.1823 89.3463 44.305 89.7564 45.465 89.7564C46.6251 89.7564 47.7478 89.3463 48.6347 88.5985C49.5216 87.8508 50.1157 86.8136 50.3119 85.6703C50.508 84.5269 50.2937 83.3511 49.7067 82.3505C49.1198 81.3499 48.198 80.589 47.1042 80.2023V80.2187Z"
    fill="black"
  />
  <path
    d="M43.8258 10.7813C43.8157 10.8794 43.8157 10.9783 43.8258 11.0764V19.2725H47.1042V11.0764C47.1132 10.9837 47.1132 10.8904 47.1042 10.7977C48.198 10.411 49.1198 9.65015 49.7067 8.64956C50.2937 7.64896 50.508 6.47307 50.3119 5.32972C50.1157 4.18638 49.5216 3.14918 48.6347 2.40147C47.7478 1.65375 46.6251 1.24365 45.465 1.24365C44.305 1.24365 43.1823 1.65375 42.2953 2.40147C41.4084 3.14918 40.8144 4.18638 40.6182 5.32972C40.422 6.47307 40.6364 7.64896 41.2233 8.64956C41.8103 9.65015 42.7321 10.411 43.8258 10.7977V10.7813Z"
    fill="black"
  />
  <path
    d="M18.9584 74.3339L25.3349 67.9574C24.2367 67.5148 23.1548 67.0558 22.0565 66.5312L16.5815 72.0062L16.4012 72.2193C15.3551 71.712 14.1632 71.5916 13.0368 71.8796C11.9104 72.1676 10.9225 72.8454 10.2484 73.7926C9.57432 74.7399 9.25762 75.8953 9.35452 77.0539C9.45143 78.2124 9.95567 79.2992 10.7778 80.1213C11.5998 80.9434 12.6866 81.4476 13.8452 81.5445C15.0037 81.6414 16.1592 81.3247 17.1064 80.6506C18.0536 79.9765 18.7314 78.9886 19.0194 77.8623C19.3074 76.7359 19.1871 75.5439 18.6797 74.4978L18.9584 74.3339Z"
    fill="black"
  />
  <path
    d="M71.9708 16.6659L65.5942 23.0425C66.6925 23.4851 67.7744 23.9441 68.8727 24.4686L74.3477 18.9936L74.528 18.7805C75.574 19.2879 76.766 19.4083 77.8924 19.1203C79.0188 18.8322 80.0067 18.1545 80.6808 17.2072C81.3549 16.26 81.6716 15.1046 81.5747 13.946C81.4777 12.7874 80.9735 11.7007 80.1514 10.8786C79.3293 10.0565 78.2426 9.55226 77.084 9.45535C75.9254 9.35845 74.77 9.67515 73.8228 10.3492C72.8755 11.0233 72.1978 12.0112 71.9097 13.1376C71.6217 14.264 71.7421 15.456 72.2495 16.502L71.9708 16.6659Z"
    fill="black"
  />
</svg>
