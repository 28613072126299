import { effect, NgModule, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversationMessageComponent } from './conversation-message.component';
import { PipesModule } from '../../../../../shared/pipes/pipes.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { IframelyEmbedModule } from '../../../ice-breakers/components/iframely-embed/iframely-embed.module';
import { UserAvatarModule } from '../../../../../shared/components/user-info/pipes/user-avatar/user-avatar.module';
import { ReceiptModule } from '../../../../../shared/components/receipt/receipt.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../../services/language.service';
import { createForChildProviderConfig } from '../../../../../shared/app-language/translate-module-provider-config.factory';

@NgModule({
  declarations: [ConversationMessageComponent],
  exports: [ConversationMessageComponent],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    MomentModule,
    IframelyEmbedModule,
    UserAvatarModule,
    ReceiptModule,
    TranslateModule.forChild(createForChildProviderConfig('ice-breakers')),
  ],
})
export class ConversationMessageModule {
  constructor(
    private readonly _languageService: LanguageService,
    private readonly _translateService: TranslateService,
  ) {
    effect(() => {
      this._translateService.use(this._languageService.currentLanguageCode());
    });
  }
}
